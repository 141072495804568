import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border: .125rem solid ${c.grayMed};
  border-radius: .25rem;
  text-align: center;

  ${({ checked }) => checked && `
    border: 2px solid ${c.bluePrimary};
    background-color: ${c.bluePrimary};
  `}
`;