/*eslint-disable no-unused-vars*/
import React, { useEffect,useState } from 'react';
/*eslint-enable no-unused-vars*/

const useWindowSize = () => {
  const [windowSize,setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const windowSizeHandler = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  useEffect(() => {
    window.addEventListener('resize',windowSizeHandler);
    return () => window.removeEventListener('resize',windowSizeHandler);
  });

  return windowSize;
}

export default useWindowSize;
