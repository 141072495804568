import styled from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const Container = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 35rem;
  width: 19rem;
  padding-left: 4.375rem;
  transition: opacity .3s .2s ease;

  ${({ hide }) => hide && `
    opacity: 0;
    transition: opacity .1s ease;
  `}
`;

export const Title = styled.h3`
  font-family: 'DIN Next';
  font-size: 1.2rem;
  margin-bottom: .5rem;
`;

export const StyleChoices = styled.div`
  position: relative;
`;

export const DropDown = styled.button`
  width: 100%;
  padding: .75rem;
  border: 2px solid ${c.grayMed};
  border-radius: .5rem;
  font-size: 1.2rem;
  font-family: 'DIN Next';
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 1.875rem;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

export const DropName = styled.div`
  flex: 1;
  text-align: left;
`;

export const DropArrow = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.2rem;
  background-color: #E6F6FC;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ open }) => open && `
    transform: rotate(180deg);
  `}
`;

export const StyleChoiceBox = styled.div`
  position: absolute;
  z-index: 1;
  display: none;
  flex-direction: column;
  top: 4rem;
  left: 0;
  width: 100%;
  border: 2px solid ${c.grayMed};
  border-radius: .5rem;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0,0,0,0.1);

  ${({ open }) => open && `
    display: flex;
  `}
`;

export const StyleChoice = styled.button`
  width: 100%;
  padding: .75rem;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) => disabled && `
    color: ${c.grayMed};

      &:hover {
        cursor: not-allowed;
      }
  `}
`;

export const StyleText = styled.div`
  font-size: 1.2rem;
  font-family: 'DIN Next';
  margin-left: .75rem;
`;

export const Rechargeable = styled.button`
  display: flex;
  margin-bottom: 3.75rem;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) => disabled && `
    color: ${c.grayMed};

      &:hover {
        cursor: not-allowed;
      }
  `}
`;

export const RechText = styled.div`
  font-family: 'DIN Next';
  font-size: 1.25rem;
  margin-left: 1rem;
`;

export const ModelChoices = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ModelChoice = styled.button`
  flex: 0 1 43%;
  padding: 0 1rem 1rem 0;
  opacity: .4;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }

  ${({ active }) => active && `
    opacity: 1;
    transition: opacity .1s linear;
  `}

  img {
    width: 100%;
    height: auto;
  }
`;
