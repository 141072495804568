import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '40',
  height = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path
    d="M27.34,19.11a.66.66,0,0,0-.59-.34.65.65,0,0,0-.58.34L22.8,25.4a.61.61,0,0,0,.29.83.78.78,0,0,0,.3.07A.66.66,0,0,0,24,26l1.16-2.17h3.26l1.09,2a.67.67,0,0,0,.88.27.6.6,0,0,0,.29-.83Zm-1.55,3.44,1-1.79,1,1.79Z"
    fill={fill} />
  <path
    d="M36,21.77a8.6,8.6,0,0,0-2.84-5.06,9.82,9.82,0,0,0-10.5-1.47,9.17,9.17,0,0,0-9.29-7.82A9.7,9.7,0,0,0,6.69,10a8.42,8.42,0,0,0,0,12.47A9.65,9.65,0,0,0,13.33,25a9.81,9.81,0,0,0,4.07-.88,9,9,0,0,0,7,7.57h0a9.76,9.76,0,0,0,2.27.26,9.55,9.55,0,0,0,8.26-4.58A8.4,8.4,0,0,0,36,21.77Zm-22.63,2a7.84,7.84,0,0,1-8.07-7.56,7.84,7.84,0,0,1,8.07-7.57,7.83,7.83,0,0,1,8,7.57A7.83,7.83,0,0,1,13.33,23.78Zm20.42,3a8.31,8.31,0,0,1-9,3.71,7.73,7.73,0,0,1-6-7.07,8.7,8.7,0,0,0,4-6.82,8.42,8.42,0,0,1,9.54,1A7.22,7.22,0,0,1,33.75,26.8Z"
    fill={fill} />
  <path
    d="M16.65,31.24h0a.14.14,0,0,0,0-.07h0a.14.14,0,0,0,0-.06l0,0s0,0,0,0l0,0,0-.05-.05-.06h0l-.05,0h0l0,0,0,0-.07,0L12.89,29.5a.67.67,0,0,0-.86.34.6.6,0,0,0,.36.8l2.25.86a4.73,4.73,0,0,1-4,.09h0a4.28,4.28,0,0,1-2.64-4A.62.62,0,0,0,7.33,27a.63.63,0,0,0-.67.6,5.51,5.51,0,0,0,3.39,5.17,6.09,6.09,0,0,0,2.47.52,6.16,6.16,0,0,0,2.12-.38L14,34.24a.59.59,0,0,0,.36.8.73.73,0,0,0,.25,0,.65.65,0,0,0,.61-.38l1.36-3.12s0,0,0,0,0,0,0,0a.43.43,0,0,1,0-.05v0a.17.17,0,0,1,0-.07v-.09a.43.43,0,0,0,0,0Z"
    fill={fill} />
  <path
    d="M23.39,8.76h0a.14.14,0,0,0,0,.07h0a.19.19,0,0,0,0,.07l0,0,0,0s0,0,0,0l0,0,0,.06h0l.05,0h0l0,0h0a.11.11,0,0,0,.07,0l3.36,1.28a.68.68,0,0,0,.86-.34.61.61,0,0,0-.36-.81L25.4,8.51a4.73,4.73,0,0,1,4-.09h0a4.28,4.28,0,0,1,2.64,4,.62.62,0,0,0,.64.63h0a.63.63,0,0,0,.65-.6A5.48,5.48,0,0,0,30,7.29a6.09,6.09,0,0,0-4.59-.15L26,5.77A.6.6,0,0,0,25.64,5a.68.68,0,0,0-.86.34L23.43,8.42v.05s0,0,0,0v0s0,.05,0,.07V8.7a.43.43,0,0,1,0,.05Z"
    fill={fill} />
  <path
    d="M14.51,19.06a.57.57,0,0,0-.59-.56H12V18h1.94a.56.56,0,1,0,0-1.12H12V15.74h1l.19.36a.58.58,0,0,0,.53.31.66.66,0,0,0,.27-.06.55.55,0,0,0,.27-.75l-.8-1.49a.61.61,0,0,0-.8-.25.55.55,0,0,0-.27.75H10.05l.9-1.38h3.1a.56.56,0,1,0,0-1.12h-5a.56.56,0,1,0,0,1.12h.5L8.47,14.89a.55.55,0,0,0,0,.57.63.63,0,0,0,.52.28h1.81v1.14H8.85a.56.56,0,1,0,0,1.12h1.94v.5H8.85a.56.56,0,1,0,0,1.12h5.07A.57.57,0,0,0,14.51,19.06Z"
    fill={fill} />
  <path
    d="M15.45,18.43a.58.58,0,0,0,.6-.56v-4.5a.6.6,0,0,0-1.2,0v4.5A.58.58,0,0,0,15.45,18.43Z"
    fill={fill} />
  <path
    d="M17.28,12.16a.58.58,0,0,0-.6.56v5.74A1.67,1.67,0,0,1,15,20.07h-.07a.56.56,0,1,0,0,1.12H15a2.84,2.84,0,0,0,2.92-2.73V12.72A.58.58,0,0,0,17.28,12.16Z"
    fill={fill} />
  </svg>
);

export default SVG;
