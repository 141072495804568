import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '40',
  height = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M20.08,18.62a.76.76,0,0,0,.76-.75V9a.76.76,0,1,0-1.51,0v8.84A.76.76,0,0,0,20.08,18.62Z"
      fill={fill} />
    <path
      d="M25.15,11.05a.74.74,0,0,0-.36-.09.75.75,0,0,0-.67.4.78.78,0,0,0-.05.58.75.75,0,0,0,.37.45,9.46,9.46,0,0,1-4.29,17.82H20a9.46,9.46,0,0,1-4.45-17.79.77.77,0,0,0,.3-1,.73.73,0,0,0-1-.3A11,11,0,0,0,20,31.73h.18a11,11,0,0,0,5-20.68Z"
      fill={fill} />
  </svg>
);

export default SVG;
