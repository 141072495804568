import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '40',
  height = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M29.9,28.9c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.2-0.3-0.6-0.1-0.9c1.7-2.1,2.7-4.7,2.6-7.5c0-2.7-0.9-5.4-2.6-7.5c-0.2-0.3-0.2-0.7,0.1-0.9c0.2-0.2,0.6-0.2,0.9,0c0,0,0,0,0,0c1.9,2.3,3,5.3,3,8.3c0,3-1,6-3,8.3C30.2,28.8,30,28.9,29.9,28.9z"
      fill={fill} />
    <path
      d="M32.7,32.2c-0.3,0-0.6-0.2-0.7-0.5c0-0.2,0-0.4,0.2-0.5c2.5-3,3.9-6.9,3.8-10.8c0-3.9-1.3-7.8-3.8-10.8C32,9.3,32,8.9,32.3,8.7c0.3-0.2,0.7-0.2,0.9,0.1c2.7,3.3,4.2,7.4,4.1,11.6c0.1,4.2-1.4,8.4-4.1,11.6C33.1,32.2,32.9,32.2,32.7,32.2z"
      fill={fill} />
    <path
      d="M27,25.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.2,0.1-0.4,0.2-0.5c2-2.4,2-5.9,0-8.3c-0.2-0.2-0.2-0.6,0-0.9c0,0,0,0,0,0c0.2-0.2,0.6-0.2,0.9,0c0,0,0,0,0,0c2.4,2.9,2.4,7,0,9.9C27.4,25.5,27.2,25.6,27,25.6z"
      fill={fill} />
    <path
      d="M5.5,31.9c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.3-0.4-0.2-0.6l0.6-4.5c-1.9-1.7-3-4.2-3-6.8c0-5.3,4.9-9.7,10.8-9.7s10.8,4.3,10.8,9.7s-4.9,9.7-10.8,9.7c-1,0-2-0.1-3-0.4c-1.3,1.1-2.8,2-4.4,2.7C5.7,31.9,5.6,31.9,5.5,31.9z M13.2,11.6c-5.3,0-9.6,3.8-9.6,8.4c0,2.3,1.1,4.5,2.9,6c0.2,0.1,0.2,0.3,0.2,0.6l-0.5,3.7c1.2-0.6,2.3-1.3,3.3-2.2c0.2-0.2,0.4-0.2,0.6-0.1c1,0.3,2,0.4,3,0.4c5.3,0,9.6-3.8,9.6-8.4S18.5,11.6,13.2,11.6z"
      fill={fill} />
    <circle
      cx="10.1"
      cy="20.4"
      r="0.8"
      fill={fill} />
    <circle
      cx="13.2"
      cy="20.4"
      r="0.8"
      fill={fill} />
    <circle
      cx="16.3"
      cy="20.4"
      r="0.8"
      fill={fill} />
  </svg>
);

export default SVG;
