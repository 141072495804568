import styled from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const FeaturesWrapper = styled.div`
  width: 36.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4.5rem;
`;

export const FeaturesTableWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  height: 28rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
      background-color: #d0d0d0;
      border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${c.bluePrimary};
  }
`;

export const FeaturesTable = styled.table`
  height: 20rem;
`;

export const Th = styled.th`
  font-size: 1.225rem;
  color: #fff;
  padding: .35rem 0;
  width: 5.25rem;
  border: .2rem solid #fff;

  ${({ good }) => good && `
    background-color: #d0d0d0;
  `}

  ${({ better }) => better && `
    background-color: #7c8180;
  `}

  ${({ best }) => best && `
    background-color: ${c.bluePrimary};
  `}
`;

export const FeatureRow = styled.tr`

  &:not(:last-of-type) {
    border-bottom: 1px dotted ${c.grayMed};
  }

  ${({ active }) => active && `
    background-color: ${c.grayLight2};
  `}
`;

export const FeatureName = styled.td`
  font-size: 1.1rem;
  padding: .7rem 1.92rem .7rem .48rem;

  ${({ emphasized }) => emphasized && `
    font-weight: 700;
    color: ${c.bluePrimary};
  `}
`;

export const FillDotWrapper = styled.td`
  text-align: center;
  vertical-align: middle;
`;

export const Disclaimer = styled.p`
  padding: 2rem 0 0;
  font-size: 1rem;
`;
