import React from 'react';

const SVG = ({
  style = {},
  fill = '#1e9be9',
  width = '34',
  height = '34',
  className = '',
  viewBox = '0 0 34 34'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(18.892 6.899)">
      <path
        d="M393.686,715.374h-3.032a2.056,2.056,0,0,1-2.055-2.055v-3.033a2.057,2.057,0,0,1,2.055-2.055h3.032a2.057,2.057,0,0,1,2.055,2.055v3.033A2.056,2.056,0,0,1,393.686,715.374Zm-3.032-5.59a.51.51,0,0,0-.5.5v3.033a.511.511,0,0,0,.5.5h3.032a.511.511,0,0,0,.5-.5v-3.033a.509.509,0,0,0-.5-.5Z"
        transform="translate(-388.6 -708.232)"
        fill={fill}
      />
      <path
        d="M400.406,715.374h-3.032a2.056,2.056,0,0,1-2.055-2.055v-3.033a2.057,2.057,0,0,1,2.055-2.055h3.032a2.057,2.057,0,0,1,2.055,2.055v3.033A2.057,2.057,0,0,1,400.406,715.374Zm-3.032-5.59a.509.509,0,0,0-.5.5v3.033a.51.51,0,0,0,.5.5h3.032a.511.511,0,0,0,.5-.5v-3.033a.51.51,0,0,0-.5-.5Z"
        transform="translate(-387.362 -708.232)"
        fill={fill}
      />
      <path
        d="M393.686,722.094h-3.032a2.056,2.056,0,0,1-2.055-2.055v-3.032a2.056,2.056,0,0,1,2.055-2.055h3.032a2.056,2.056,0,0,1,2.055,2.055v3.032A2.056,2.056,0,0,1,393.686,722.094Zm-3.032-5.589a.5.5,0,0,0-.5.5v3.032a.5.5,0,0,0,.5.5h3.032a.5.5,0,0,0,.5-.5v-3.032a.5.5,0,0,0-.5-.5Z"
        transform="translate(-388.6 -706.994)"
        fill={fill}
      />
      <path
        d="M400.406,722.094h-3.032a2.056,2.056,0,0,1-2.055-2.055v-3.032a2.056,2.056,0,0,1,2.055-2.055h3.032a2.057,2.057,0,0,1,2.055,2.055v3.032A2.056,2.056,0,0,1,400.406,722.094Zm-3.032-5.589a.5.5,0,0,0-.5.5v3.032a.5.5,0,0,0,.5.5h3.032a.5.5,0,0,0,.5-.5v-3.032a.5.5,0,0,0-.5-.5Z"
        transform="translate(-387.362 -706.994)"
        fill={fill}
      />
    </g>
    <g>
      <path
        d="M378.55,707.22a.773.773,0,0,1-.551-.23l-1.274-1.283a.776.776,0,0,1,1.1-1.093L379.1,705.9a.776.776,0,0,1-.549,1.323Z"
        transform="translate(-371.937 -702.041)"
        fill={fill}
      />
      <path
        d="M377.079,731.8a.776.776,0,0,1-.776-.776v-3.434a.775.775,0,1,1,1.55,0v3.434A.776.776,0,0,1,377.079,731.8Z"
        transform="translate(-371.973 -697.908)"
        fill={fill}
      />
      <g>
        <path
          d="M382.326,706a.776.776,0,0,1-.776-.776v-2.046a.776.776,0,0,1,1.551,0v2.046A.776.776,0,0,1,382.326,706Z"
          transform="translate(-371.007 -702.406)"
          fill={fill}
        />
        <path
          d="M379.807,733.187h-6.363a.776.776,0,0,1-.776-.776v-3.872a5.659,5.659,0,0,1,4.45-6.038h0c-.014,0,1.505-.557,3.381-1.032a7.687,7.687,0,0,0,.237-1.444.791.791,0,0,1,.831-.715.776.776,0,0,1,.715.833c-.192,2.534-.843,2.694-1.156,2.77-1.905.464-3.541,1.065-3.557,1.069a4.229,4.229,0,0,0-3.354,4.485l0,3.168h5.588a.776.776,0,0,1,0,1.552Z"
          transform="translate(-372.647 -699.292)"
          fill={fill}
        />
        <path
          d="M382.717,727.95a.774.774,0,0,1-.7-.45l-2.59-5.58a.775.775,0,1,1,1.407-.652l2.59,5.581a.774.774,0,0,1-.377,1.029A.762.762,0,0,1,382.717,727.95Z"
          transform="translate(-371.412 -699.014)"
          fill={fill}
        />
      </g>
    </g>
    <g transform="translate(10.604 2.344)">
      <path
        d="M386.339,707.22a.776.776,0,0,1-.549-1.323l1.273-1.283a.776.776,0,0,1,1.1,1.093l-1.274,1.283A.773.773,0,0,1,386.339,707.22Z"
        transform="translate(-380.872 -704.385)"
        fill={fill}
      />
      <path
        d="M387.611,731.8a.776.776,0,0,1-.775-.776v-3.434a.775.775,0,1,1,1.55,0v3.434A.776.776,0,0,1,387.611,731.8Z"
        transform="translate(-380.637 -700.252)"
        fill={fill}
      />
      <g transform="translate(0 17.672)">
        <path
          d="M392.5,733.187h-6.363a.776.776,0,0,1,0-1.553h5.587v-3.1a4.208,4.208,0,0,0-3.264-4.53c-.1-.032-1.738-.632-3.644-1.1-.313-.076-.964-.236-1.156-2.77a.776.776,0,1,1,1.547-.117,7.687,7.687,0,0,0,.237,1.444c1.877.475,3.4,1.033,3.466,1.059a5.645,5.645,0,0,1,4.362,6.088l0,3.8A.776.776,0,0,1,392.5,733.187Z"
          transform="translate(-381.222 -719.308)"
          fill={fill}
        />
        <path
          d="M382.377,727.95a.762.762,0,0,1-.326-.072.774.774,0,0,1-.377-1.029l2.59-5.581a.775.775,0,1,1,1.407.652l-2.59,5.58A.774.774,0,0,1,382.377,727.95Z"
          transform="translate(-381.602 -719.03)"
          fill={fill}
        />
      </g>
    </g>
    <path
      d="M383.267,723.027h-.251a5.985,5.985,0,0,1-5.978-5.979v-3.521a5.985,5.985,0,0,1,5.978-5.979h.251a5.985,5.985,0,0,1,5.978,5.979v3.521A5.985,5.985,0,0,1,383.267,723.027Zm-.251-13.927a4.433,4.433,0,0,0-4.428,4.427v3.521a4.433,4.433,0,0,0,4.428,4.427h.251a4.432,4.432,0,0,0,4.428-4.427v-3.521a4.432,4.432,0,0,0-4.428-4.427Z"
      transform="translate(-371.838 -701.459)"
      fill={fill}
    />
    <path
      d="M379.628,714.982a10.063,10.063,0,0,1-1.826-.156.776.776,0,0,1,.309-1.521c.041.006,4.031.775,6.229-1.658a.772.772,0,0,1,.969-.148c1.522.9,3.248,1.9,3.449,2l-.334.677.4.7c-.4.23-.417.244-3.788-1.737A8.027,8.027,0,0,1,379.628,714.982Z"
      transform="translate(-371.811 -700.751)"
      fill={fill}
    />
  </svg>
);

export default SVG;
