import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '95.118',
  height = '60.00143',
  className = '',
  viewBox = '0 0 95.118 60.00143'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M83.98125,15.76577a5.714,5.714,0,1,1,5.71393-5.714A5.72033,5.72033,0,0,1,83.98125,15.76577Zm-27.168,36.68466a5.71257,5.71257,0,1,1,5.714-5.71118A5.7197,5.7197,0,0,1,56.81328,52.45043ZM33.644,31.39382a5.714,5.714,0,1,1,5.714-5.714A5.72031,5.72031,0,0,1,33.644,31.39382ZM10.472,54.938a5.714,5.714,0,1,1,5.714-5.714A5.7203,5.7203,0,0,1,10.472,54.938ZM83.98125.675A9.34359,9.34359,0,0,0,77.716,16.98019L61.5031,38.6636a9.14464,9.14464,0,0,0-10.261.57642l-9.71265-8.52338A9.37264,9.37264,0,1,0,25.823,30.837L15.57873,41.37786a9.355,9.355,0,1,0,2.6275,2.55759L28.39458,33.44772a9.33568,9.33568,0,0,0,10.59686-.08115L48.788,41.9627a9.27122,9.27122,0,0,0-1.35156,4.77655,9.37687,9.37687,0,0,0,18.75373,0,9.28084,9.28084,0,0,0-1.9336-5.64117l16.627-22.23471a9.25384,9.25384,0,0,0,3.09765.56527,9.37685,9.37685,0,0,0,0-18.75369Z"
        fill={fill} 
      />
    </g>
  </svg>
);

export default SVG;
