import React from 'react'
// Style
import { Container } from './FillDot.styles'

const FillDot = ({
  percent=100
}) => {

  return(
    <Container percent={percent} />
  );
}

export default FillDot;