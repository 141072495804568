import React, { useContext } from 'react';
// Data
import { choicesData } from '../../../../data/struggleScaleData';
// Components
import { FlipCoin } from '../../../../components/FlipCoin';
// Child Components
import { Side } from './children/Side';
// Context
import assessmentContext from '../../../../contexts/assessment/assessmentStore';
// Style
import { Choice,Choices,Question,Value } from './StruggleScale.styles';

const StruggleScale = () => {
  const { assessmentDispatch,assessmentState } = useContext(assessmentContext);

  const choose = (num) => {
    
    (num !== assessmentState.frustrationLevel) && assessmentDispatch({
      type: 'SET_FRUSTRATION_LEVEL',
      payload: num
    });
  }

  return(
    <>
      <Choices>
        {choicesData.map((choice,index) => {
          if(index === 0) {
            return null;
          }
          return(
            <Choice key={choice.scale} onClick={() => choose(choice.scale)}>
              <FlipCoin
                chosen={choice.scale === assessmentState.frustrationLevel}
                side1={<Side icon={choice.icon} fill="#FFF" />}
                side2={<Side icon={choice.icon} fill="#FFF" />}
              />
              <Value>{choice.scale}</Value>
            </Choice>
          )
        })}
      </Choices>
      <Question show={assessmentState.frustrationLevel}>How would your loved ones or friends rate that impact?</Question>
    </>
  );
};

export default StruggleScale;
