import styled from 'styled-components';

export const Container = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 36rem;
  width: 22.1rem;
  padding-right: 1rem;
  transition: opacity .3s .2s ease;

  ${({ hide }) => hide && `
    opacity: 0;
    transition: opacity .1s ease;
  `}
`;

export const HearingAid360 = styled.div`
  width: 100%;
`;

// export const Image = styled.img`
//   width: 100%;
// `;