import React from 'react';

const SVG = ({
  style = {},
  fill = '#1e9be9',
  width = '32.26',
  height = '20.07',
  className = '',
  viewBox = '0 0 32.26 20.07'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-96.956 -1073.607)">
      <path
        d="M97.842,1092.369a.761.761,0,0,1-.761-.76v-15.965a.76.76,0,0,1,1.162-.645l12.918,7.982a.759.759,0,0,1,0,1.291l-12.918,7.982A.768.768,0,0,1,97.842,1092.369Zm.761-15.361v13.237l10.71-6.618Z"
        transform="translate(0 0.18)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.25"
      />
      <path
        d="M123.254,1093.843a.761.761,0,0,1-.648-1.158,17.609,17.609,0,0,0,.38-17.782.761.761,0,1,1,1.33-.739,19.131,19.131,0,0,1-.412,19.316A.764.764,0,0,1,123.254,1093.843Zm-4.485-2.745a.762.762,0,0,1-.648-1.158,12.393,12.393,0,0,0,.267-12.482.761.761,0,0,1,1.332-.739,13.915,13.915,0,0,1-.3,14.015A.761.761,0,0,1,118.769,1091.1Zm-4.5-2.754a.762.762,0,0,1-.648-1.159,7.1,7.1,0,0,0,.141-7.162.762.762,0,0,1,1.332-.739,8.628,8.628,0,0,1-.174,8.7A.764.764,0,0,1,114.271,1088.344Z"
        transform="translate(2.611 0)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.25"
      />
    </g>
  </svg>
);

export default SVG;
