import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { Switcher } from './children/Switcher';

const Audiogram = () => {

  return(
    <Page>
      <Progress visible goTo={'/hearing-loss'} navDirection={'prev'} text={'Back'} />
      <Switcher />
      <Progress visible goTo={'/types-of-hearing-loss'} navDirection={'next'} text={'Next'} />
    </Page>
  );
};

export default Audiogram;
