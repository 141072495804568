import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 1.4rem;
  text-align: center;
  width: 80%;
  color: ${c.bluePrimary};
`;

export const Detail = styled.p`
  font-size: 1.9rem;
  margin-bottom: 2.4rem;
`;
