import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';
// Styles
import { Container,ProgressDot,ProgressDots,Slide } from './Swiper.styles';

const Swiper = ({
  initialSlide=0,
  progressDots=false,
  timeout=1000,
  slides
}) => {
  const [activeSlide,setActiveSlide] = useState(initialSlide);
  const num = slides.length - 1;
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => handleSwipe(eventData),
    trackMouse: true,
    trackTouch: true
  });

  const handleSwipe = (eventData) => {
    if(eventData.dir === 'Right') {
      setActiveSlide(activeSlide === 0 ? num : activeSlide-1)
    } else if(eventData.dir === 'Left') {
      setActiveSlide(activeSlide === num ? 0 : activeSlide+1)
    }
  }

  return(
    <Container {...swipeHandlers}>
      <TransitionGroup className="transition-group">
        <CSSTransition
          classNames="slide"
          key={activeSlide}
          timeout={timeout}
          unmountOnExit
        >
          <Slide timeout={timeout}>
            {slides[activeSlide]}
          </Slide>
        </CSSTransition>
      </TransitionGroup>
        {progressDots &&
          <ProgressDots className="swiperProgressDots">
            {slides.map((dot,index) => {
              return(
                <ProgressDot
                  active={activeSlide === index}
                  key={index}
                  num={index}
                  onClick={() => setActiveSlide(index)}
                />
              )
            })}
          </ProgressDots>
        }
    </Container>
  );
};

export default Swiper;
