import React from 'react';
// Components
import { Swiper } from '../../../../../../components/Swiper';
// Slides
import { Slide1,Slide2,Slide3 } from './BrainHealthSlides';
// Style
import { Container,SwiperWrapper } from './BrainHealth.styles';
import { SubTitle,Title } from '../../Slide.styles';

const BrainHealth = () => {

  return(
    <Container>
      <Title>Impacts of hearing loss</Title>
      <SubTitle>Brain Health/Cognition</SubTitle>
      <SwiperWrapper>
        <Swiper
          progressDots={true}
          slides={[<Slide1 />,<Slide2 />,<Slide3 />]}
          timeout={500}
        />
      </SwiperWrapper>
    </Container>
  );
};

export default BrainHealth;
