import React, { useContext,useState } from 'react';
// Child Components
import { AudiogramSimulation } from './children/AudiogramSimulation';
import { Toggles } from './children/Toggles';
// Context
import assessmentContext from '../../../../contexts/assessment/assessmentStore';
// Data
import { AUDIOGRAM_DATA as data } from '../../../../data/audiogramData';
// Style
import { Title,Wrapper } from './Switcher.styles';

const Switcher = () => {
  const { assessmentState } = useContext(assessmentContext);
  const [paneToggled,setPaneToggled] = useState(false);
  const [bananaToggled,setBananaToggled] = useState(false);
  const [iconsToggled, setIconsToggled] = useState(false);
  const [active,setActive] = useState(assessmentState.hearingLossLevel);

  const togglePane = () => setPaneToggled(paneToggled ? false : true);
  const toggleBanana = () => setBananaToggled(bananaToggled ? false : true);
  const toggleIcons = () => setIconsToggled(iconsToggled ? false : true);

  return(
    <>
      <Title>{data[active].name}</Title>
      <Wrapper>
        <AudiogramSimulation
          active={active}
          bananaToggled={bananaToggled}
          iconsToggled={iconsToggled}
          paneToggled={paneToggled}
          setActive={setActive}
        />
        <Toggles
          active={active}
          bananaToggled={bananaToggled}
          iconsToggled={iconsToggled}
          paneToggled={paneToggled}
          pinned={assessmentState.hearingLossLevel}
          toggleBanana={toggleBanana}
          toggleIcons={toggleIcons}
          togglePane={togglePane}
        />
      </Wrapper>
    </>
  );
};

export default Switcher;
