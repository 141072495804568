import React from 'react';
// Child Components
import { MiniRemoteMicSwiper } from './children/MiniRemoteMicSwiper';
import { RemoteSwiper } from './children/RemoteSwiper';
import { RemoteMicPlusSwiper } from './children/RemoteMicPlusSwiper';
import { TableMicSwiper } from './children/TableMicSwiper';
// Style
import {
  Container,
  DefaultImage
} from './Swipers.styles';

const Swipers = ({
  active,
  items
}) => {

  const filteredItem = (id) => {
    return items.filter((item) => item.id === id)[0];
  }

  return(
    <Container>
      {active === '' &&
        <DefaultImage src="/images/accessories/accessories-family-no-tv-streamer.png" alt="accessories family" />
      }
      {active === 'remote-mic-plus' &&
        <RemoteMicPlusSwiper data={filteredItem('remote-mic-plus')} />
      }
      {active === 'mini-remote-mic' &&
        <MiniRemoteMicSwiper data={filteredItem('mini-remote-mic')} />
      }
      {active === 'remote' &&
        <RemoteSwiper data={filteredItem('remote')} />
      }
      {active === 'table-mic' &&
        <TableMicSwiper data={filteredItem('table-mic')} />
      }
    </Container>
  );
};

export default Swipers;
