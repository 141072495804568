import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const IconWrapper = styled.div`

  ${({ name }) => name === 'starkey' && `
    margin: 0 0 1rem;
  `}

  ${({ name }) => name !== 'starkey' && `
    margin: 0 0 3rem;
  `}
`;

export const Form = styled.form`
  width: 24rem;
  padding: 3rem 1rem .5rem;
  border-top: 1px solid ${c.grayMed};
  border-bottom: 1px solid ${c.grayMed};
  margin-bottom: 1rem;
`;

export const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;

  ${({ centered }) => centered && `
    display: flex;
    justify-content: center;
  `}
`;

export const Label = styled.label`
  display: block;
  font-family: "DIN Next";
  font-size: 1rem;
  margin-bottom: .625rem;
  padding-left: 1rem;
  font-weight: 600;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  border: none;
  background-color: ${c.grayLight2};
  font-family: "DIN Next";
  font-size: 1rem;
  padding: .9375rem 1rem;
  border-radius: 3rem;

  &:active {
    outline: 0;
    border: 0;
  }

  &:focus {
    outline: 0;
    border: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const FinePrint = styled.div`
  width: 24rem;

  p {
    font-family: "DIN Next";
    font-size: 0.8125rem;
    color: #7c8180;
    margin-bottom: .8rem;
  }
`;
