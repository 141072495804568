import styled from 'styled-components';

export const Container = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background-color: rgba(89,91,96,0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: all .2s linear;

  ${({ active }) => active && `
    bottom: -1.875rem;
    left: -1.875rem;
    width: 20.5rem;
    height: 20.5rem;
  `}
`;

export const Title = styled.h2`
  opacity: 0;
  text-align: center;
  font-size: 3.125rem;
  color: #fff;
  margin-bottom: .75rem;

  ${({ active }) => active && `
    opacity: 1;
    transition: opacity .2s .2s linear;
  `}
`;

export const Text = styled.p`
  opacity: 0;
  text-align: center;
  font-family: 'DIN Next';
  font-size: 1rem;
  color: #fff;

  ${({ active }) => active && `
    opacity: 1;
    transition: opacity .2s .2s linear;
  `}
`;