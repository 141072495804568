import React from 'react';
// Style
import { Container,Line } from './PlusMinus.styles';

const PlusMinus = ({ toggled }) => {

  return(
    <Container>
      <Line toggled={toggled} />
      <Line toggled={toggled} />
    </Container>
  );
};

export default PlusMinus;
