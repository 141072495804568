import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  font-size: 3.4rem;
  color: ${c.bluePrimary};
  margin-bottom: 3rem;
`;

export const GraphWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
`;

export const Graph = styled.img`
  width: 10.3rem;
  height: auto;
`;

export const Choices = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Choice = styled.button`
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 50%;
  border: none;
  outline: none;
  font-family: 'Mr Eaves XL Sans';
  font-size: 0.9rem;
  font-weight: 700;
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  &:nth-of-type(1) {
    background-color: ${c.rainbow[0]};
  }

  &:not(:nth-of-type(1)) {
    margin-left: -1rem;
  }

  &:nth-of-type(2) {
    background-color: ${c.rainbow[1]};
    width: 6.35rem;
    height: 6.35rem;
  }

  &:nth-of-type(3) {
    background-color: ${c.rainbow[2]};
    width: 6.75rem;
    height: 6.75rem;
  }

  &:nth-of-type(4) {
    background-color: ${c.rainbow[3]};
    width: 7.15rem;
    height: 7.15rem;
  }

  &:nth-of-type(5) {
    background-color: ${c.rainbow[4]};
    width: 7.55rem;
    height: 7.55rem;
    padding: 1.2rem;
  }

  &:nth-of-type(6) {
    background-color: ${c.rainbow[5]};
    width: 7.95rem;
    height: 7.95rem;
    padding: 1.4rem;
  }

  &:nth-of-type(7) {
    background-color: ${c.rainbow[6]};
    width: 8.35rem;
    height: 8.35rem;
  }

  &:nth-of-type(8) {
    background-color: ${c.rainbow[7]};
    width: 8.75rem;
    height: 8.75rem;
  }

  &:nth-of-type(9) {
    background-color: ${c.rainbow[8]};
    width: 9.15rem;
    height: 9.15rem;
  }

  ${({ active }) => active && `
    border: .375rem solid #fff;
    box-shadow: 0 0 .625rem rgba(0,0,0,.17), inset 0 0 .625rem rgba(0,0,0,.17);
    z-index: 2;
  `}
`;
