import React from 'react';
// Child Components
import { BrainHealth } from './children/BrainHealth';
import { PhysicalHealth } from './children/PhysicalHealth';
import { SocialInteraction } from './children/SocialInteraction';
import { LonelinessDepression } from './children/LonelinessDepression';
import { BalanceSafety } from './children/BalanceSafety';
// Style
import { Container,Content } from './Modal.styles';

const Modal = ({ isOpen,modalName }) => {

  return(
    <Container isOpen={isOpen}>
      <Content>
        {modalName === 'brain-health' && <BrainHealth />}
        {modalName === 'physical-health' && <PhysicalHealth />}
        {modalName === 'social-interaction' && <SocialInteraction />}
        {modalName === 'loneliness-depression' && <LonelinessDepression />}
        {modalName === 'balance-safety' && <BalanceSafety />}
      </Content>
    </Container>
  );
};

export default Modal;
