import styled from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const Container = styled.div`
  z-index: 3;
  width: 100%;
  height: 12.5%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${c.grayMed};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 93px 97px 97px;
`;

export const Name = styled.h2`
  color: #000;
  font-size: 1.5625rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: .4rem;
`;

export const Description = styled.h3`
  color: ${c.grayLight1};
  font-size: 1.25rem;
  text-align: center;
`;