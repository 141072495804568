import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '73.77',
  height = '77.63',
  className = '',
  viewBox = '0 0 73.77 77.63'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M78.241,55.233a19.108,19.108,0,0,0-5.311-14.445,24.866,24.866,0,0,0-35.789-.056,19.267,19.267,0,0,0-5.393,14.492c.674,10.279,4.273,13.068,7.443,15.526,2.441,1.892,4.367,3.385,4.715,8.718a1.581,1.581,0,0,0,.33.867,1.708,1.708,0,0,0,.047.326,1.592,1.592,0,0,0,1.536,1.184,1.625,1.625,0,0,0,.4-.051l19.345-5.071a1.667,1.667,0,0,0,.455-.15,1.6,1.6,0,0,0,.892-1.206c.927-2.42,2.39-3.587,4.076-4.929C74.053,67.988,77.528,65.216,78.241,55.233ZM69.008,67.949a14.1,14.1,0,0,0-4.9,5.869L46.986,78.31C46.36,72.3,43.7,70.236,41.135,68.245,38.23,66,35.493,63.874,34.91,55.019A16.127,16.127,0,0,1,39.453,42.9a21.7,21.7,0,0,1,31.151.047A15.964,15.964,0,0,1,75.074,55C74.461,63.6,71.814,65.714,69.008,67.949Z"
      transform="translate(-18.105 -18.934)"
      fill={fill}
    />
    <path
      d="M81.6,161.662,62.075,166.78a1.588,1.588,0,0,0,.4,3.123,1.624,1.624,0,0,0,.4-.051l19.525-5.118a1.588,1.588,0,0,0-.807-3.072Z"
      transform="translate(-34.768 -92.276)"
      fill={fill}
    />
    <path
      d="M81.6,144.5l-19.525,5.123a1.588,1.588,0,0,0,.4,3.123,1.625,1.625,0,0,0,.4-.051l19.525-5.118A1.59,1.59,0,0,0,81.6,144.5Z"
      transform="translate(-34.768 -82.481)"
      fill={fill}
    />
    <path
      d="M85.427,9.734a1.589,1.589,0,0,0,1.587-1.587V1.587a1.587,1.587,0,1,0-3.175,0v6.56A1.589,1.589,0,0,0,85.427,9.734Z"
      transform="translate(-47.871)"
      fill={fill}
    />
    <path
      d="M133.637,21.6,129,26.24a1.59,1.59,0,0,0,1.124,2.711,1.562,1.562,0,0,0,1.12-.468l4.638-4.638a1.587,1.587,0,1,0-2.244-2.244Z"
      transform="translate(-73.39 -12.07)"
      fill={fill}
    />
    <path
      d="M157.423,80.7h-6.56a1.587,1.587,0,0,0,0,3.175h6.56a1.587,1.587,0,0,0,0-3.175Z"
      transform="translate(-85.236 -46.078)"
      fill={fill}
    />
    <path
      d="M129.033,127.421a1.588,1.588,0,0,0-2.244,2.248l4.638,4.638a1.589,1.589,0,0,0,1.124.468,1.59,1.59,0,0,0,1.12-2.711Z"
      transform="translate(-72.128 -72.49)"
      fill={fill}
    />
    <path
      d="M32.527,28.485a1.589,1.589,0,0,0,1.124.468,1.562,1.562,0,0,0,1.12-.468,1.582,1.582,0,0,0,0-2.244L30.133,21.6a1.588,1.588,0,1,0-2.244,2.248Z"
      transform="translate(-15.658 -12.071)"
      fill={fill}
    />
    <path
      d="M8.147,77.58H1.583a1.587,1.587,0,0,0,0,3.175h6.56a1.587,1.587,0,0,0,0-3.175Z"
      transform="translate(0 -44.297)"
      fill={fill}
    />
    <path
      d="M30.317,127.428l-4.638,4.638a1.59,1.59,0,0,0,1.124,2.711,1.562,1.562,0,0,0,1.12-.468l4.638-4.638a1.587,1.587,0,1,0-2.244-2.244Z"
      transform="translate(-14.396 -72.492)"
      fill={fill}
    />
    <path
      d="M84.258,67.595a1.585,1.585,0,0,0-2.162.6L72.585,84.983l-5.676-7.812a1.586,1.586,0,1,0-2.566,1.866l7.122,9.807a1.585,1.585,0,0,0,1.283.652.547.547,0,0,0,.086,0,1.578,1.578,0,0,0,1.291-.8L84.85,69.757A1.578,1.578,0,0,0,84.258,67.595Z"
      transform="translate(-36.564 -38.477)"
      fill={fill}
    />
  </svg>
);

export default SVG;
