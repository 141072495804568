import React from 'react';
// Components
import { Button } from '../../components/Button';
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Style
import { BGVideo,Content,Description,Title,VidContainer } from './HearingEvaluation.styles';

const HearingEvaluation = () => {

  return(
    <Page>
      <Progress visible goTo={'/understanding-sound'} navDirection={'prev'} text={'Back'} />
      <Content>
        <Title>Your hearing evaluation</Title>
        <Description>
          Let's evaluate your hearing and see what the results tell us.
        </Description>
        <Button goTo={'/hearing-loss'} text="Done">Begin</Button>
      </Content>
      <VidContainer>
        <BGVideo
          playsInline=""
          loop="loop"
          autoPlay="autoPlay"
          muted="muted"
          id="bgvideo"
        >
          <source
            poster="/images/hearing-test/video/hearing-test-poster.jpg"
            src="/videos/hearing-test/patient-journey-white.mp4"
            type="video/mp4"
          />
          <source
            poster="/images/hearing-test/video/hearing-test-poster.jpg"
            src="/videos/hearing-test/patient-journey-white.webm"
            type="video/webm"
          />
          <source
            poster="/images/hearing-test/video/hearing-test-poster.jpg"
            src="/videos/hearing-test/patient-journey-white.ogv"
            type="video/ogv"
          />
        </BGVideo>
      </VidContainer>
    </Page>
  );
};

export default HearingEvaluation;
