import React from 'react';

const SVG = ({
  style = {},
  fill = '#506162',
  width = '80.53209',
  height = '81.49023',
  className = '',
  viewBox = '0 0 80.53209 81.49023'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M27.308,49.617A6.88352,6.88352,0,0,1,37.902,44.35c2.374-2.74,6.963-3.872,10.188-3.8a49.36234,49.36234,0,0,1,2.323-7.943c-.158.261-12.738-8.255-1.379-20.961,5.331-5.959,15.229-2.985,24.3-10.021-2.461,8.071-1.542,13.831-2.3,18-2.52,13.925-11.81,16.633-18.441,14.6"
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.25}
    />
    <path
      d="M50.413,32.601a63.02919,63.02919,0,0,1,13.38-20.67"
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.25}
    />
    <path
      d="M48.09,40.547c2.584.96,7.595,2.419,8.695,6.22,3.62482-1.35225,12.35484,3.49631,10.811,9.427"
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.25}
    />
    <path
      d="M54.276,62.522,69.2,55.511c8.843-6.429,11.968,1.711,8.03,3.709C71.44216,62.854,54.88818,73.35137,49.365,76.82,32.24541,86.09974,21.98543,70.87311,6.83,74.437"
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.25}
    />
    <path
      d="M1.625,52.408c4.54163-1.42415,16.94135-8.59038,21.09982-5.16784,10.92994,8.5061,14.75819,11.76806,28.67046,11.1607C61.89361,63.7707,38.07183,71.78169,24.638,61.932"
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.25}
    />
  </svg>
);

export default SVG;
