import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Auth
import { useMsal } from "@azure/msal-react";

const Logout = () => {
  const history = useHistory();
  const { accounts,inProgress,instance } = useMsal();

  function handleLogout() {
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }

  useEffect(() => {

    // If MSAL is done doing things
    if(inProgress === 'none') {

      // If an account exists
      // (this check prevents a logout loop)
      if(accounts.length) {
        handleLogout();
      } else {
        history.push('/login');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[accounts,history,inProgress]);

  return null;
};

export default Logout;
