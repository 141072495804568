import styled from 'styled-components';
import { colors as c } from '../../../../../../styles/colors';

export const Container = styled.div`
  width: 100%; 
  flex-basis: 81%;
  display: flex;
  justify-content: center;
`;

export const Control = styled.button`
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0 1.4rem;

  ${({ hide }) => hide && `
    visibility: hidden;
  `}
`;

export const ControlImg = styled.img`
  width: 3.125rem;
  height: 3.125rem;
`;

export const Panes = styled.div`
  position: relative;
  flex-basis: 71%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Pane = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  ${({ hidden }) => hidden && `
    opacity: 0;
  `}
`;

export const AudiogramPane = styled(Pane)`
  
`;

export const Gram = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  opacity: 0;
  padding-left: 1.75rem;
  transition: opacity .25s linear;

  ${({ show }) => show && `
    opacity: 1;
  `}

  ${({ layer }) => layer==='base' && `
    opacity: 1;
    z-index: 1;
  `}

  ${({ layer }) => layer==='banana' && `
    z-index: 2;
  `}

  ${({ layer }) => layer==='icons' && `
    z-index: 3;
  `}

  ${({ layer }) => layer==='full' && `
    z-index: 4;
  `}
`;

export const TextPane = styled(Pane)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextPaneWrapper = styled.div`
  position: relative;
  width: 100%;
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 7px 4px rgba(0,0,0,.1);
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
  overflow: hidden;
`;

export const TextWrapper = styled.div`
  position: relative;
  flex: 1;
  border: 2px solid ${c.grayMed};
  border-bottom: none;
`;

export const ClearText = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4.5rem;
  background-color: #fff;
  transition: opacity .15s linear;

  p {
    font-family: 'DIN Next';
    font-size: 1.25rem;
    line-height: 1.9;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;

export const BlurText = styled(ClearText)`
  z-index: 2;

  span {
    color: transparent;
    text-shadow: 0 0 .25rem rgba(0,0,0,.4);
  }

  ${({ hidden }) => hidden && `
    opacity: 0;
  `}
`;

export const BlurToggle = styled.button`
  width: 100%;
  background: #e8e8a8;
  border: none;
  outline: none;
  flex-basis: 2.1rem;
  padding: 0;
  display: flex;
`;

export const BlurToggleHalf = styled.span`
  font-family: 'Mr Eaves XL Sans';
  font-weight: 700;
  flex: 1;
  width: 50%;
  height: 100%;
  font-size: .94rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${c.grayMed};

  ${({ active }) => active && `
    background-color: ${c.bluePrimary};
    color: #fff;
  `}
`;
