import React from 'react';
// Components
import { CheckCircle } from '../../../../components/CheckCircle';
// Style
import {
  CheckList,
  CheckListItem,
  Container,
  Image,
  ItemText,
  Title
} from './CareExperiencePanel.styles';

const CareExperiencePanel = () => {

  return(
    <Container>
      <Title>Care experience</Title>
      <Image src="/images/appointment-summary/ongoing-care-graphic.png" alt="ongoing care graphic" />
      <CheckList>
        <CheckListItem>
          <CheckCircle /><ItemText>Virtual Fitting Support</ItemText>
        </CheckListItem>
        <CheckListItem>
          <CheckCircle /><ItemText>Follow-Up Service</ItemText>
        </CheckListItem>
        <CheckListItem>
          <CheckCircle /><ItemText>Warranty</ItemText>
        </CheckListItem>
        <CheckListItem>
          <CheckCircle /><ItemText>Office Visits</ItemText>
        </CheckListItem>
      </CheckList>
    </Container>
  );
};

export default CareExperiencePanel;
