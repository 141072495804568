export const HOW_WE_HEAR_DATA = {
  earSections: [
    "Sound<br />Reception<br />Area",
    "External<br />Ear",
    "Middle<br />Ear",
    "Inner<br />Ear",
    "Brain &bull; Auditory Centers",
    "Fluid<br />Area",
    "Auditory<br />Ossicles",
    "Sound<br />Construction<br />Area",
    "Sound<br />Reception<br />Area"
  ],
  modals: {
    tinnitus: {
      title: "Tinnitus",
      text: "Tinnitus is the perception of phantom<br />sounds that are generated in the brain,<br />but can be triggered by anything that<br />damages the auditory pathway."
    },
    balance: {
      title: "Balance",
      text: "Three small interconnected tube-like<br />structures that are filled with fluid.<br />They are located in your inner ear<br />and help maintain your balance."
    }
  },
  labels: [
    {
      slug: "eardrum",
      src: "/images/how-we-hear/eardrum-label.svg",
    },
    {
      slug: "auditory-nerve",
      src: "/images/how-we-hear/auditory-nerve-label.svg",
    },
    {
      slug: "cochlea",
      src: "/images/how-we-hear/cochlea-label.svg",
    },
    {
      slug: "semi-circular-canals",
      src: "/images/how-we-hear/semi-circular-canals-label.svg",
    }
  ]
};
