import React from 'react';
// Style
import { Container,Image } from './MiniRemoteMicSwiper.styles';

const MiniRemoteMicSwiper = ({ data }) => {

  return(
    <Container>
      <Image src={data.images[0]} alt="mini remote microphone" />
    </Container>
  );
};

export default MiniRemoteMicSwiper;
