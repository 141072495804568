import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '88.59',
  height = '66.25',
  className = '',
  viewBox = '0 0 88.59 66.25'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(13880.295 -2975.875)">
      <g transform="translate(-13880.295 2978.717)">
        <path
          d="M1.546,12.116a4.592,4.592,0,0,1-4.58-4.9L-.816-25.578a1.526,1.526,0,0,0-.868-1.457A7.218,7.218,0,0,1-5.7-33.515v-9.613a1.549,1.549,0,0,1,1.548-1.548A1.549,1.549,0,0,1-2.6-43.128v9.613A4.137,4.137,0,0,0-.3-29.8a4.692,4.692,0,0,1,1.843,1.641A4.692,4.692,0,0,1,3.389-29.8,4.137,4.137,0,0,0,5.7-33.515v-9.613a1.549,1.549,0,0,1,1.548-1.548,1.549,1.549,0,0,1,1.548,1.548v9.613a7.215,7.215,0,0,1-4.017,6.479,1.529,1.529,0,0,0-.868,1.458L6.126,7.214a4.592,4.592,0,0,1-4.58,4.9m0-26.729L.055,7.424a1.477,1.477,0,0,0,.4,1.121,1.527,1.527,0,0,0,2.184,0,1.477,1.477,0,0,0,.4-1.121Z"
          transform="translate(5.701 44.676)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13872.662 2978.717)">
        <path
          d="M.33,2.711A1.548,1.548,0,0,1-1.218,1.163V-8.449A1.548,1.548,0,0,1,.33-10,1.549,1.549,0,0,1,1.879-8.449V1.163A1.549,1.549,0,0,1,.33,2.711"
          transform="translate(1.218 9.997)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13876.532 2978.717)">
        <path
          d="M.33,2.711A1.548,1.548,0,0,1-1.218,1.163V-8.449A1.548,1.548,0,0,1,.33-10,1.548,1.548,0,0,1,1.879-8.449V1.163A1.548,1.548,0,0,1,.33,2.711"
          transform="translate(1.218 9.997)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13801.445 2975.875)">
        <path
          d="M.98,13.327A4.592,4.592,0,0,1-3.6,8.427L-2.376-9.682c.13-1.909.2-3.848.2-5.762V-46.751a2.357,2.357,0,0,1,1.8-2.315,2.366,2.366,0,0,1,2.7,1.177C4.738-43.45,6.129-36.773,6.129-29.57A48.39,48.39,0,0,1,4.6-17.156a13.875,13.875,0,0,0-.46,4.566L5.56,8.427a4.59,4.59,0,0,1-4.58,4.9M.915-43.406v27.961c0,1.984-.067,3.993-.2,5.972L-.512,8.635a1.478,1.478,0,0,0,.4,1.121,1.53,1.53,0,0,0,2.185,0,1.479,1.479,0,0,0,.4-1.121L1.048-12.38a16.965,16.965,0,0,1,.561-5.584A45.243,45.243,0,0,0,3.034-29.57,42.771,42.771,0,0,0,.915-43.406"
          transform="translate(3.612 49.145)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13868.236 2976.027)">
        <path
          d="M7.05,14.1A33.085,33.085,0,0,1-26-18.947,33.086,33.086,0,0,1,7.05-52,33.086,33.086,0,0,1,40.1-18.947,33.085,33.085,0,0,1,7.05,14.1m0-63A29.987,29.987,0,0,0-22.9-18.947,29.987,29.987,0,0,0,7.05,11,29.987,29.987,0,0,0,37-18.947,29.987,29.987,0,0,0,7.05-48.9"
          transform="translate(25.998 51.997)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13856.813 2987.451)">
        <path
          d="M4.613,9.227A21.65,21.65,0,0,1-17.012-12.4,21.649,21.649,0,0,1,4.613-34.024,21.65,21.65,0,0,1,26.239-12.4,21.651,21.651,0,0,1,4.613,9.227m0-40.154A18.549,18.549,0,0,0-13.915-12.4,18.55,18.55,0,0,0,4.613,6.13,18.55,18.55,0,0,0,23.142-12.4,18.549,18.549,0,0,0,4.613-30.927"
          transform="translate(17.012 34.024)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
