import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 5rem 0 0;
  color: ${c.bluePrimary};
  font-size: 3.3rem;
  font-weight: 300;
  text-align: center;
  transition: opacity .3s .2s ease;

  ${({ hide }) => hide && `
    opacity: 0;
    transition: opacity .1s ease;
  `}
`;