import React from 'react';
import styled from 'styled-components';
// Components
import { Swiper } from '../../../../../../components/Swiper';
// Style
import { Container } from './RemoteSwiper.styles';

const RemoteSwiper = ({ data }) => {

  return(
    <Container>
      <Swiper
        progressDots={true}
        slides={[<Slide1 data={data} />,<Slide2 data={data} />]}
        timeout={500}
      />
    </Container>
  );
};

const SContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;S
`;

const S1Image = styled.img`
  width: 70%;
  height: auto;
  margin-bottom: 2rem;
`;

const Slide1 = ({ data }) => {
  
  return(
    <SContainer>
      <S1Image src={data.images[0]} alt="remote" />
    </SContainer>
  )
}

const S2Image = styled.img`
  width: 70%;
  height: auto;
  margin-bottom: 3rem;
`;

const Slide2 = ({ data }) => {
  
  return(
    <SContainer>
      <S2Image src={data.images[1]} alt="remote" />
    </SContainer>
  )
}

export default RemoteSwiper;
