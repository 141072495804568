export const navData = [
  '/todays-visit',
  '/self-descriptor',
  '/favorite-activities',
  '/wellness-concerns',
  '/healthy-living-goals',
  '/hearing-challenges',
  '/lifestyle-assessment-results',
  '/your-story',
  '/how-we-hear',
  '/understanding-sound',
  '/hearing-evaluation',
  '/hearing-loss',
  '/audiogram',
  '/types-of-hearing-loss',
  '/impacts-of-hearing-loss',
  '/technology-levels',
  '/hearing-aid-transition',
  '/hearing-aid-styles',
  '/accessories',
  '/appointment-summary'
];