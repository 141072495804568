import styled from 'styled-components';

export const Image = styled.img`
  width: 33.7rem;
  margin-bottom: 4rem;
`;

export const Title = styled.h1`
  font-size: 3.25rem;
  font-weight: 300;
  margin-bottom: 3rem;
`;
