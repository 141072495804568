import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '37.19',
  height = '34.9',
  className = '',
  viewBox = '0 0 37.19 34.9'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M22.341,18.224l1.111-2.071,1.123,2.071Zm5.624,3.136-3.83-7.117a.765.765,0,0,0-.683-.393.778.778,0,0,0-.683.393l-3.888,7.279a.705.705,0,0,0,.336.961.9.9,0,0,0,.347.081.768.768,0,0,0,.683-.393l1.342-2.5H25.35l1.261,2.349a.781.781,0,0,0,1.018.312.718.718,0,0,0,.336-.972"
      transform="translate(2.956 2.174)"
      fill={fill}
    />
    <path
      d="M34.509,24.927a9.626,9.626,0,0,1-10.473,4.3,8.932,8.932,0,0,1-6.967-8.182A10.051,10.051,0,0,0,21.7,13.158,9.769,9.769,0,0,1,32.75,14.315a8.36,8.36,0,0,1,1.759,10.612M10.878,21.432c-5.15,0-9.35-3.923-9.35-8.749s4.2-8.749,9.35-8.749a9.048,9.048,0,0,1,9.293,8.749,9.048,9.048,0,0,1-9.293,8.749m26.188-2.326a9.948,9.948,0,0,0-3.287-5.856,11.351,11.351,0,0,0-12.139-1.7A10.636,10.636,0,0,0,10.878,2.5,11.166,11.166,0,0,0,3.194,5.474a9.734,9.734,0,0,0,0,14.419,11.166,11.166,0,0,0,7.684,2.974,11.389,11.389,0,0,0,4.71-1.018,10.479,10.479,0,0,0,8.054,8.772h.012a11.959,11.959,0,0,0,2.627.3,11.043,11.043,0,0,0,9.559-5.3,9.821,9.821,0,0,0,1.227-6.515"
      transform="translate(0 0.389)"
      fill={fill}
    />
    <path
      d="M14.293,27.006h0c-.012-.035-.012-.069-.023-.081v-.012c-.012-.023-.012-.046-.035-.069,0-.012-.012-.012-.012-.023s-.023-.035-.035-.046-.012-.012-.012-.023l-.046-.069a.307.307,0,0,0-.058-.069l-.012-.012c-.012-.012-.035-.023-.058-.046s-.012-.012-.023-.012a.253.253,0,0,0-.058-.035c-.012-.012-.012-.012-.023-.012a.245.245,0,0,0-.081-.035L9.93,24.993a.777.777,0,0,0-1,.393.7.7,0,0,0,.417.926l2.6.984a5.479,5.479,0,0,1-4.664.1H7.3a4.953,4.953,0,0,1-3.055-4.641A.739.739,0,0,0,3.5,22.03a.74.74,0,0,0-.775.706,6.376,6.376,0,0,0,3.935,5.971,6.982,6.982,0,0,0,5.3.174l-.694,1.585a.692.692,0,0,0,.417.926.841.841,0,0,0,.289.046.754.754,0,0,0,.706-.44l1.574-3.611c0-.012.012-.012.012-.023s-.012-.012,0-.023a.126.126,0,0,0,.012-.058c0-.012,0-.012.012-.023a.2.2,0,0,0,.012-.081v-.093a.5.5,0,0,0-.012-.058l.012-.023"
      transform="translate(0.427 3.46)"
      fill={fill}
    />
    <path
      d="M19.462,4.455h0c.012.035.012.069.023.081v.012c.012.023.012.046.035.069-.012.012,0,.012.012.023s.023.035.035.046.012.012.012.023l.046.069a.307.307,0,0,0,.058.069l.012.012c.012.012.035.023.058.046s.012.012.023.012a.253.253,0,0,0,.058.035c.012.012.012.012.023.012s.058.023.081.035l3.888,1.47a.777.777,0,0,0,1-.393.7.7,0,0,0-.417-.926l-2.627-.984a5.479,5.479,0,0,1,4.664-.1h.012A4.942,4.942,0,0,1,29.495,8.7a.739.739,0,0,0,.752.729h.012a.753.753,0,0,0,.764-.706,6.369,6.369,0,0,0-3.935-5.983,7.015,7.015,0,0,0-5.3-.174L22.482.983a.692.692,0,0,0-.417-.926.777.777,0,0,0-1,.393L19.508,4.061c0,.012-.012.012-.012.023a.04.04,0,0,1-.012.023.126.126,0,0,0-.012.058c0,.012,0,.012-.012.023a.2.2,0,0,0-.012.081v.1c0,.023.012.046.012.069Z"
      transform="translate(3.058 -0.003)"
      fill={fill}
    />
    <path
      d="M5.014,14.593a.65.65,0,1,0,0,1.3h5.867a.65.65,0,1,0,0-1.3H8.636V14h2.245a.65.65,0,1,0,0-1.3H8.636V11.4H9.794l.22.417a.674.674,0,0,0,.613.359.764.764,0,0,0,.312-.069.64.64,0,0,0,.312-.868l-.926-1.724a.724.724,0,0,0-.926-.3.635.635,0,0,0-.312.879L9.1,10.1H6.391l1.042-1.6h3.6a.65.65,0,1,0,0-1.3H5.246a.65.65,0,1,0,0,1.3h.579l-1.25,1.909a.617.617,0,0,0-.012.66.694.694,0,0,0,.6.336H7.248v1.308H5.014a.65.65,0,1,0,0,1.3H7.259v.59H5.014Z"
      transform="translate(0.679 1.13)"
      fill={fill}
    />
    <path
      d="M11.614,14.405a.673.673,0,0,0,.694-.648V8.538a.7.7,0,0,0-1.389,0v5.219a.673.673,0,0,0,.694.648"
      transform="translate(1.717 1.237)"
      fill={fill}
    />
    <path
      d="M11.056,17.7h.081A3.274,3.274,0,0,0,14.5,14.531V7.888a.7.7,0,0,0-1.389,0v6.643a1.939,1.939,0,0,1-1.99,1.875h-.081a.673.673,0,0,0-.694.648.683.683,0,0,0,.706.648"
      transform="translate(1.627 1.135)"
      fill={fill}
    />
  </svg>
);

export default SVG;
