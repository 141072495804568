import React from 'react';

const SVG = ({
  style = {},
  width = '111.66',
  height = '70.27',
  className = '',
  viewBox = '0 0 112.45 70.27'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M74.507,78.216a3.8,3.8,0,0,0-3.155-1.427c-1.476,0-2.953.662-2.953,2.316,0,3.614,8.552,1.555,8.552,7.969,0,3.871-3.056,5.752-6.6,5.752a7.464,7.464,0,0,1-5.905-2.444l2.419-2.341a4.253,4.253,0,0,0,3.565,1.882c1.476,0,3.155-.815,3.155-2.469,0-3.945-8.552-1.679-8.552-8.118,0-3.718,3.308-5.446,6.642-5.446a7.391,7.391,0,0,1,5.165,1.857Z"
      transform="translate(-37.797 -43.333)"
      fill="#003a70"
    />
    <path
      d="M96.57,86.6V84h2.523V80.46h3.056V84h3.358v2.6h-3.358v5.347c0,1.22.356,1.985,1.7,1.985a3.208,3.208,0,0,0,1.654-.38v2.572a6.527,6.527,0,0,1-2.419.405c-3.234,0-3.995-1.452-3.995-4.326V86.6Z"
      transform="translate(-56.634 -47.186)"
      fill="#003a70"
    />
    <path
      d="M130.343,99.133h-.079a4.393,4.393,0,0,1-3.92,1.985c-2.266,0-4.454-1.245-4.454-3.664,0-3.97,4.632-4.251,7.688-4.251h.765v-.331c0-1.5-1.17-2.291-2.8-2.291a4.807,4.807,0,0,0-3.259,1.274l-1.6-1.6a7.155,7.155,0,0,1,5.194-1.96c5.219,0,5.219,3.767,5.219,5.5v7.026h-2.75V99.133Zm-.178-3.792h-.637c-1.679,0-4.582.128-4.582,1.882,0,1.121,1.146,1.6,2.138,1.6,2.088,0,3.081-1.1,3.081-2.8Z"
      transform="translate(-71.483 -51.778)"
      fill="#003a70"
    />
    <path
      d="M155.48,88.586h3.056v1.935h.054a3.894,3.894,0,0,1,4.706-2.059v2.953a6.071,6.071,0,0,0-1.476-.227,3.074,3.074,0,0,0-3.284,3.081v6.542H155.48Z"
      transform="translate(-91.182 -51.772)"
      fill="#003a70"
    />
    <path
      d="M178.64,72.04H181.7V84.132l4.71-5.066h3.92l-5.244,5.55,5.575,6.67h-4.044L181.75,85.05h-.05v6.236h-3.056V72.04Z"
      transform="translate(-104.765 -42.248)"
      fill="#003a70"
    />
    <path
      d="M209.752,95.765a3.167,3.167,0,0,0,3.284,2.9,4.019,4.019,0,0,0,3.288-1.654l2.188,1.654a6.535,6.535,0,1,1-5.165-10.388c3.408,0,5.723,2.394,5.723,6.646v.839Zm6.265-2.291a2.813,2.813,0,0,0-3.106-2.9,3.036,3.036,0,0,0-3.155,2.9Z"
      transform="translate(-121.221 -51.772)"
      fill="#003a70"
    />
    <path
      d="M237.75,89.02h3.358L244.52,97.5h.05l3.056-8.478h3.18L245.028,103.4c-.893,2.291-1.96,3.486-4.657,3.486a8.936,8.936,0,0,1-2.341-.306l.38-2.746a4.559,4.559,0,0,0,1.551.306c1.5,0,1.911-.079,2.419-1.352l.587-1.452Z"
      transform="translate(-139.43 -52.206)"
      fill="#003a70"
    />
    <g>
      <g>
        <path
          id="Path_2426"
          data-name="Path 2426"
          d="M64.1,65.129S37.653,50.659,7.593,63L22.3,37.893,7.6,24.833h21L39.3,7.034l3.747,17.8h68.607v-.372L45.8,21.488,40.54,0,26.43,21.628H0l17.662,17.05S.07,70.257,0,70.273c30.565-18.415,64.1-4.789,64.1-4.789Z"
          fill="#eba900"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
