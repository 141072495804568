import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const DefaultImage = styled.img`
  width: 100%;
  height: auto;
  padding: 3rem 0;
`;
