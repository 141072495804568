import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  position: relative;
  z-index: 2;
  font-size: 3.75rem;
  font-weight: 300;
  color: ${c.bluePrimary};
  margin: 2rem 0 .6rem;
`;

export const Copy = styled.div`
position: relative;
  z-index: 2;
  font-size: 1.5625rem;
  width: 43.5rem;
  text-align: center;
  margin-bottom: 31rem;
`;

export const Image = styled.img`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
  }

  &::before {
    left: 0;
    background-color: #fff;
  }

  &::after {
    right: 0;
    background-color: ${c.blueLight};
  }
`;