import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { EarBrainAnatomy } from './children/EarBrainAnatomy';
// Style
import { Title } from './HowWeHear.styles';

const HowWeHear = () => {

  return(
    <Page>
      <Progress visible goTo={'/your-story'} navDirection={'prev'} text={'Back'} />
      <Title>How we hear</Title>
      <EarBrainAnatomy />
      <Progress visible goTo={'/understanding-sound'} navDirection={'next'} text={'Next'} />
    </Page>
  );
};

export default HowWeHear;
