import React from 'react';
// Style
import {
  Circle,
  Container,
  Content,
  Flipper,
  Side1,
  Side2
} from './FlipCoin.styles';

const FlipCoin = ({
  chosen,
  flipped,
  onClick,
  side1,
  side2
}) => {

  return(
    <Container flipped={flipped} onClick={onClick}>
      <Flipper flipped={chosen}>
        <Side1 activeSide={!chosen}>
          <Circle gray>
            <Content>
             {side1}
            </Content>
          </Circle>
        </Side1>
        <Side2 activeSide={chosen}>
          <Circle blue>
            <Content>
             {side2}
            </Content>
          </Circle>
        </Side2>
      </Flipper>
    </Container>
  );
};

export default FlipCoin;
