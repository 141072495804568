export const FAKE_DATA = {
  "cardsData": [
    {
      "CategoryID": "91ccb0e2-1182-4f2b-ad4d-a03bcf5b42aa",
      "Card1": "17bd92bf-662c-452a-8b3c-9a037e17de42",
      "Card2": "d47ae53e-29ff-46d4-8808-ff56596ca523",
      "Card3": "1c6d4289-68a6-441d-9800-44b77b1beb1d"
    },
    {
      "CategoryID": "e117f16b-6900-46e4-a6b6-b4b6066ff98f",
      "Card1": "d81b590e-98aa-4b25-91a8-781690e04afe",
      "Card2": "e4909d95-6c19-4e82-8965-09e249d35753",
      "Card3": "259ac416-4210-4ec1-be6d-75396747db8d"
    },
    {
      "CategoryID": "27682d64-b57b-4cdd-ba4e-f671388e84a1",
      "Card1": "33039eac-d303-4b47-800f-a9fdc6079e8a",
      "Card2": "7b8f1184-2124-4175-91de-ef11e2cc16a0",
      "Card3": "5406504d-95f2-4239-961a-1b83a5f2249e"
    },
    {
      "CategoryID": "96e754e8-443d-42ae-bec6-6647a4ac901b",
      "Card1": "4eb2728b-6ee1-4567-97b4-9f51f11ecd12",
      "Card2": "c76cd9b1-25c6-4fce-a196-d4171974020e",
      "Card3": "79c34226-8d9f-4cb4-823d-79af6512adf8"
    },
    {
      "CategoryID": "083a986f-d1ce-459d-9ace-b1b58d19ae4a",
      "Card1": "9e026bd1-5eac-4e92-8464-afb99f2b0253",
      "Card2": "a2f43fc1-88fd-4d2a-bf3b-e78a74ca825b",
      "Card3": "1c45f251-8b3b-4c9f-970a-46f267a8b75b"
    }
  ]
}