import React from 'react';

const SVG = ({
  style = {},
  fill = '#506162',
  width = '58.40705',
  height = '101.70642',
  className = '',
  viewBox = '0 0 58.40705 101.70642'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path
    d="M57.68247,29.9285l-.001-.00336-.00006-.00061Zm-4.101,6.126-19.13,3.8c11.559,28.36261,10.09229,28.20612,4.665,56.42145-1.147,7.60028-13.04108,5.608-11.67462-1.93744-.01508-1.956,5.13574-23.68555,3.44464-24.979L11.53574,97.61905c-4.16705,6.42889-14.17029-.24011-9.85022-6.55853l21.527-31.583-6.96777-18.6847a7.5249,7.5249,0,0,1,1.79882-8.07873c-4.19091-.217-8.21606-3.01575-8.13806-8.41278-.16565-11.24854,17.5022-11.24841,17.338-.00079a8.1052,8.1052,0,0,1-1.72021,5.33508,7.77266,7.77266,0,0,1,2.91821.778l21.17694-4.202-34.152-21.6c-4.41309,7.79865-16.51111,3.144-6.73731-3.5683C12.2786-3.08566,49.013,24.691,52.68247,25.72854a5.21335,5.21335,0,0,1,.899,10.326Zm-40.165-31.907c.46515-1.82526-2.742-1.874-3.87732-1.22766-6.26849,4.49719,1.32629,4.871,3.8783,1.22864Zm5.157,26.583a6.4298,6.4298,0,0,0,6.42993-6.42944c.12238-8.34278-12.981-8.344-12.85992-.00061h.001A6.437,6.437,0,0,0,18.57346,30.73056Zm36.6261,1.97394a3.19,3.19,0,0,0-3.252-4.872l-23.785,4.718c-4.86541-3.00165-12.121,2.204-10.00006,7.525l7.337,19.676-22.116,32.447a3.873,3.873,0,0,0,6.458,4.276l20.941-30.584a7.57013,7.57013,0,0,1,2.554,6.86L29.45255,94.67917c-.88159,4.95013,6.91809,6.202,7.64392,1.24835l4.25659-23.74011c1.89051-6.127-7.13488-26.56085-9.65759-33.8739,1.14935-.575,23.75513-3.789,23.506-5.608Z"
    strokeWidth="1.25"
    stroke={fill}
    fill={fill}
  />
  </svg>
);

export default SVG;
