import React from 'react';

// Components
import Button from '../Button/Button';

// Style
import { SubTitle } from './AssessmentError.styles';

const AssessmentError = () => (
  <>
    <SubTitle>
        Please return to the beginning <br />
        to take the assessment in full.
    </SubTitle>
    <Button href={'/lifestyle-assessment'} text="Return to Assessment"></Button>
  </>
);

export default AssessmentError;
