import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  -webkit-overflow-scrolling: touch;
  transition: top 1s ease-in-out;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.page-enter {
    top: 100%;
  }

  &.page-enter-active {
    top: 0;
  }

  &.page-exit {
    top: -100%;
  }
`;