import React, { useEffect,useState } from 'react'
import { useLocation,NavLink } from 'react-router-dom';
// Hooks
import { useMenuDot } from '../../hooks/useMenuDot';
// Data
import { navData } from '../../data/showNavData';
// Style
import {
  Container,
  HamburgerMenuButton,
  HamburgerBox,
  HamburgerInner,
  NavigationWrap,
  NavigationSection,
  NavBackground,
  NavigationUl,
  NavigationHeading,
  ResetLink,
  SignOutLink
} from './Navigation.styles'

const Navigation = () => {
  const [show,setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const {pathname} = useLocation();
  const activeDot = useMenuDot();

  const toggleClass = () => setExpanded(!expanded);

  useEffect(() => {
    navData.includes(pathname) ? setShow(true) : setShow(false);
  },[pathname]);

  return(
    <Container show={show}>
      <NavBackground className={`${expanded ? 'expanded' : ''}`} />
      <HamburgerMenuButton
          onClick={toggleClass}
          className={`navbar-toggler hamburger ${expanded ? 'expanded' : ''}`}
          type="button"
          aria-expanded={expanded}
          aria-label="Navigation trigger"
        >
        <HamburgerBox>
          <HamburgerInner className={`${expanded ? 'expanded' : ''}`}/>
        </HamburgerBox>
      </HamburgerMenuButton>
      <NavigationWrap className={`navigation-wrap ${expanded ? 'expanded' : ''}`}>
          <NavigationSection>
            <NavigationHeading>
                About You
            </NavigationHeading>
            <nav role="navigation">
              <NavigationUl>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'todays-visit' ? 'isActive' : ''}`}
                    to={'/todays-visit'}
                  >
                    Today's Visit
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'lifestyle-assessment' ? 'isActive' : ''}`}
                    to={'/self-descriptor'}
                  >
                    Lifestyle Assessment
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'lifestyle-assessment-results' ? 'isActive' : ''}`}
                    to={'/lifestyle-assessment-results'}
                  >
                    Lifestyle Assessment Results
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'how-we-hear' ? 'isActive' : ''}`}
                    to={'/how-we-hear'}
                  >
                    How We Hear
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'understanding-sound' ? 'isActive' : ''}`}
                    to={'/understanding-sound'}
                  >
                    Understanding Sound
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'hearing-evaluation' ? 'isActive' : ''}`}
                    to={'/hearing-evaluation'}
                  >
                    Hearing Evaluation
                  </NavLink>
                </li>
              </NavigationUl>
            </nav>
          </NavigationSection>
          <NavigationSection>
            <NavigationHeading>
              Your Solution
            </NavigationHeading>
            <nav role="navigation">
              <NavigationUl>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'hearing-results' ? 'isActive' : ''}`}
                    to={'/hearing-loss'}
                  >
                    Hearing Results
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'audiogram' ? 'isActive' : ''}`}
                    to={'/audiogram'}
                  >
                    Audiogram
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'about-your-hearing' ? 'isActive' : ''}`}
                    to={'/types-of-hearing-loss'}
                  >
                    About Your Hearing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'impacts-of-hearing-loss' ? 'isActive' : ''}`}
                    to={'/impacts-of-hearing-loss'}
                  >
                    Impacts of a Hearing Loss
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'your-best-fit' ? 'isActive' : ''}`}
                    to={'/technology-levels'}
                  >
                    Your Best Fit
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'every-ear-unique' ? 'isActive' : ''}`}
                    to={'/hearing-aid-transition'}
                  >
                    Every Ear Is Unique
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'hearing-aid-styles' ? 'isActive' : ''}`}
                    to={'/hearing-aid-styles'}
                  >
                    Hearing Aid Styles
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'accessories' ? 'isActive' : ''}`}
                    to={'/accessories'}
                  >
                    Accessories
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`navigation-link ${activeDot === 'appointment-summary' ? 'isActive' : ''}`}
                    to={{
                      pathname: '/appointment-summary',
                      state: { active: 0 }
                    }}
                  >
                    Appointment Summary
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="navigation-link"
                    to={{
                      pathname: '/appointment-summary',
                      state: { active: 1 }
                    }}
                  >
                    Care Experience
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="navigation-link"
                    to={{
                      pathname: '/appointment-summary',
                      state: { active: 2 }
                    }}
                  >
                    Mobile Apps
                  </NavLink>
                </li>
              </NavigationUl>
            </nav>
          </NavigationSection>
          <NavigationSection>
            <NavigationUl>
              <li>
                <ResetLink className="btn-outline" href="/" target="_self">
                  Reset Assessment
                </ResetLink>
              </li>
              <li>
                <SignOutLink className="btn-outline" href="/logout" target="_self">
                  Sign Out
                </SignOutLink>
              </li>
            </NavigationUl>
          </NavigationSection>
      </NavigationWrap>
    </Container>
  );
}

export default Navigation