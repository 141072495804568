import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '22.84',
  height = '34.75',
  className = '',
  viewBox = '0 0 22.84 34.75'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M478.65,254.5a.67.67,0,0,0,.48-.2.67.67,0,0,0,.19-.47.68.68,0,0,0-.19-.47.66.66,0,0,0-.95,0,.68.68,0,0,0-.19.47.67.67,0,0,0,.66.67Z"
        transform="translate(-469.09 -253.16)"
        fill={fill}
      />
      <path
        d="M478.65,258.22a.67.67,0,0,0,.67-.67v-1.47a.67.67,0,1,0-1.33,0v1.47A.67.67,0,0,0,478.65,258.22Z"
        transform="translate(-469.09 -253.16)"
        fill={fill}
      />
      <path
        d="M482.18,261.75a.67.67,0,0,0,.67.66h3.72a.67.67,0,0,0,0-1.33h-3.72A.67.67,0,0,0,482.18,261.75Z"
        transform="translate(-469.09 -253.16)"
        fill={fill}
      />
      <path
        d="M470.74,262.41h3.72a.67.67,0,1,0,0-1.33h-3.72a.67.67,0,1,0,0,1.33Z"
        transform="translate(-469.09 -253.16)"
        fill={fill}
      />
      <path
        d="M474.77,258.81a.69.69,0,0,0,1,0,.68.68,0,0,0,0-.95L474,256.11a.66.66,0,1,0-.94.94Z"
        transform="translate(-469.09 -253.16)"
        fill={fill}
      />
      <path
        d="M482.06,259a.67.67,0,0,0,.47-.19l1.76-1.76a.66.66,0,0,0-.94-.94l-1.76,1.75a.66.66,0,0,0,0,.95A.67.67,0,0,0,482.06,259Z"
        transform="translate(-469.09 -253.16)"
        fill={fill}
      />
      <path
        d="M489.48,267.47a2.39,2.39,0,0,0-1.3.37,11.84,11.84,0,0,1-5.87-2.77,2.42,2.42,0,0,0-1.13.28v-3.8a2.46,2.46,0,0,0-4.91,0v13.87l-3.2-1.59a3.53,3.53,0,0,0-1.63-.39,2.35,2.35,0,0,0-1.66,4l7.73,7.72a9.26,9.26,0,0,0,6.59,2.73,7.84,7.84,0,0,0,7.83-7.83V269.92A2.45,2.45,0,0,0,489.48,267.47Zm-11,16.78-7.73-7.73a1,1,0,0,1-.3-.73,1,1,0,0,1,1-1,2.33,2.33,0,0,1,1,.24l4.15,2.08a.78.78,0,0,0,.3.07.74.74,0,0,0,.35-.1.68.68,0,0,0,.31-.57V261.55a1.13,1.13,0,0,1,2.26,0v11.36a.67.67,0,0,0,1.33,0v-5.38a1.13,1.13,0,0,1,2.26,0v5.38a.66.66,0,0,0,1.32,0v-4.19a1.13,1.13,0,1,1,2.26,0v4.19a.67.67,0,0,0,1.33,0v-3a1.13,1.13,0,0,1,2.26,0v10.16a6.52,6.52,0,0,1-6.51,6.51A7.93,7.93,0,0,1,478.45,284.25Z"
        transform="translate(-469.09 -253.16)"
        fill={fill}
      />
    </g>
  </svg>
);

export default SVG;
