import styled from 'styled-components';

export const Container = styled.div`
  width: 100%; 
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GramToggles = styled.div`
  position: absolute;
  bottom: 1.6rem;
  left: 4.5rem;
`;

export const ToggleWrapper = styled.div`
  padding: .3rem;
  display: flex;

  div {
    display: flex;
    align-items: center;
    font-family: 'DIN Next';
    font-size: 1rem;
    padding-left: .5rem;
  }
`;

export const SpectrumToggleWrapper = styled(ToggleWrapper)`

`;

export const SoundsToggleWrapper = styled(ToggleWrapper)`

`;

export const PaneToggleWrapper = styled.div`
  width: 18.75rem;
`;