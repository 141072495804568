import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '98.7',
  height = '73.75',
  className = '',
  viewBox = '0 0 98.7 73.75'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-188.895 -517.41)">
      <g transform="translate(164 488)">
        <g>
          <g transform="translate(37.788 50.044)">
            <path
              d="M14.166,11.279,12.408,8.367c.91-.548,3.372-2.994,3.847-7.651.815-7.992-4.684-25.379-9.542-33.023C1.72-40.167-4.432-37.995-5.113-37.719c-2.139.856-12.8,6.37-18.136,9.168l-.317.165-.357.024s-8.478.578-13.813,1.026c-2.853.236-4.069,1.818-4.007,3.278.071,1.73,1.865,3.591,5.63,3.591a49.954,49.954,0,0,0,5.506-.262l4.173-.517-2.638,3.273S-41.055-3.116-46.884,4.145c-2.536,3.156-1.885,4.59-1.879,4.6l-2.832,1.875c-.235-.327-2.166-3.347,2.058-8.61,4.194-5.225,11.573-14.38,15.385-19.107-.6.017-1.252.028-1.96.028-6.009,0-8.892-3.552-9.029-6.852-.125-2.983,2.053-6.389,7.122-6.811,4.7-.392,11.842-.887,13.51-1,2.284-1.2,15.489-8.091,18.133-9.147C-2.918-42.258,4.334-42.4,9.584-34.132,14.208-26.854,20.611-8.463,19.639,1.06c-.594,5.817-3.718,9.16-5.472,10.219"
              transform="translate(52.238 41.591)"
              fill={fill}
            />
          </g>
        </g>
        <g transform="translate(53.27 82.561)">
          <path
            d="M0,0-17.753-14.656-29.036-.456-31.13-2.122l12.979-16.334L1.7-2.063Z"
            transform="translate(31.13 18.456)"
            fill={fill}
          />
        </g>
        <g>
          <g transform="translate(69.795 29.41)">
            <path
              d="M2.528,5.057A11.865,11.865,0,0,1-9.323-6.8,11.864,11.864,0,0,1,2.528-18.646,11.865,11.865,0,0,1,14.381-6.8,11.866,11.866,0,0,1,2.528,5.057m0-20.3A8.458,8.458,0,0,0-5.921-6.8a8.459,8.459,0,0,0,8.45,8.451A8.46,8.46,0,0,0,10.979-6.8a8.459,8.459,0,0,0-8.451-8.448"
              transform="translate(9.323 18.646)"
              fill={fill}
            />
          </g>
          <g transform="translate(67.313 65.451)">
            <path
              d="M3.846,4.3-14.182-10.571l10.243-5.275.829,1.336c.234.381,5.792,9.39,6.431,14.568ZM-8.033-9.91l6.264,5.166A72.893,72.893,0,0,0-5.2-11.37Z"
              transform="translate(14.182 15.846)"
              fill={fill}
            />
          </g>
          <g transform="translate(24.895 99.757)">
            <path
              d="M20.692.726H-74.6a1.7,1.7,0,0,1-1.7-1.7,1.7,1.7,0,0,1,1.7-1.7H20.692a1.7,1.7,0,0,1,1.7,1.7,1.7,1.7,0,0,1-1.7,1.7"
              transform="translate(76.303 2.676)"
              fill={fill}
            />
          </g>
        </g>
        <g transform="translate(59.556 69.374)">
          <path
            d="M0,0-.453-2.638,9.678-4.374l.453,2.638Z"
            transform="translate(0.453 4.374)"
            fill={fill}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
