import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '85.29418',
  height = '63.77388',
  className = '',
  viewBox = '0 0 85.29418 63.77388'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M37.55657,46.9228c-1.4249.06468-6.75971,3.445-7.46266.86736-.993-2.48894,5.41875-2.98613,6.5818-3.77531-.44525-5.82013,7.75575-8.48074,10.88086-3.59905L66.166,34.2608a1.5195,1.5195,0,0,1,.95508,2.885l-18.55858,6.137c.82529,6.115-7.99355,8.97348-11.00586,3.642Zm5.07813-.181c3.81615.05792,3.819-5.93574.00364-5.88193C38.82081,40.80237,38.82046,46.79193,42.6347,46.7418Zm31.82422-2.472a1.51956,1.51956,0,0,1-.01546-3.03795c.01072-.0002,7.221.00011,7.23424,0a38.9296,38.9296,0,0,0-10.373-25c-1.29558.64664-5.41063,7.24261-7.24177,5.03248-2.03957-1.80349,4.46719-5.93554,5.09528-7.18446a38.95021,38.95021,0,0,0-25-10.372c-.45809,1.38531,1.28,8.92844-1.521,8.73843-2.79727.211-1.07042-7.38045-1.522-8.73843a38.93147,38.93147,0,0,0-25,10.372c.65048,1.29927,7.22782,5.40733,5.0569,7.2447-1.8147,2.0789-5.944-4.45859-7.20534-5.09272a38.89529,38.89529,0,0,0-10.375,25h7.2168a1.51944,1.51944,0,0,1-.00012,3.03794c-1.24286-.477-10.64255,1.30742-10.29278-1.51a42.122,42.122,0,0,1,84.24407-.00691h0a1.51707,1.51707,0,0,1-1.51716,1.517h-.00237Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export default SVG;
