import React from 'react';

const SVG = ({
  style = {},
  width = '216.3',
  height = '34.8',
  className = '',
  viewBox = '0 0 216.3 34.8'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      className="st0"
      fill="#ce3338"
      d="M38.3,34.7c-1.6,0-3.1-0.7-4.3-1.8L7.6,10.5v24.2l-7.6,0V4.4C0,1.4,1.2,0,3.6,0c1.8,0,3.4,0.7,4.7,2l26.1,22.2
	V0H42v30.2C42,33.2,40.7,34.7,38.3,34.7"
    />
    <path
      className="st0"
      fill="#ce3338"
      d="M76,4.8v20.7c0,0.9-0.6,1.7-1.4,2c-1.6,0.6-3.3,0.9-4.9,0.8l-8.5,0c-1.7,0.1-3.4-0.2-5.1-0.8
	c-0.9-0.3-1.4-1.1-1.4-1.9V4.8h-7.5v21c-0.2,3.4,1.9,6.6,5.1,7.7c2.1,0.8,5.3,1.2,9.5,1.2h7c3.2,0.1,6.4-0.3,9.4-1.3
	c3.2-1.1,5.4-4.2,5.2-7.6v-21H76z"
    />
    <path
      className="st1"
      fill="#231f20"
      d="M104.2,34.7c-3.1,0.1-6.3-0.5-9.2-1.8c-3.3-1.6-4.9-4-4.9-7.1V9.6c0-3.2,1.7-5.7,5.1-7.3c3-1.3,6.2-1.9,9.5-1.9
	h23.3v6.5H105c-5,0-7.5,0.9-7.5,2.6v4.9h30.4V21H97.6v4.6c0,1.8,2.3,2.6,6.8,2.6h23.5v6.5H104.2z"
    />
    <path
      className="st1"
      fill="#231f20"
      d="M158.2,6.9c-0.9-1.4-2.4-2.2-4-2.2c-1.6,0-3.1,0.8-4,2.2l-19.6,27.8h8.8l5.1-7.5h19.1l5.3,7.5h8.5L158.2,6.9z
	 M159,20.7h-9.9l4.9-7.1L159,20.7z"
    />
    <path
      className="st1"
      fill="#231f20"
      d="M211.7,22.7c2.7-1,4.4-3.6,4.4-6.4v-3.3c0-3.1-1.7-5.4-4.8-6.7c-2.7-1-5.5-1.5-8.4-1.4h-23.1v30h7.4v-9.3
	l16.8,9.3h11.7l0.3-1l-17.4-9.6h5.3C206.5,24.2,209.2,23.7,211.7,22.7 M207.9,17.2c-1,0.3-2.1,0.5-3.1,0.4h-17.5v-6.3h16.4
	c1.3-0.1,2.7,0.1,4,0.5c0.8,0.3,1,0.6,1,1v3.5C208.6,16.7,208.5,16.9,207.9,17.2"
    />
  </svg>
);

export default SVG;
