import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  display: inline-block;
  position: relative;
  height: 1.8rem;
  width: 1.8rem;
  color: ${c.bluePrimary};
  border: .16rem solid ${c.bluePrimary};
  border-radius: 50%;

  > svg {
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    top: 30%;
    left: 65%;
    transform: translate(-50%, -50%);
  }
`;