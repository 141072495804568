import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '82.61',
  height = '83.82',
  className = '',
  viewBox = '0 0 82.61 83.82'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-482.72 -257.855)">
      <path
        d="M1.6,3.2A8.025,8.025,0,0,1-6.413-4.813,8.024,8.024,0,0,1,1.6-12.828,8.025,8.025,0,0,1,9.617-4.813,8.026,8.026,0,0,1,1.6,3.2m0-13.087a5.076,5.076,0,0,0-5.07,5.071A5.077,5.077,0,0,0,1.6.259a5.077,5.077,0,0,0,5.07-5.071A5.076,5.076,0,0,0,1.6-9.884"
        transform="translate(521.851 270.683)"
        fill={fill}
      />
      <path
        d="M1.6,3.2A8.026,8.026,0,0,1-6.414-4.813,8.025,8.025,0,0,1,1.6-12.828,8.025,8.025,0,0,1,9.618-4.813,8.026,8.026,0,0,1,1.6,3.2m0-13.087a5.076,5.076,0,0,0-5.07,5.071A5.077,5.077,0,0,0,1.6.259,5.078,5.078,0,0,0,6.674-4.813,5.077,5.077,0,0,0,1.6-9.884"
        transform="translate(489.134 270.683)"
        fill={fill}
      />
      <path
        d="M71-56.82H41.989v-2.008H71Z"
        transform="translate(466.961 359.693)"
        fill={fill}
      />
      <path
        d="M6.168,13.153A4.63,4.63,0,0,1,1.5,9.607C.9,6.622-1.423-7.741-2.129-12.111H-16.688A8.025,8.025,0,0,1-24.7-20.125V-46.3a6.387,6.387,0,0,1,6.38-6.38h1.8a7.813,7.813,0,0,1,6.168,2.993l5.931,8.458H7.849a4.748,4.748,0,0,1,3.618,1.672A4.759,4.759,0,0,1,12.53-35.7a4.879,4.879,0,0,1-4.855,3.958H-6.759a4.735,4.735,0,0,1-1.912-.4v9.129H-.965c3.923,0,7.254,4.2,7.908,8.115,0,0,3.35,17.835,3.989,21.2.765,4.014-1.325,6.283-3.733,6.753a5.394,5.394,0,0,1-1.031.1M-18.323-49.734A3.44,3.44,0,0,0-21.759-46.3v26.173a5.077,5.077,0,0,0,5.071,5.07H-.875A1.471,1.471,0,0,1,.578-13.817c.032.194,3.13,19.445,3.807,22.848a1.872,1.872,0,0,0,2.253,1.131c1.243-.242,1.756-1.45,1.4-3.313-.642-3.368-4-21.236-4-21.236C3.6-17.032,1.322-20.071-.965-20.071h-9.178a1.473,1.473,0,0,1-1.473-1.472v-13.6l-4.371-6.237a1.475,1.475,0,0,1,.36-2.052,1.474,1.474,0,0,1,2.051.361l4.6,6.566a1.153,1.153,0,0,1,.071.1l.671.955a1.8,1.8,0,0,0,1.473.766H7.675a1.942,1.942,0,0,0,1.95-1.486,1.8,1.8,0,0,0-.4-1.477,1.8,1.8,0,0,0-1.373-.635H-5.188a1.473,1.473,0,0,1-1.206-.627l-6.322-9.016a4.853,4.853,0,0,0-3.807-1.807Z"
        transform="translate(507.424 328.527)"
        fill={fill}
      />
      <path
        d="M1.6,3.2A8.025,8.025,0,0,1-6.413-4.813,8.024,8.024,0,0,1,1.6-12.828,8.024,8.024,0,0,1,9.615-4.813,8.025,8.025,0,0,1,1.6,3.2m0-13.087a5.076,5.076,0,0,0-5.07,5.071A5.077,5.077,0,0,0,1.6.259a5.077,5.077,0,0,0,5.07-5.071A5.076,5.076,0,0,0,1.6-9.884"
        transform="translate(555.713 270.683)"
        fill={fill}
      />
      <path
        d="M1.284,13.153a5.394,5.394,0,0,1-1.031-.1c-2.409-.47-4.5-2.739-3.733-6.753C-2.841,2.933.513-14.93.513-14.93c.65-3.89,3.984-8.085,7.9-8.085h7.708v-9.129a4.735,4.735,0,0,1-1.912.4H-.225A4.876,4.876,0,0,1-5.078-35.7a4.756,4.756,0,0,1,1.066-3.858A4.737,4.737,0,0,1-.4-41.227H11.874l5.882-8.39a7.844,7.844,0,0,1,6.218-3.06h1.8a6.387,6.387,0,0,1,6.38,6.38v26.173a8.024,8.024,0,0,1-8.014,8.014H9.578c-.7,4.37-3.032,18.733-3.627,21.717a4.626,4.626,0,0,1-4.668,3.547M8.414-20.071c-2.284,0-4.566,3.04-5,5.655,0,.029-3.36,17.9-4,21.266-.354,1.863.157,3.07,1.4,3.313A1.875,1.875,0,0,0,3.065,9.031c.677-3.4,3.774-22.654,3.807-22.848a1.473,1.473,0,0,1,1.453-1.238H24.138a5.077,5.077,0,0,0,5.07-5.07V-46.3a3.439,3.439,0,0,0-3.435-3.435h-1.8a4.884,4.884,0,0,0-3.857,1.872L13.846-38.91a1.473,1.473,0,0,1-1.206.627H-.4a1.8,1.8,0,0,0-1.371.635,1.8,1.8,0,0,0-.4,1.477A1.938,1.938,0,0,0-.225-34.685H14.21a1.8,1.8,0,0,0,1.472-.765l5.344-7.624a1.471,1.471,0,0,1,2.052-.361,1.475,1.475,0,0,1,.36,2.052l-4.37,6.233v13.607a1.473,1.473,0,0,1-1.473,1.472Z"
        transform="translate(533.176 328.527)"
        fill={fill}
      />
      <path
        d="M59.835-31.988H57.827V-57.65h2.008Z"
        transform="translate(464.622 367.721)"
        fill={fill}
      />
      <path
        d="M5.818,2.582H2.874V-3.624A1.789,1.789,0,0,0,1.83-5.259,25.583,25.583,0,0,0-8.672-7.4,26.29,26.29,0,0,0-19.3-5.245a1.79,1.79,0,0,0-1.055,1.64V2.582H-23.3V-3.605a4.737,4.737,0,0,1,2.807-4.333,29.29,29.29,0,0,1,11.822-2.4,28.57,28.57,0,0,1,11.711,2.4,4.738,4.738,0,0,1,2.779,4.32Z"
        transform="translate(532.194 286.19)"
        fill={fill}
      />
    </g>
  </svg>
);

export default SVG;
