import styled from 'styled-components';

export const Container = styled.button`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

export const Image = styled.img`
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 52%;
  height: auto;
  transition: width .3s ease,
              bottom .3s ease;

  ${({ zoomed }) => zoomed && `
    width: 116%;
    bottom: -54%;
  `}
`;
