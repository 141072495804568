import React from 'react';
// Components
import { FillDot } from '../../../../components/FillDot';
// Style
import {
  Container,
  FillDotWrapper,
  ItemBody,
  ItemName,
  SelectionName,
  Th
} from './LifestyleNeedsPanel.styles';

const LifestyleNeedsPanel = ({ data }) => {

  return(
    <Container>
      <table>
        <thead>
          <tr>
            <td />
            <td />
            <Th best>
              <span>Best</span>
            </Th>
            <Th better>
              <span>Better</span>
            </Th>
            <Th good>
              <span>Good</span>
            </Th>
          </tr>
        </thead>
        {data.map((item,pIndex) => {
          return(
            <ItemBody key={pIndex}>
              {item.answers.map((answer,index) => {
                return(pIndex < 4 ?
                  <tr key={index}>
                    <ItemName>{index === 0 && item.title}</ItemName>
                    <SelectionName>{answer.text}</SelectionName>
                    <FillDotWrapper>
                      <FillDot percent={answer.features.techLevel[0] === '1' ? 100 : 0}/>
                    </FillDotWrapper>
                    <FillDotWrapper>
                      <FillDot percent={answer.features.techLevel[1] === '1' ? 100 : 0} />
                    </FillDotWrapper>
                    <FillDotWrapper>
                      <FillDot percent={answer.features.techLevel[2] === '1' ? 100 : 0} />
                    </FillDotWrapper>
                  </tr>
                  : null
                );
              })}
            </ItemBody>
          )
        })}
        <ItemBody>
          {data[4].answers.map((answer,index) => {
            return(
              <tr key={index}>
                <ItemName>{index === 0 && data[4].title}</ItemName>
                <SelectionName>{answer.text}</SelectionName>
                <FillDotWrapper>
                  <FillDot percent={100}/>
                </FillDotWrapper>
                <FillDotWrapper>
                  <FillDot percent={60} />
                </FillDotWrapper>
                <FillDotWrapper>
                  <FillDot percent={40} />
                </FillDotWrapper>
              </tr>
            );
          })}
        </ItemBody>
      </table>
    </Container>
  );
};

export default LifestyleNeedsPanel;
