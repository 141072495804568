import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '72.25',
  height = '76.25',
  className = '',
  viewBox = '0 0 73 77'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M48.128,27.994A24.1,24.1,0,0,0,28.6,37.978c-2.031-5.552-3.451-9.72-4.141-12.151v-6.66c.242-.668.492-1.319.743-1.965a19.235,19.235,0,0,0,1.767-6.7,10.591,10.591,0,0,0-2.985-7.407,10.083,10.083,0,0,0-14.533,0A10.564,10.564,0,0,0,6.467,10.5,17.8,17.8,0,0,0,8.04,16.762c.317.826.646,1.684.936,2.576V25.88c-.756,2.682-1.82,5.5-2.945,8.489C3.2,41.864,0,50.366,0,60.736c0,6.137,4.163,15.052,10.687,15.052H22.745c1.046,0,5.917-.334,8.744-6.216A24.12,24.12,0,1,0,48.128,27.994ZM12.234,25.132V22.741h8.972v2.391ZM11.786,5.359a7,7,0,0,1,9.865,0A7.358,7.358,0,0,1,23.717,10.5a17.412,17.412,0,0,1-1.543,5.521c-.3.761-.6,1.539-.875,2.321a1.626,1.626,0,0,0-.092.541V19.5H12.234v-.4a1.634,1.634,0,0,0-.075-.484c-.325-1.051-.708-2.049-1.073-3.007a15.1,15.1,0,0,1-1.363-5.1A7.311,7.311,0,0,1,11.786,5.359ZM22.75,72.535H10.687c-3.93,0-7.434-6.6-7.434-11.8,0-9.777,3.09-17.98,5.825-25.216.932-2.466,1.82-4.827,2.545-7.13H21.831c.932,2.989,2.484,7.4,4.616,13.153a24.052,24.052,0,0,0,2.638,25.365C27.159,72.249,23.488,72.535,22.75,72.535ZM48.128,73A20.875,20.875,0,1,1,69,52.124,20.9,20.9,0,0,1,48.128,73Z"
      fill={fill}
    />
    <path
      d="M124.521,86.4a3.165,3.165,0,1,0,3.165,3.165A3.165,3.165,0,0,0,124.521,86.4Zm0,3.253.092-.088A.089.089,0,0,1,124.521,89.653Z"
      transform="translate(-68.009 -48.418)"
      fill={fill}
    />
    <path
      d="M102.985,87.48a3.165,3.165,0,1,0,3.165,3.165A3.172,3.172,0,0,0,102.985,87.48Zm0,3.253.092-.088A.1.1,0,0,1,102.985,90.733Z"
      transform="translate(-55.939 -49.023)"
      fill={fill}
    />
    <path
      d="M115.471,105.3a3.163,3.163,0,1,0,3.161,3.165A3.165,3.165,0,0,0,115.471,105.3Zm0,3.253.092-.088A.1.1,0,0,1,115.471,108.553Z"
      transform="translate(-62.938 -59.01)"
      fill={fill}
    />
  </svg>
);

export default SVG;
