import React, { useContext,useEffect } from 'react';
// Components
import { Spinner } from '../Spinner';
// Auth
import { useMsal } from "@azure/msal-react";
// Context
import configContext from '../../contexts/config/configStore';
// Style
import { Container } from './Loading.styles';

const Loading = () => {
  const { configDispatch,configState } = useContext(configContext);
  const { inProgress } = useMsal();

  useEffect(() => {
    if(inProgress !== 'none') {
      configDispatch({ type: 'SET_LOADING', payload: true });
    } else {
      configDispatch({ type: 'SET_LOADING', payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inProgress]);

  if(configState.loading) {

    return(
      <Container>
        <Spinner />
      </Container>
    );
  } else {

    return null
  }
};

export default Loading;
