import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '60.71',
  height = '78.99',
  className = '',
  viewBox = '0 0 60.71 78.99'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-909.822 -236.411)">
      <g>
        <path
          d="M2.209,4.433A11.088,11.088,0,0,1-8.868-6.643,11.1,11.1,0,0,1-3.253-16.28a11.049,11.049,0,0,1,5.592-1.514A11.088,11.088,0,0,1,13.415-6.718,11.1,11.1,0,0,1,7.8,2.919,11.021,11.021,0,0,1,2.209,4.433m.13-19a7.839,7.839,0,0,0-3.973,1.079,7.878,7.878,0,0,0-4,6.84A7.854,7.854,0,0,0,2.209,1.2,7.822,7.822,0,0,0,6.179.122a7.875,7.875,0,0,0,4-6.84,7.853,7.853,0,0,0-7.844-7.844"
          transform="translate(947.784 254.205)"
          fill={fill}
        />
        <path
          d="M.536,7.8A1.614,1.614,0,0,1-1.077,6.242L-2.148-23.1a7.713,7.713,0,0,1,2.207-5.67A8.284,8.284,0,0,1,5.991-31.3a8.152,8.152,0,0,1,8.143,8.143,1.617,1.617,0,0,1-1.616,1.616A1.616,1.616,0,0,1,10.9-23.162a4.917,4.917,0,0,0-4.912-4.912,5.04,5.04,0,0,0-3.6,1.543,4.523,4.523,0,0,0-1.308,3.31L2.15,6.123A1.614,1.614,0,0,1,.6,7.8H.536"
          transform="translate(956.395 307.603)"
          fill={fill}
        />
        <path
          d="M4.1,5.076A33.371,33.371,0,0,1-15.869-2.282a1.615,1.615,0,0,1-.249-2.271A1.619,1.619,0,0,1-13.845-4.8C-6.633.994,3.5,2.988,8.442,1.215c1.445-.52,2.282-1.293,2.491-2.3a3.732,3.732,0,0,0-.056-2.6,2.837,2.837,0,0,0-1.835-.806H8.816c-6.665,0-18.834-6.121-21.3-13.783A1.615,1.615,0,0,1-11.442-20.3a1.617,1.617,0,0,1,2.032,1.042C-7.442-13.151,3.788-7.521,9.07-7.717a1.745,1.745,0,0,1,.27.012,5.758,5.758,0,0,1,4.17,2.155c1.125,1.584.928,3.479.587,5.124-.311,1.5-1.355,3.53-4.561,4.682A16.308,16.308,0,0,1,4.1,5.076"
          transform="translate(953.701 272.658)"
          fill={fill}
        />
        <path
          d="M1.657,14.962a10.184,10.184,0,0,1-5.094-1.3A6.1,6.1,0,0,1-6.651,8.2l.3-18.539c.33-21.547.568-37.116,9.027-44.668,4.747-4.241,11.679-5.755,21.841-4.759A1.614,1.614,0,0,1,25.971-58a1.593,1.593,0,0,1-1.765,1.45c-9.185-.9-15.345.355-19.373,3.952C-2.568-45.989-2.8-31.014-3.117-10.29l-.3,18.533A2.912,2.912,0,0,0-1.8,10.877a7.5,7.5,0,0,0,6.816.042,2.884,2.884,0,0,0,1.62-2.6l.572-23.006a1.618,1.618,0,0,1,1.454-1.568A1.626,1.626,0,0,1,10.4-15.009L16.089,9.706a2.861,2.861,0,0,0,2.3,1.862,7.524,7.524,0,0,0,6.482-2.1,2.915,2.915,0,0,0,.742-3.009L22.786-7.382c-.267-1.323-.591-2.733-.926-4.193-1.449-6.324-3.093-13.492-1.578-18.666a1.613,1.613,0,0,1,2-1.1,1.615,1.615,0,0,1,1.1,2c-1.278,4.367.265,11.1,1.625,17.038.341,1.485.672,2.924.946,4.271L28.734,5.63a6,6,0,0,1-1.46,6,10.6,10.6,0,0,1-9.567,3.1,6.063,6.063,0,0,1-4.73-4.171L10.118-1.83,9.866,8.359a6.052,6.052,0,0,1-3.244,5.365,10.1,10.1,0,0,1-4.965,1.238"
          transform="translate(916.473 300.207)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
