/**
 * Find an object in arr with key equal to val
 * @param {array} arr The array of objects to search
 * @param {int} val The value to match to key
 * @param {string} key The key whose value to match against val
 * @return {object} foundObject The object whose key matches val
 */
export function searchArrayOfObjects(arr,val,key) {
  for(let i=0; i<arr.length; i++) {
    if(arr[i][key] === val) {
      let foundObject = arr[i];
      return foundObject;
    }
  }
}

/**
 * Create comma-separated list from array
 * @param {array} arr The array of items
 * @return {string} arr The comma-separated list
 */
export const commaSeparatedListUtility = arr => {
  return arr
    .map(item => item)
    .reduce((prev, curr) => prev+', '+curr);
};

export const stepIsComplete = (array) => {
  const num = array.reduce((a,v) => (v === 1 ? a+1 : a), 0);
  return (num >= 3 ? true : false);
}

/**
 * Test if id is valid UUID v4
 * @param {string} id The id to test
 * @return {boolean} isValid True or false
 */
export const isValidUUIDv4 = (id) => {
  const re = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  const isValid = re.test(id);
  return isValid;
}

/**
 * Test if str is valid email address
 * @param {string} str The string to test
 * @return {boolean} isValid True or false
 */
export const isValidEmailAddress = (str) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(str);
  return isValid;
}
