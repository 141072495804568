import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '59.57',
  height = '78.95',
  className = '',
  viewBox = '0 0 59.57 78.95'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(13464.17 -2749.678)">
      <g transform="translate(-13449.613 2749.678)">
        <path
          d="M2.029,4.072A9.523,9.523,0,0,1-7.482-5.441,9.535,9.535,0,0,1-2.66-13.715a9.46,9.46,0,0,1,4.8-1.3A9.523,9.523,0,0,1,11.649-5.5,9.537,9.537,0,0,1,6.825,2.774a9.476,9.476,0,0,1-4.8,1.3m.109-15.849a6.241,6.241,0,0,0-3.175.864A6.3,6.3,0,0,0-4.244-5.441,6.281,6.281,0,0,0,2.029.835,6.257,6.257,0,0,0,5.206-.028,6.308,6.308,0,0,0,8.413-5.5a6.281,6.281,0,0,0-6.275-6.275"
          transform="translate(7.482 15.014)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13464.17 2767.258)">
        <path
          d="M4.194,13.093a6.883,6.883,0,0,1-3.47-.913,5.539,5.539,0,0,1-2.76-4.973l.348-13.35-3.353-.685a1.626,1.626,0,0,1-1.041-.717,1.633,1.633,0,0,1-.208-1.248L-1.825-27.3l-4.018,5.47c-1.815,2.471-4.129,3.4-6.346,2.56a5.042,5.042,0,0,1-2.823-2.667,5.039,5.039,0,0,1-.121-3.886A1.593,1.593,0,0,1-15-26.092L-4.474-43.319c1.55-3.652,7.878-4.96,13.342-4.96h.006c5.6,0,12.08,1.331,13.623,5.059l2.253,4.059a1.62,1.62,0,0,1-.632,2.2,1.619,1.619,0,0,1-2.2-.629l-2.3-4.15a1.965,1.965,0,0,1-.1-.21c-.577-1.52-4.719-3.093-10.645-3.093H8.868c-5.776,0-9.815,1.541-10.379,3.029a1.654,1.654,0,0,1-.132.269L-12.15-24.552a1.838,1.838,0,0,0,.089,1.286,1.826,1.826,0,0,0,1.022.97c.976.374,1.95-.581,2.586-1.45L.331-35.7a1.616,1.616,0,0,1,1.988-.51,1.623,1.623,0,0,1,.891,1.847l-5.959,24.7,3.038.62A1.619,1.619,0,0,1,1.583-7.414L1.2,7.269A2.271,2.271,0,0,0,2.365,9.388,3.831,3.831,0,0,0,6,9.406,2.408,2.408,0,0,0,7.237,7.164V-6.156A1.618,1.618,0,0,1,8.855-7.774a1.619,1.619,0,0,1,1.619,1.618V7.164A5.654,5.654,0,0,1,7.6,12.215a6.88,6.88,0,0,1-3.409.877"
          transform="translate(15.465 48.279)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13435.974 2798.389)">
        <path
          d="M2.1,6.451a7.034,7.034,0,0,1-3.533-.9A4.56,4.56,0,0,1-3.776,1.494l.292-6.642L-6.4-5.654a1.619,1.619,0,0,1-1.134-.8,1.612,1.612,0,0,1-.1-1.386l5.863-14.921a1.624,1.624,0,0,1,2.1-.914,1.619,1.619,0,0,1,.915,2.1L-3.891-8.5l2.364.409A1.616,1.616,0,0,1-.187-6.428L-.541,1.588A1.288,1.288,0,0,0,.213,2.76a4.135,4.135,0,0,0,3.75.015,1.4,1.4,0,0,0,.821-1.307V-6.5A1.619,1.619,0,0,1,6.4-8.119,1.619,1.619,0,0,1,8.022-6.5V1.469A4.668,4.668,0,0,1,5.566,5.587,7.058,7.058,0,0,1,2.1,6.451"
          transform="translate(7.742 23.786)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13436.252 2805.701)">
        <path
          d="M.634.958A6.1,6.1,0,0,1-1.215.621,1.62,1.62,0,0,1-2.258-1.415,1.622,1.622,0,0,1-.219-2.459a3.361,3.361,0,0,0,.85.191c-.008-.015.1-.131.3-.465a1.615,1.615,0,0,1,2.214-.575,1.62,1.62,0,0,1,.576,2.217A3.449,3.449,0,0,1,.634.958"
          transform="translate(2.336 3.532)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13436.258 2806.721)">
        <path
          d="M.345.686A1.454,1.454,0,0,1-.26.556,1.551,1.551,0,0,1-.8.212,1.615,1.615,0,0,1-1.273-.932a1.543,1.543,0,0,1,.128-.625A1.7,1.7,0,0,1-.8-2.076a1.672,1.672,0,0,1,2.288,0,1.79,1.79,0,0,1,.367.519,2.075,2.075,0,0,1,.107.625A1.62,1.62,0,0,1,1.489.212,1.673,1.673,0,0,1,.971.556a1.544,1.544,0,0,1-.625.13"
          transform="translate(1.273 2.529)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13438.313 2803.208)">
        <path
          d="M.785,1.435a1.639,1.639,0,0,1-.5-.078A4.614,4.614,0,0,1-2.547-1.105a3.914,3.914,0,0,1,0-3.229A1.619,1.619,0,0,1-.406-5.148,1.621,1.621,0,0,1,.41-3.008a.672.672,0,0,0,0,.586,1.38,1.38,0,0,0,.869.7A1.619,1.619,0,0,1,2.326.312,1.618,1.618,0,0,1,.785,1.435"
          transform="translate(2.895 5.29)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13438.104 2790.382)">
        <path
          d="M6.163,4.355C4.238,4.355,3.189,2.9,2.51,1.964A1.618,1.618,0,0,1,5.133.068c.847,1.171.95,1.131,1.472.965a1.38,1.38,0,0,0,.868-.7.686.686,0,0,0,0-.586L3.291-10.605c-.406-.9-4.028-2.218-9.718-2.218h0c-5.551,0-9.088,1.293-9.507,2.226l-3.658,9.561A1.616,1.616,0,0,1-21.685-.1a1.618,1.618,0,0,1-.932-2.09l3.693-9.644c1.759-3.924,10-4.222,12.5-4.222h0c2.537,0,10.95.3,12.7,4.186L10.452-1.521a3.867,3.867,0,0,1-.019,3.17A4.608,4.608,0,0,1,7.6,4.112a4.673,4.673,0,0,1-1.434.243"
          transform="translate(22.724 16.06)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13423.447 2798.439)">
        <path
          d="M1.269,6.437a7.167,7.167,0,0,1-3.521-.872A4.572,4.572,0,0,1-4.68,1.47,1.619,1.619,0,0,1-3.062-.149,1.62,1.62,0,0,1-1.442,1.47a1.358,1.358,0,0,0,.8,1.284,4.492,4.492,0,0,0,4.013-.067c.8-.47.788-1,.786-1.194l-.3-7.931A1.616,1.616,0,0,1,5.215-8.1L7.9-8.521,2.387-21.485a1.618,1.618,0,0,1,.856-2.122,1.623,1.623,0,0,1,2.124.856l6.327,14.87a1.628,1.628,0,0,1-.074,1.417,1.619,1.619,0,0,1-1.163.815l-3.318.524.249,6.533A4.563,4.563,0,0,1,5.013,5.473a7.454,7.454,0,0,1-3.744.964"
          transform="translate(4.68 23.736)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13430.254 2775.38)">
        <path
          d="M1.877,3.753a8.806,8.806,0,0,1-8.8-8.8,8.805,8.805,0,0,1,8.8-8.8,8.806,8.806,0,0,1,8.8,8.8,8.806,8.806,0,0,1-8.8,8.8m0-14.358a5.565,5.565,0,0,0-5.559,5.56A5.565,5.565,0,0,0,1.877.517,5.565,5.565,0,0,0,7.436-5.044,5.565,5.565,0,0,0,1.877-10.6"
          transform="translate(6.92 13.841)"
          fill={fill}
        />
      </g>
    </g>
    ;
  </svg>
);

export default SVG;
