import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '79.91',
  height = '90.43',
  className = '',
  viewBox = '0 0 79.91 90.43'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-36.48 -25.103)">
      <g transform="translate(59.755 25.103)">
        <path
          d="M3.491,6.983A16.259,16.259,0,0,1-8,2.269,16.259,16.259,0,0,1-12.872-9.27,16.385,16.385,0,0,1,3.38-25.75H3.5A16.384,16.384,0,0,1,19.86-9.5,16.257,16.257,0,0,1,15.146,2.108,16.255,16.255,0,0,1,3.608,6.983L3.6,5.54l.01,1.443H3.491M3.5-22.866H3.4A13.5,13.5,0,0,0-9.988-9.289,13.4,13.4,0,0,0-5.973.217,13.41,13.41,0,0,0,3.587,4.1,13.5,13.5,0,0,0,16.976-9.478,13.5,13.5,0,0,0,3.5-22.866"
          transform="translate(12.872 25.75)"
          fill={fill}
        />
      </g>
      <g transform="translate(52.774 50.785)">
        <path
          d="M.308,1.875A1.442,1.442,0,0,1-.782-.513,19.583,19.583,0,0,1,9.034-6.532l1.093-.322a1.44,1.44,0,0,1,1.79.976,1.443,1.443,0,0,1-.976,1.791l-1.11.325L9.8-3.75A16.491,16.491,0,0,0,1.4,1.378a1.443,1.443,0,0,1-1.091.5"
          transform="translate(1.134 6.913)"
          fill={fill}
        />
      </g>
      <g transform="translate(86.251 50.475)">
        <path
          d="M2.772,1.941a1.437,1.437,0,0,1-1.1-.51,16.64,16.64,0,0,0-8.7-5.3L-9.078-4.3a1.443,1.443,0,0,1-1.11-1.711,1.445,1.445,0,0,1,1.712-1.11l2.076.444.052.011A19.517,19.517,0,0,1,3.871-.434,1.442,1.442,0,0,1,3.7,1.6a1.434,1.434,0,0,1-.932.342"
          transform="translate(10.22 7.156)"
          fill={fill}
        />
      </g>
      <g transform="translate(40.818 62.669)">
        <path
          d="M6.664,10.989a1.447,1.447,0,0,1-.478-.081L-23.609.453a1.443,1.443,0,0,1-.965-1.361v-10.9a1.443,1.443,0,0,1,1.443-1.443A1.442,1.442,0,0,1-21.69-11.81v9.88L5.221,7.512V-27.6L-21.69-37.045v7.867a1.442,1.442,0,0,1-1.442,1.443,1.443,1.443,0,0,1-1.443-1.443v-9.9a1.439,1.439,0,0,1,.608-1.176,1.442,1.442,0,0,1,1.312-.184l29.8,10.454a1.443,1.443,0,0,1,.965,1.36V9.546a1.443,1.443,0,0,1-1.443,1.443"
          transform="translate(24.574 40.522)"
          fill={fill}
        />
      </g>
      <g transform="translate(79.372 62.669)">
        <path
          d="M.308,10.989A1.443,1.443,0,0,1-1.135,9.546V-28.626a1.441,1.441,0,0,1,.965-1.36l29.8-10.454a1.441,1.441,0,0,1,1.312.184,1.439,1.439,0,0,1,.608,1.176v9.193A1.443,1.443,0,0,1,30.1-28.444a1.442,1.442,0,0,1-1.442-1.443v-7.158L1.751-27.6V7.512L28.662-1.93V-12.111A1.442,1.442,0,0,1,30.1-13.554a1.443,1.443,0,0,1,1.443,1.443v11.2A1.443,1.443,0,0,1,30.581.453L.786,10.908a1.447,1.447,0,0,1-.478.081"
          transform="translate(1.135 40.522)"
          fill={fill}
        />
      </g>
      <g transform="translate(70.613 111.27)">
        <path
          d="M1.233.91A9.008,9.008,0,0,1-4-.783a1.442,1.442,0,0,1-.239-2.025,1.443,1.443,0,0,1,2.019-.245,6.225,6.225,0,0,0,7.1-.079,1.442,1.442,0,0,1,1.991.444A1.442,1.442,0,0,1,6.428-.7,9.567,9.567,0,0,1,1.233.91"
          transform="translate(4.547 3.357)"
          fill={fill}
        />
      </g>
      <g transform="translate(46.731 59.055)">
        <path
          d="M6.328,3.912A9.085,9.085,0,0,1,1.191,2.289L-22.623-11.738a1.443,1.443,0,0,1-.511-1.975,1.442,1.442,0,0,1,1.974-.51L2.723-.156a1.394,1.394,0,0,1,.161.111A6.224,6.224,0,0,0,9.976-.131l.053-.033L34.464-14.231a1.442,1.442,0,0,1,1.969.53,1.442,1.442,0,0,1-.53,1.969L11.5,2.321a9.569,9.569,0,0,1-5.169,1.59"
          transform="translate(23.334 14.424)"
          fill={fill}
        />
      </g>
      <g transform="translate(36.48 76.833)">
        <path
          d="M1.422,3.235H-.349A4.9,4.9,0,0,1-5.243-1.659V-7.033a4.9,4.9,0,0,1,4.894-4.9H1.422a4.9,4.9,0,0,1,4.894,4.9v5.373A4.9,4.9,0,0,1,1.422,3.235M-.349-9.044a2.013,2.013,0,0,0-2.01,2.011v5.373A2.012,2.012,0,0,0-.349.35H1.422a2.012,2.012,0,0,0,2.01-2.01V-7.033a2.013,2.013,0,0,0-2.01-2.011Z"
          transform="translate(5.243 11.928)"
          fill={fill}
        />
      </g>
      <g transform="translate(104.831 76.833)">
        <path
          d="M1.422,3.235H-.349A4.9,4.9,0,0,1-5.243-1.659V-7.033a4.9,4.9,0,0,1,4.894-4.9H1.422a4.9,4.9,0,0,1,4.9,4.9v5.373a4.9,4.9,0,0,1-4.9,4.894M-.349-9.044a2.013,2.013,0,0,0-2.01,2.011v5.373A2.012,2.012,0,0,0-.349.35H1.422a2.014,2.014,0,0,0,2.011-2.01V-7.033A2.014,2.014,0,0,0,1.422-9.044Z"
          transform="translate(5.243 11.928)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
