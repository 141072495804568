import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Message = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  width: 52%;
  text-align: center;
`;

export const Button = styled.button`
  display: inline-block;
  background-color: ${c.bluePrimary};
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  padding: 1rem 3rem;
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
`;