import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '48.27',
  height = '80.32',
  className = '',
  viewBox = '0 0 48.27 80.32'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M1.862,80.317a1.932,1.932,0,0,1-1.027-.305,1.839,1.839,0,0,1-.666-2.3L15.354,43.421l-13.187.194a1.591,1.591,0,0,1-1.582-.833,1.924,1.924,0,0,1-.139-1.777l18.3-39.866A1.861,1.861,0,0,1,20.435.056L43.977,0a1.741,1.741,0,0,1,1.61.944,1.908,1.908,0,0,1-.028,1.888L29.6,28.985l16.824.056a1.811,1.811,0,0,1,1.693,1.138,1.787,1.787,0,0,1-.361,2L3.222,79.734A1.849,1.849,0,0,1,1.862,80.317Zm16.38-40.644a1.888,1.888,0,0,1,1.555.833,1.772,1.772,0,0,1,.139,1.777L8,69.184,42.145,32.732l-15.852-.056a1.85,1.85,0,0,1-1.582-2.8L40.645,3.749,21.6,3.777,5.027,39.867l13.215-.194Z"
      transform="translate(0.001 0.001)"
      fill={fill}
    />
  </svg>
);

export default SVG;
