import styled from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Th = styled.th`
  font-size: 1.225rem;
  color: #fff;
  padding: .35rem 0;
  width: 5.25rem;
  border: .2rem solid #fff;

  ${({ good }) => good && `
    background-color: #d0d0d0;
  `}

  ${({ better }) => better && `
    background-color: #7c8180;
  `}

  ${({ best }) => best && `
    background-color: ${c.bluePrimary};
  `}
`;

export const ItemBody = styled.tbody`

  &:not(:last-of-type) {
    border-bottom: 1px dotted ${c.grayMed};
  }
`;

export const ItemName = styled.td`
  color: ${c.bluePrimary};
  font-size: 1.225rem;
  font-weight: 700;
  padding: .36rem 1.92rem .36rem .48rem;
`;

export const SelectionName = styled.td`
  font-size: 1.125rem;
  padding: .36rem .48rem;
`;

export const FillDotWrapper = styled.td`
  text-align: center;
  vertical-align: middle;
`;
