import styled from 'styled-components';

export const Message = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  width: 52%;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;