import React from 'react';
import { useContext } from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
// Data
import { HEARING_LOSS_LEVELS_DATA as data } from '../../data/hearingLossLevelsData';
// Style
import {
  Choice,
  Choices,
  Graph,
  GraphWrapper,
  Title
} from './HearingLoss.styles';

const HearingLoss = () => {
  const { assessmentDispatch,assessmentState } = useContext(assessmentContext);

  const choose = (num) => {
    assessmentDispatch({
      type: 'SET_HEARING_LOSS_LEVEL',
      payload: num
    });
  }

  return(
    <Page>
      <Progress visible goTo={'/hearing-evaluation'} navDirection={'prev'} text={'Back'} />
      <Title>Hearing results</Title>
      <GraphWrapper>
        <Graph
          src={data[assessmentState.hearingLossLevel].src}
          alt={data[assessmentState.hearingLossLevel].alt}
        />
      </GraphWrapper>
      <Choices>
        {data.map((choice,index) => {
          return <Choice
            active={assessmentState.hearingLossLevel===index}
            key={choice.text}
            onClick={() => choose(index)}
          >{choice.text}</Choice>
        })}
      </Choices>
      <Progress visible goTo={'/audiogram'} navDirection={'next'} text={'Select'} />
    </Page>
  );
};

export default HearingLoss;
