import React from 'react';

const SVG = ({
  style = {},
  fill = '#FFFFFF',
  width = '164.76',
  height = '37.56',
  className = '',
  viewBox = '0 0 164.76 37.56'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <path
          d="M19.32,37.56C8.94,37.56,0,30.42,0,18.78S8.94,0,19.32,0c4.74,0,9.54,1.38,12.54,3.66v6.66A17.51,17.51,0,0,0,20.1,5.88c-7.14,0-12.42,4.8-12.42,12.9S13,31.68,20.1,31.68a17.51,17.51,0,0,0,11.76-4.44V33.9C28.86,36.18,24.06,37.56,19.32,37.56Z"
          fill={fill}
        />
        <path
          d="M58,37.38c-3.24,0-5.16-1.32-5.76-4.08a13.92,13.92,0,0,1-9.78,4.2,6.44,6.44,0,0,1-6.78-6.6c0-6.06,7.44-9.66,16.38-10.44v-1c0-3.06-1.32-4.5-4.62-4.5a21.42,21.42,0,0,0-10,3.36V13.14a29.49,29.49,0,0,1,11.1-3c5.76,0,10.44,2.1,10.44,9.24v12.3c0,1.56.54,2.28,1.56,2.28a3.11,3.11,0,0,0,1.38-.36v2.64A6.75,6.75,0,0,1,58,37.38ZM52,24.42c-6.24.6-10.2,2.7-10.2,5.64,0,2.34,1.62,3,3.36,3,1.38,0,4.32-1,6.84-3.54Z"
          fill={fill}
        />
        <path
          d="M73.62,22.62V36.78h-7V11.16L73,10.32l.3,5.28A10.9,10.9,0,0,1,83,10.14v6.78C78.24,15.78,73.62,18.18,73.62,22.62Z"
          fill={fill}
        />
        <path
          d="M99.06,37.5c-8,0-13.56-5.82-13.56-13.86a13.09,13.09,0,0,1,13.08-13.5c6.6,0,11.52,4.08,11.52,10.32a15.48,15.48,0,0,1-.42,3.54H92.4c-.12,4.08,1.68,8.46,8.76,8.46a12.86,12.86,0,0,0,7.56-2.58l1.92,3.48C108.18,35.76,104.16,37.5,99.06,37.5Zm-.3-22.92c-2.94,0-5.46,1.68-6.06,5.7h11.1C104.4,17.16,102.36,14.58,98.76,14.58Z"
          fill={fill}
        />
        <path
          d="M118.9,23.3a8.39,8.39,0,0,0,5.56,2c3.11,0,5.28-1.86,5.28-5.25,0-2.94-1.75-5-4.86-5a4.49,4.49,0,0,0-3.19,1.26l-.87-1,7.87-8.15H119V5.52h12.45V6.61l-6.82,7a6.3,6.3,0,0,1,7.07,6.54c0,4.27-3.15,6.93-7.18,6.93a8.9,8.9,0,0,1-5.59-1.92Z"
          fill={fill}
        />
        <path
          d="M147.79,8a8.21,8.21,0,0,0-4.19-1.26c-3.54,0-6.3,2-6.72,9.48a7.23,7.23,0,0,1,5.74-2.87c3.67,0,6.43,3.08,6.43,7A6.72,6.72,0,0,1,142.23,27c-3,0-7.24-1.75-7.24-9.8C135,9.86,137.82,5,143.56,5a8.07,8.07,0,0,1,4.23,1.15Zm-5.73,17.46a4.84,4.84,0,0,0,5.07-5.14c0-3-1.78-5.36-4.72-5.36a6.68,6.68,0,0,0-5.5,3.19C137,24.21,140,25.43,142.06,25.43Z"
          fill={fill}
        />
        <path
          d="M152,23.3a8.37,8.37,0,0,0,5.57,2A4.94,4.94,0,0,0,162.84,20c0-2.94-1.61-4.93-4.72-4.93a4.88,4.88,0,0,0-4.38,2.62l-1-.42V5.52h11.19V7.24h-9.44V15a6.15,6.15,0,0,1,4.06-1.5c3.35,0,6.22,2.38,6.22,6.54a6.81,6.81,0,0,1-7.17,7,9,9,0,0,1-5.6-1.92Z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
