import React from 'react';
// Components
import { Swiper } from '../../../../../../components/Swiper';
// Slides
import { Slide1,Slide2,Slide3 } from './BalanceSafetySlides';
// Style
import { Container,SwiperWrapper } from './BalanceSafety.styles';
import { SubTitle,Title } from '../../Slide.styles';

const BalanceSafety = () => {

  return(
    <Container>
      <Title>Impacts of hearing loss</Title>
      <SubTitle>Balance/Safety</SubTitle>
      <SwiperWrapper>
        <Swiper
          progressDots={true}
          slides={[<Slide1 />,<Slide2 />,<Slide3 />]}
          timeout={500}
        />
      </SwiperWrapper>
    </Container>
  );
};

export default BalanceSafety;
