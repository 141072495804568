import React from 'react';

const SVG = ({
  style = {},
  width = '135.4',
  height = '50.1',
  className = '',
  viewBox = '0 0 135.4 50.1'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      className="st0"
      fill="#78a22f"
      d="M102,16.4l0.4,0.4c0.1-0.1,7.5-6.4,19.5-1.3c11.9,5,12.5,14.9,12.6,15l0.5,0c0-0.1,0.4-12.6-11.9-17.8
	C110.7,7.4,102.1,16.3,102,16.4z"
    />
    <path
      className="st0"
      fill="#78a22f"
      d="M110.2,10.7l0.3,0.3c0,0,5.3-4.5,13.6-0.9c8.3,3.5,8.8,10.4,8.8,10.5l0.4,0C133.2,13,127,6.9,119.5,7
	C116,7,112.7,8.3,110.2,10.7z"
    />
    <path
      className="st0"
      fill="#78a22f"
      d="M114.8,6.6l0.2,0.2c0,0,4.1-3.4,10.7-0.6c6.6,2.8,7,8.1,7,8.1l0.3,0c-0.1-6-5.1-10.7-11.1-10.6
	C119.3,3.8,116.8,4.8,114.8,6.6z"
    />
    <path
      className="st0"
      fill="#78a22f"
      d="M118.5,3.4l0.1,0.1c0,0,3.2-2.6,8.4-0.4c5.2,2.2,5.5,6.3,5.5,6.3l0.2,0c-0.1-3.3-2.1-6.2-5.2-7.5
	C124.5,0.6,121,1.1,118.5,3.4z"
    />
    <path
      className="st0"
      fill="#78a22f"
      d="M125.4,0.7L125.4,0.7c0.9-0.3,1.8-0.2,2.6,0.2c1.6,0.7,1.8,1.7,1.8,1.7l0.1,0c0-1-0.7-1.9-1.7-2.3
	C127.3-0.2,126.2,0,125.4,0.7z"
    />
    <path
      className="st0"
      fill="#78a22f"
      d="M114.2,22c0,2.7,2.2,4.8,4.8,4.8c1.2,0,2.4-0.5,3.3-1.3l-0.3-0.3c0,0-1.5,1-4.3-0.2c-2.8-1.2-3.1-3-3.1-3
	L114.2,22z"
    />
    <path
      className="st1"
      fill="#323E48"
      d="M5.6,46.6c-0.7,1.7,0.1,2.3,2.3,2.3l0,0.7H0.7v-0.7c2.6,0,2.8-0.7,3.7-2.6l6.8-15.8h0.4l6.9,15.8
	c1,1.9,1.1,2.6,3.7,2.6l0,0.7h-8.6v-0.7c2.4,0,3-0.6,2.3-2.3l-1.5-3.4H7.1L5.6,46.6z M10.7,34.8l-3.1,7.1l6.2,0L10.7,34.8z"
    />
    <path
      className="st1"
      fill="#323E48"
      d="M32.2,48.6c2.4,0,4.1-1.4,4.1-4.3v-4.8c0-3.4-0.4-3.9-2.7-3.9v-0.7h6.9v0.7c-2.2,0-2.7,0.5-2.7,3.9v4.6
	c0.2,3.1-2.2,5.8-5.3,6c-0.2,0-0.5,0-0.7,0c-3.1,0.2-5.8-2.2-6-5.3c0-0.2,0-0.5,0-0.7v-4.6c0-3.4-0.4-3.9-2.7-3.9v-0.7h7.7v0.7
	c-2.2,0-2.7,0.5-2.7,3.9v4.8C28.2,47.3,29.8,48.6,32.2,48.6z"
    />
    <path
      className="st1"
      fill="#323E48"
      d="M44.7,48.9c2.3,0,2.7-0.6,2.7-4v-5.4c0-3.4-0.4-3.9-2.7-3.9v-0.7h7.2c5.7,0,8.3,3.3,8.3,7.3
	c0,4-2.5,7.3-8.3,7.3h-7.2V48.9z M52,36.2h-2.2v9.6c0,2.2,0.2,2.6,1.9,2.6H52c4.5,0,5.3-3.4,5.3-6.1C57.3,39.6,56.5,36.2,52,36.2z"
    />
    <path
      className="st1"
      fill="#323E48"
      d="M64.7,49.6v-0.7c2.3,0,2.7-0.6,2.7-4v-5.4c0-3.4-0.4-3.9-2.7-3.9v-0.7h7.7v0.7c-2.3,0-2.7,0.5-2.7,3.9v5.4
	c0,3.4,0.4,4,2.7,4v0.7H64.7z"
    />
    <path
      className="st1"
      fill="#323E48"
      d="M77.7,48.9c2.3,0,2.7-0.6,2.7-4v-5.4c0-3.4-0.4-3.9-2.7-3.9v-0.7h7.1c3.2,0,4.9,1.6,4.9,3.7
	c0,1.1-0.5,2.2-1.5,2.9c1.6,0.6,2.6,2.1,2.6,3.9c0,2.4-1.9,4.2-5.4,4.2h-7.8L77.7,48.9z M84.9,36.2h-2.1v5h2.1
	c1.3,0.1,2.3-0.9,2.4-2.2c0-0.1,0-0.2,0-0.3c0.1-1.3-0.8-2.4-2.1-2.6C85,36.2,85,36.2,84.9,36.2z M85.6,42.5h-2.8v3.2
	c0,2.2,0.2,2.6,2.1,2.6h0.7c1.5,0.1,2.7-1,2.8-2.4c0-0.1,0-0.3,0-0.4c0.1-1.5-1-2.8-2.4-2.9C85.9,42.5,85.7,42.5,85.6,42.5z"
    />
    <path
      className="st1"
      fill="#323E48"
      d="M108.1,46.2l0.2-0.7h0.7l-0.3,4.1H96v-0.7c2.3,0,2.7-0.6,2.7-4v-5.4c0-3.4-0.4-3.9-2.7-3.9v-0.7h11.8l0.3,4.1
	h-0.7l-0.2-0.7c-0.4-1.6-0.8-2.2-3.9-2.2h-2.3v5h0.2c3.4,0,3.7-0.4,3.7-2.7h0.7v6.6H105c0-2.2-0.3-2.7-3.7-2.7h-0.2v2
	c0,3.4,0.4,3.9,2.7,3.9h0.4C107.3,48.3,107.7,47.8,108.1,46.2z"
    />
    <path
      className="st1"
      fill="#323E48"
      d="M114.4,35.6v-0.7h7.7v0.7c-2.3,0-2.7,0.5-2.7,3.9v4.9c0,3.4,0.4,3.9,2.7,3.9h1.2c3.1,0,3.5-0.6,3.9-2.2l0.2-0.7
	h0.7l-0.2,4.1h-13.5v-0.7c2.3,0,2.7-0.6,2.7-4v-5.4C117.1,36.2,116.6,35.6,114.4,35.6z"
    />
  </svg>
);

export default SVG;
