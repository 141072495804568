/*eslint-disable no-unused-vars*/
import React, { useEffect,useState } from 'react';
/*eslint-enable no-unused-vars*/
import { useLocation } from "react-router-dom";
import { MENUDOT_DATA as data } from '../../data/menuDotMapping';

const useMenuDot = () => {
  const [active,setActive] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    const slashRemovedpathname = pathname.substring(1);

    if(data.hasOwnProperty(slashRemovedpathname)) {
      setActive(data[slashRemovedpathname]);
    } else {
     setActive('');
    }
  },[pathname]);

  return active;
}

export default useMenuDot;
