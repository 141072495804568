import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 75vw;
  max-height: 100vh;
  max-width: 133.33vh;
  background: #fff;
  overflow: hidden;
`;
