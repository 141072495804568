import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '34.3',
  height = '32.22',
  className = '',
  viewBox = '0 0 34.3 32.22'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(26.078 4.247)">
      <path
        d="M25.44,31.672a.757.757,0,0,1-.758-.758V29.239A2.72,2.72,0,0,1,23.5,28.3a4.782,4.782,0,0,1-.781-2.8V4.458a.757.757,0,0,1,.758-.758h4.052a.757.757,0,0,1,.758.758v2.56h1.905a.745.745,0,0,1,.746.746l-.011,7.863a.747.747,0,0,1-.758.746h0a.747.747,0,0,1-.746-.758L29.435,8.5H28.287V25.486c0,2.227-1,3.443-2.1,3.788v1.618A.739.739,0,0,1,25.44,31.672Zm.057-3.765h.023c.631,0,1.274-.826,1.274-2.4V8.521H24.247V25.509c0,1.527.608,2.353,1.228,2.4ZM24.235,7.017h2.548V5.2H24.235Z"
        transform="translate(-22.72 -3.7)"
        fill={fill}
      />
    </g>
    <g>
      <path
        d="M23.875,8.218a.476.476,0,0,0-.034-.184.731.731,0,0,1-.023-.08.673.673,0,0,0-.149-.218L16.15.218a.673.673,0,0,0-.218-.149c-.023-.011-.046-.011-.069-.023a.583.583,0,0,0-.2-.034C15.645.011,15.633,0,15.61,0H1.905A1.915,1.915,0,0,0,0,1.905V30.314a1.908,1.908,0,0,0,1.905,1.905H21.981a1.908,1.908,0,0,0,1.905-1.905V8.264C23.886,8.253,23.875,8.241,23.875,8.218ZM16.368,2.571l4.947,4.947H16.77a.407.407,0,0,1-.4-.4V2.571Zm5.613,28.145H1.905a.407.407,0,0,1-.4-.4V1.905a.407.407,0,0,1,.4-.4H14.864V7.117A1.908,1.908,0,0,0,16.77,9.022h5.613V30.314A.415.415,0,0,1,21.981,30.716Z"
        fill={fill}
      />
      <path
        d="M5.028,11.255H17.367a.758.758,0,0,0,0-1.515H5.028a.758.758,0,0,0,0,1.515Z"
        transform="translate(0.631 1.44)"
        fill={fill}
      />
      <path
        d="M5.028,14.035H17.458a.758.758,0,0,0,0-1.515H5.028a.758.758,0,0,0,0,1.515Z"
        transform="translate(0.631 1.851)"
        fill={fill}
      />
      <path
        d="M5.028,16.805h12.5a.758.758,0,0,0,0-1.515H5.028a.758.758,0,0,0,0,1.515Z"
        transform="translate(0.631 2.26)"
        fill={fill}
      />
      <path
        d="M17.6,18.08H5.028a.758.758,0,0,0,0,1.515H17.6a.758.758,0,1,0,0-1.515Z"
        transform="translate(0.631 2.673)"
        fill={fill}
      />
      <path
        d="M5.028,8.475h6.382a.758.758,0,0,0,0-1.515H5.028a.758.758,0,0,0,0,1.515Z"
        transform="translate(0.631 1.029)"
        fill={fill}
      />
    </g>
  </svg>
);

export default SVG;
