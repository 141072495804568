import styled from 'styled-components';
import { colors as c } from '../../../../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 9.5rem;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 0;
  color: ${c.bluePrimary};
  padding-top: 4rem;
  text-align: center;
`;

export const SubTitle = styled.h2`
  font-size: 1.68rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const Screens = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Screen = styled.div`
  flex: 1;
  padding: 0 .9rem;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const AppIcons = styled.div`
  display: flex;
  justify-content: center;
`;

export const AppIcon = styled.img`
  width: 7.6875rem;
  height: auto;
  margin: 0 .3rem .3rem;
`;

export const Disclaimer = styled.p`
  font-size: 1rem;
  text-align: center;
`;
