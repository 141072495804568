import React, { useContext } from 'react';
// Components
import Icon from '../../../../components/Icon';
// Context
import assessmentContext from '../../../../contexts/assessment/assessmentStore';
// Data
import { ACCESSORIES_DATA } from '../../../../data/accessoriesData';
import { HEARING_LOSS_LEVELS_DATA } from '../../../../data/hearingLossLevelsData';
// Functions
import { commaSeparatedListUtility } from '../../../../functions/helpers';
import { filterLifestyleData } from '../../../../functions/data';
// Style
import {
  ButtonWrapper,
  Column,
  Columns,
  Container,
  EmailButton,
  Item,
  ItemTitle,
  Patient,
  SectionTitle,
  SpinnerIcon,
  SubSection,
  Title,
  WarningIcon
} from './ResultsPanel.styles';

const ResultsPanel = ({
  emailButtonState,
  sendEmailHandler
}) => {
  const { assessmentState } = useContext(assessmentContext);

  const accessories = [];
  ACCESSORIES_DATA.forEach((accy,index) => {
    if(assessmentState.accessories[index]) {
      accessories.push(accy.name);
    }
  });
  const accessoriesString = accessories.length ? commaSeparatedListUtility(accessories) : "None";

  return(
    <Container>
      <Title>Appointment summary</Title>
      <Patient>{assessmentState.patient.firstName} {assessmentState.patient.lastInitial}</Patient>
      <Columns>
        <Column>
          <SectionTitle>Lifestyle Results</SectionTitle>
          {filterLifestyleData(assessmentState).map((item) => {
            return(
              <Item key={item.title}>
                <ItemTitle>{item.title}:</ItemTitle>
                {item.answers.map((answer,index) => {
                  return(
                    <React.Fragment key={answer.text}>
                      &nbsp;{answer.text}{index < 2 ? ", " : ""}
                    </React.Fragment>
                  )
                })}
              </Item>
            )
          })}
        </Column>
        <Column>
          <SectionTitle>Hearing Results</SectionTitle>
            <Item>
              <ItemTitle>Hearing Loss:</ItemTitle>
              {' ' + HEARING_LOSS_LEVELS_DATA[assessmentState.hearingLossLevel].text}
            </Item>
            <Item>
              <ItemTitle>Type of Hearing Loss:</ItemTitle>
              {' ' + assessmentState.hearingLossType.type}
            </Item>
            <SubSection>
              <SectionTitle>Recommendation</SectionTitle>
              <Item>
                <ItemTitle>Technology Level:</ItemTitle>
                {' ' + assessmentState.technologyLevel}
              </Item>
              <Item>
                <ItemTitle>Hearing Aid Solution:</ItemTitle>
                {' ' + assessmentState.hearingAidStyle}
              </Item>
              <Item>
                <ItemTitle>Accessories:</ItemTitle>
                {' ' + accessoriesString}
              </Item>
            </SubSection>
        </Column>
      </Columns>
      <ButtonWrapper>
        <EmailButton
          clicked={emailButtonState > 0}
          disabled={emailButtonState > 0}
          onClick={sendEmailHandler}
          success={emailButtonState === 2}
        >
          {emailButtonState === 0 && (
            <span className="email-button-send">
              {assessmentState.provider.emailSentOnce
                ? 'Send Again'
                : 'Send to Provider'}
            </span>
          )}
          {emailButtonState === 1 && (
            <SpinnerIcon src="/images/icons/spinner.svg" alt="loading" />
          )}
          {emailButtonState === 2 && (
            <Icon name={'checkmark'} />
          )}
          {emailButtonState === 3 && (
            <WarningIcon src="/images/icons/warning.svg" alt="warning" />
          )}
        </EmailButton>
      </ButtonWrapper>
    </Container>
  );
};

export default ResultsPanel;
