import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '57.89',
  height = '89.47',
  className = '',
  viewBox = '0 0 57.89 89.47'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(13663.94 -2745.659)">
      <path
        d="M1.343,2.037a1.46,1.46,0,0,0,2.063-.062,5.847,5.847,0,0,0,.02-7.744,5.821,5.821,0,0,0-4.1-1.743,5.156,5.156,0,0,0-3.881,1.65A1.46,1.46,0,0,0-4.494-3.8a1.458,1.458,0,0,0,2.063-.062A2.143,2.143,0,0,1-.7-4.593a2.9,2.9,0,0,1,1.965.781,2.933,2.933,0,0,1,.02,3.787,1.461,1.461,0,0,0,.062,2.063"
        transform="translate(-13638.24 2777.59)"
        fill={fill}
      />
      <path
        d="M7.274,1.868a5.788,5.788,0,0,0-2.919.789V1.868A5.844,5.844,0,0,0-1.482-3.969a5.8,5.8,0,0,0-3.252.993,5.842,5.842,0,0,0-5.5-3.911,5.844,5.844,0,0,0-5.837,5.837V2.657a5.788,5.788,0,0,0-2.919-.789A5.844,5.844,0,0,0-24.83,7.705V16.92a1.459,1.459,0,0,0,1.459,1.459,1.459,1.459,0,0,0,1.459-1.459V7.705a2.921,2.921,0,0,1,2.919-2.919,2.921,2.921,0,0,1,2.919,2.919V22.757a1.459,1.459,0,0,0,1.459,1.459,1.459,1.459,0,0,0,1.459-1.459V-1.051a2.921,2.921,0,0,1,2.919-2.919A2.922,2.922,0,0,1-7.318-1.051V24.744A1.459,1.459,0,0,0-5.859,26.2,1.459,1.459,0,0,0-4.4,24.744V1.868A2.922,2.922,0,0,1-1.482-1.051,2.921,2.921,0,0,1,1.437,1.868V29.506a1.459,1.459,0,0,0,2.919,0V7.705a2.919,2.919,0,0,1,5.837,0V50.483A27.772,27.772,0,0,1,4.66,67.408l-.3.394V79.668H-18.993V72.9l-.678-.43A21.222,21.222,0,0,1-24.262,68.5a1.459,1.459,0,0,0-2.059-.14,1.459,1.459,0,0,0-.14,2.059,24.329,24.329,0,0,0,4.55,4.073v8.1H7.274v-13.8a30.789,30.789,0,0,0,5.837-18.305V7.705A5.844,5.844,0,0,0,7.274,1.868"
        transform="translate(-13619.167 2752.547)"
        fill={fill}
      />
      <path
        d="M.175.032A1.459,1.459,0,0,0-.5,1.983a22.11,22.11,0,0,1,1.928,9.581,1.459,1.459,0,0,0,2.919,0A25,25,0,0,0,2.126.7,1.461,1.461,0,0,0,.175.032"
        transform="translate(-13635.214 2798.763)"
        fill={fill}
      />
      <path
        d="M4.981,8.091c0-1.081,0-4.369,2.746-9.51.6-1.124,1.322-2.207,2.019-3.256,1.963-2.949,3.992-6,3.992-10.565A14.254,14.254,0,0,0,9.413-25.245,16.4,16.4,0,0,0-2.329-29.833c-10.039.106-16.038,7.5-16.038,14.593h2.919c0-5.671,4.918-11.588,13.148-11.675a13.21,13.21,0,0,1,9.66,3.744,11.319,11.319,0,0,1,3.459,7.931c0,3.684-1.7,6.24-3.5,8.948-.736,1.106-1.5,2.25-2.162,3.5C2.063,2.991,2.063,6.829,2.063,8.108a7.3,7.3,0,0,1-7.3,7.114,7.3,7.3,0,0,1-7.3-7.106h-2.919A10.256,10.256,0,0,0-5.234,18.141,10.264,10.264,0,0,0,4.981,8.091"
        transform="translate(-13645.573 2802.219)"
        fill={fill}
      />
      <path
        d="M.883,4.175A1.461,1.461,0,0,0,.465,2.154a1.461,1.461,0,0,0-2.021.419c-.122.186-.24.348-.353.5-.812,1.12-1.345,2.05-1.345,4.677,0,3.692,6.3,9.882,11.28,10.2.13.01.261.015.394.015a1.459,1.459,0,1,0,0-2.919c-2.131,0-3.09-3.216-3.09-5.5a4.949,4.949,0,0,1,4.55-4.714A9.877,9.877,0,0,0,20.093-5.374c0-5.808-4.3-10.023-10.216-10.023C3.6-15.4-1.31-10.995-1.31-5.374H1.609c0-4.447,4.205-7.1,8.269-7.1,4.364,0,7.3,2.855,7.3,7.1a6.978,6.978,0,0,1-7.3,7.289A7.85,7.85,0,0,0,2.41,9.547a12.53,12.53,0,0,0,.269,2.587C.94,10.543-.338,8.707-.338,7.753c0-1.875.264-2.237.789-2.962C.589,4.6.734,4.4.883,4.175"
        transform="translate(-13657.766 2793.82)"
        fill={fill}
      />
    </g>
    ;
  </svg>
);

export default SVG;
