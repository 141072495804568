import React from 'react';
// Components
import { Swiper } from '../../../../components/Swiper';
// Child Components
import { ThriveAppsSlide } from './children/ThriveAppsSlide';
import { ThriveCareSlide } from './children/ThriveCareSlide';
import { ThriveFeaturesSlide } from './children/ThriveFeaturesSlide';
// Style
import {
  Container
} from './AppsPanel.styles';

const AppsPanel = () => {

  return(
    <Container>
      <Swiper
        progressDots={true}
        timeout={250}
        slides={[<ThriveAppsSlide />, <ThriveFeaturesSlide />, <ThriveCareSlide />]}
      />
    </Container>
  );
};

export default AppsPanel;
