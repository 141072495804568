/*eslint-disable no-unused-vars*/
import React, { useEffect,useState } from 'react';
/*eslint-enable no-unused-vars*/

const  useElSize = (ref) => {
  const [dimensions,setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight
    });

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (ref.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  },[ref])

  return dimensions;
}

export default useElSize;
