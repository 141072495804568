import React from 'react';

const SVG = ({
  style = {},
  fill = '#506162',
  width = '92.21503',
  height = '68.59676',
  className = '',
  viewBox = '0 0 92.21503 68.59676'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M90.33,30.27654v.001H78.411a2.0083,2.0083,0,0,0-.217,0h-24.4a1.885,1.885,0,0,0-1.756,1.2l-2.58,6.611-7.647-19.011a1.88273,1.88273,0,0,0-3.49695.005L33.846,30.27752H15.23c-3.342-11.03.641-21.159,10.113-25.421,10.567-4.755,18.846,7.343,19.191,7.858a1.88589,1.88589,0,0,0,3.137,0c.08405-.12695,8.582-12.631,19.191-7.858,7.447,3.35,11.657,10.433,11.262,18.946a1.88552,1.88552,0,0,0,3.767.174c.462-9.965-4.7-18.609-13.481-22.559-9.467-4.258-17.972,1.951-22.307,7.123-4.335-5.172-12.841-11.382-22.307-7.123-10.8,4.861-15.732,16.513-12.479,28.859H1.885a1.885,1.885,0,0,0,0,3.77H35.12006a1.884,1.884,0,0,0,1.751-1.187l3.2-8.016,7.669,19.065a1.885,1.885,0,0,0,1.749,1.182h.01a1.88606,1.88606,0,0,0,1.746-1.2l3.841-9.844h20.465a38.18478,38.18478,0,0,1-2.6,4.846c-8.478,13.393-23,23.162-26.849,25.6-3.846-2.437-18.357-12.191-26.846-25.6a1.885,1.885,0,0,0-3.22052,1.9599q.01731.02829.03552.05609c10.28205,16.246,28.313,26.978,29.076,27.427a1.882,1.882,0,0,0,1.913,0c.762-.449,18.794-11.182,29.077-27.427a40.96725,40.96725,0,0,0,3.513-6.862H90.33a1.885,1.885,0,1,0,0-3.77Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
