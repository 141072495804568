import React from 'react';
import styled from 'styled-components';
// Functions
import parse from 'html-react-parser';
// Style
import { Description,Disclaimer,SlideWrapper } from '../../Slide.styles';

const S1Description = styled(Description)`
  margin: 2.6rem 0 1rem;
`;

const S1Image = styled.img`
  width: 28.3125rem;
  height: auto;
  margin-bottom: 3.8rem;
`;

export const Slide1 = () => {

  return(
    <SlideWrapper>
      <S1Description>{parse('Hearing aids with integrated sensors track physical activity and can<br />help you be more active.')}</S1Description>
      <S1Image src="/images/impacts/cancer-2-infographics.svg" alt="colon cancer and prostate cancer infographics" />
      <Disclaimer>{parse('<strong>Source:</strong> Kaiser Permanente "The Gift of Walking"')}</Disclaimer>
    </SlideWrapper>
  )
}

const S2Description = styled(Description)`
  margin: 2.6rem 0 1rem;
`;

const S2Image = styled.img`
  width: 38.875rem;
  height: auto;
  margin-bottom: 4.45rem;
`;

export const Slide2 = () => {
  return(
    <SlideWrapper>
      <S2Description>{parse('Hearing aids with integrated sensors track physical activity and can<br />help you be more active.')}</S2Description>
      <S2Image src="/images/impacts/physical-health-3-infographics.svg" alt="stroke, diabetes, and breast cancer infographics" />
      <Disclaimer>{parse('<strong>Source:</strong> Kaiser Permanente "The Gift of Walking"')}</Disclaimer>
    </SlideWrapper>
  )
}

const S3Description = styled(Description)`
  margin: 2.6rem 0 1rem;
`;

const S3Image = styled.img`
  width: 38.875rem;
  height: auto;
  margin-bottom: 10rem;
`;

export const Slide3 = () => {
  return(
    <SlideWrapper>
      <S3Description>{parse('Hearing aids with integrated sensors track physical activity and<br />can help you be more active. Inactive adults increase their risk of:')}</S3Description>
      <S3Image src="/images/impacts/physical-health-4-graphics.svg" alt="cancer, heart disease, stroke, and type 2 diabetes graphics" />
      <Disclaimer>{parse('<strong>Source:</strong> U.S. Department of Health &amp; Human Services<br /><a href="https://www.hhs.gov/fitness/be-active/importance-of-physical-activity/index.html" target="_blank">https://www.hhs.gov/fitness/be-active/importance-of-physical-activity/index.html</a>')}</Disclaimer>
    </SlideWrapper>
  )
}
