import React, { useContext,useEffect,useState } from 'react';
// Components
import { Progress } from '../../components/Progress';
// Child Components
import { ImpactCircle } from './children/ImpactCircle';
import { Modal } from './children/Modal';
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
// Functions
import parse from 'html-react-parser';
import { calculateImpactsTotals } from '../../functions/data';
// Style
import { CloseModal,StyledPage,Title } from './ImpactsOfHearingLoss.styles';

const ImpactsOfHearingLoss = () => {
  const { assessmentState } = useContext(assessmentContext);
  const [modalOpen,setModalOpen] = useState(false);
  const [modalName,setModalName] = useState('');
  const [highlighted,setHighlighted] = useState([false,false,false,false,false]);

  useEffect(() => {
    assessmentState.assessment.length && setHighlighted(calculateImpactsTotals(assessmentState.assessment));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[assessmentState]);

  return(
    <StyledPage>
      <Progress visible={!modalOpen} goTo={'/types-of-hearing-loss'} navDirection={'prev'} text={'Back'} />
      <Title>{parse('Impacts of<br />a hearing loss')}</Title>
      <ImpactCircle highlighted={highlighted} setModalOpen={setModalOpen} setModalName={setModalName} />
      <Modal modalName={modalName} isOpen={modalOpen} />
      <CloseModal active={modalOpen} onClick={() => setModalOpen(false)}>
        <img src="/images/icons/times-icon.svg" alt="close" />
      </CloseModal>
      <Progress visible={!modalOpen} goTo={'/technology-levels'} navDirection={'next'} text={'Next'} />
    </StyledPage>
  );
};

export default ImpactsOfHearingLoss;
