import React from 'react'
import { useHistory } from 'react-router-dom';
// Style
import { Container } from './Button.styles'

const Button = ({
  className,
  goTo = '/',
  text = 'button'
}) => {
  const history = useHistory();

  function go(e) {
    e.preventDefault();
    history.push(goTo);
  }

  return(
    <Container className={className} href={goTo} onClick={(e) => go(e)}>{text}</Container>
  );
}

export default Button