export const THRIVE_APP_DATA = [
  {
    name: "Adjust Volume",
    icon: "adjust",
  },
  {
    name: "Activity",
    icon: "bodyActivity",
  },
  {
    name: "Stream Phone Calls & Audio",
    icon: "stream",
  },
  {
    name: "Engagement",
    icon: "brainHealth",
  },
  {
    name: "Create Memories",
    icon: "memories",
  },
  {
    name: "Fall Detection and Alerts",
    icon: "fallDetection",
  },
  {
    name: "Self Check",
    icon: "selfCheck",
  },
  {
    name: "Transcribe",
    icon: "transcribe",
  },
  {
    name: "Reminders",
    icon: "reminders",
  },
  {
    name: "Thrive Assistant",
    icon: "thriveAssistant",
  },
  {
    name: "Find My Phone",
    icon: "findMyPhone",
  },
  {
    name: "Voice AI",
    icon: "voiceAi",
  },
  {
    name: "TeleHear",
    icon: "telehear",
  }
];
