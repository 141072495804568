import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Content = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 2rem;
  color: ${c.bluePrimary};
`;

export const Description = styled.p`
  font-size: 1.7rem;
  width: 35%;
  text-align: center;
  margin-bottom: 2rem;
`;

export const VidContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BGVideo = styled.video`
  height: 100%;
  width: auto;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;