import React, { useContext,useEffect,useRef } from 'react';
import { Redirect,Route,Switch,useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// Components
import { Loading } from '../Loading';
import { Navigation } from '../Navigation';
// Pages
import { Login } from '../../pages/Login';
import { Logout } from '../../pages/Logout';
import { SSOLogout } from '../../pages/SSOLogout';
import { NoAccess } from '../../pages/NoAccess';
import { Start } from '../../pages/Start';
import { Intro } from '../../pages/Intro';
import { TodaysVisit } from '../../pages/TodaysVisit';
import { SelfDescriptor } from '../../pages/SelfDescriptor';
import { FavoriteActivities } from '../../pages/FavoriteActivities';
import { WellnessConcerns } from '../../pages/WellnessConcerns';
import { HealthyLivingGoals } from '../../pages/HealthyLivingGoals';
import { HearingChallenges } from '../../pages/HearingChallenges';
import { LifestyleAssessmentResults } from '../../pages/LifestyleAssessmentResults';
import { YourStory } from '../../pages/YourStory';
import { HowWeHear } from '../../pages/HowWeHear';
import { UnderstandingSound } from '../../pages/UnderstandingSound';
import { HearingEvaluation } from '../../pages/HearingEvaluation';
import { HearingLoss } from '../../pages/HearingLoss';
import { Audiogram } from '../../pages/Audiogram';
import { TypesOfHearingLoss } from '../../pages/TypesOfHearingLoss';
import { ImpactsOfHearingLoss } from '../../pages/ImpactsOfHearingLoss';
import { TechnologyLevels } from '../../pages/TechnologyLevels';
import { HearingAidTransition } from '../../pages/HearingAidTransition';
import { HearingAidStyles } from '../../pages/HearingAidStyles';
import { Accessories } from '../../pages/Accessories';
import { AppointmentSummary } from '../../pages/AppointmentSummary';
// Hooks
import { useElSize } from '../../hooks/useElSize';
import { useWindowSize } from '../../hooks/useWindowSize';
// Auth
import { useMsal } from "@azure/msal-react";
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
import configContext from '../../contexts/config/configStore';
// Data
import { idData } from '../../data/idAnalyticsData';
// Analytics
import ReactGA from 'react-ga';
// Style
import { Container } from './Frame4x3.styles';

const  Frame4x3 = () => {
  const { assessmentDispatch,assessmentState } = useContext(assessmentContext);
  const { configDispatch } = useContext(configContext);
  const location = useLocation();
  const frameRef = useRef(null);
  const frameSize = useElSize(frameRef);
  const windowSize = useWindowSize();
  const { accounts } = useMsal();

  // Google Analytics
  useEffect(() => {
    var checkGA = setInterval(function() {
      if (window.ga) {
        ReactGA.pageview(location.pathname);
        if(idData.includes(location.pathname)) {
          window.ga('set', 'dimension1', assessmentState.provider.id);
          window.ga('set', 'dimension2', assessmentState.assessmentID);
        }
        clearInterval(checkGA);
      }
   }, 100);
   return () => clearInterval(checkGA);
  }, [assessmentState.provider.id,assessmentState.assessmentID,location.pathname]);

  // Manage the 4x3 frame sizing
  useEffect(() => {
    const c = 64;
    const shape = windowSize.width/windowSize.height < 4/3 ? "tall" : "wide";
    const html = document.getElementsByTagName('html')[0];
    html.setAttribute("style", "font-size:"+(shape === 'tall' ? frameSize.width/c+'px' : frameSize.height/(.75*c)+'px'));
  },[frameSize,windowSize]);

  useEffect(() => {
    if(accounts.length) {
      assessmentDispatch({ type: 'SET_PROVIDER_EMAIL', payload: accounts[0].idTokenClaims.email.toLowerCase() });
      configDispatch({ type: 'SET_BRAND_NAME', payload: accounts[0].idTokenClaims.brand.toLowerCase() });
    }
  },[accounts,assessmentDispatch,configDispatch]);

  return(
    <Container ref={frameRef}>
      <Loading />
      <Navigation />
      <TransitionGroup className="transition-group">
        <CSSTransition
          classNames="page"
          key={location.pathname}
          timeout={1000}
          unmountOnExit
        >
        <Switch location={location}>
          <Route exact path="/" render={() => <Start />} />
          <Route exact path="/intro" render={() => <Intro />} />
          <Route exact path="/todays-visit" render={() => <TodaysVisit />} />
          <Route exact path="/self-descriptor" render={() => <SelfDescriptor />} />
          <Route exact path="/favorite-activities" render={() => <FavoriteActivities />} />
          <Route exact path="/wellness-concerns" render={() => <WellnessConcerns />} />
          <Route exact path="/healthy-living-goals" render={() => <HealthyLivingGoals />} />
          <Route exact path="/hearing-challenges" render={() => <HearingChallenges />} />
          <Route exact path="/lifestyle-assessment-results" render={() => <LifestyleAssessmentResults />} />
          <Route exact path="/your-story" render={() => <YourStory />} />
          <Route exact path="/how-we-hear" render={() => <HowWeHear />} />
          <Route exact path="/understanding-sound" render={() => <UnderstandingSound />} />
          <Route exact path="/hearing-evaluation" render={() => <HearingEvaluation />} />
          <Route exact path="/hearing-loss" render={() => <HearingLoss />} />
          <Route exact path="/audiogram" render={() => <Audiogram />} />
          <Route exact path="/types-of-hearing-loss" render={() => <TypesOfHearingLoss />} />
          <Route exact path="/impacts-of-hearing-loss" render={() => <ImpactsOfHearingLoss />} />
          <Route exact path="/technology-levels" render={() => <TechnologyLevels />} />
          <Route exact path="/hearing-aid-transition" render={() => <HearingAidTransition />} />
          <Route exact path="/hearing-aid-styles" render={() => <HearingAidStyles />} />
          <Route exact path="/accessories" render={() => <Accessories />} />
          <Route exact path="/appointment-summary" render={() => <AppointmentSummary />} />
          <Route exact path="/login" render={() => <Login />} />
          <Route exact path="/logout" render={() => <Logout />} />
          <Route exact path="/ssologout" render={() => <SSOLogout />} />
          <Route exact path="/noAccess" render={() => <NoAccess />} />
          <Redirect from='/:anything-else' to='/'/>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
    </Container>
  )
}

export default Frame4x3;
