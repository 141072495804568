import styled from 'styled-components';
import { colors as c } from '../../../../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5.5rem;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 0;
  color: ${c.bluePrimary};
  padding-top: 4rem;
  text-align: center;
`;

export const Image = styled.img`
  width: 35.25rem;
  height: auto;
  margin-left: 4rem;
  margin-top: 1.4rem;
`;
