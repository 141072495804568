export const choicesData = [
  {
    text: 'None',
    scale: 0,
    icon: 'zzz'
  },
  {
    text: 'Neutral',
    scale: 1,
    icon: 'neutralFace'
  },
  {
    text: 'Slightly',
    scale: 2,
    icon: 'slightlyFace'
  },
  {
    text: 'Not Good',
    scale: 3,
    icon: 'notGoodFace'
  },
  {
    text: 'Bad',
    scale: 4,
    icon: 'badFace'
  },
  {
    text: 'Very Bad',
    scale: 5,
    icon: 'veryBadFace'
  }
];