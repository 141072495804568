import React from 'react';
import styled from 'styled-components';
// Functions
import parse from 'html-react-parser';
// Style
import { Description,Disclaimer,SlideWrapper } from '../../Slide.styles';

const S1Image = styled.img`
  width: 10rem;
  height: auto;
  margin: 7rem 0 1rem;
`;

const S1Description = styled(Description)`
  margin-bottom: 9rem;
`;

export const Slide1 = () => {

  return(
    <SlideWrapper>
      <S1Image src="/images/impacts/lonely-graphic.svg" alt="lonely graphic" />
      <S1Description>{parse('<strong>Loneliness is associated with untreated hearing loss,</strong><br /> and impacts 35% of adults 45 and older.')}</S1Description>
      <Disclaimer>{parse('<strong>Source:</strong> Anderson, G. Oscar & Thayer, Colette, E. (2018).<br />For a Future Without Senior Poverty. AARP Foundation. <a href="https://doi.org/res.00246.001" target="_blank">https://doi.org/res.00246.001</a>')}</Disclaimer>
    </SlideWrapper>
  )
}

const S2Image = styled.img`
  width: 10rem;
  height: auto;
  margin: 7rem 0 1rem;
`;

const S2Description = styled(Description)`
  margin-bottom: 9rem;
`;

export const Slide2 = () => {
  return(
    <SlideWrapper>
      <S2Image src="/images/impacts/hourglass-graphic.svg" alt="hourglass graphic" />
      <S2Description>{parse('<strong>Untreated hearing loss is linked to loneliness,</strong><br /> which increases the risk of premature death by 45%.')}</S2Description>
      <Disclaimer>{parse('<strong>Source:</strong> Holt-Lunstad J, Smith TB, Layton JB (2010).<br />Social Relationships and Mortality Risk: A Meta-analytic Review. PLOS Med 7(7): e1000316.<br /><a href="https://doi.org/res.00246.001" target="_blank">https://doi.org/res.00246.001</a>')}</Disclaimer>
    </SlideWrapper>
  )
}

const S3Image = styled.img`
  width: 10rem;
  height: auto;
  margin: 7rem 0 1rem;
`;

const S3Description = styled(Description)`
  margin-bottom: 9rem;
`;

export const Slide3 = () => {
  return(
    <SlideWrapper>
      <S3Image src="/images/impacts/sad-man-graphic.svg" alt="sad man graphic" />
      <S3Description>{parse('Untreated hearing loss can lead to depression,<br />which is associated with an <strong>increased risk of<br />premature death for both men and women.</strong>')}</S3Description>
      <Disclaimer>{parse('<strong>Source:</strong> World Health Organization')}</Disclaimer>
    </SlideWrapper>
  )
}

const S4Description1 = styled(Description)`
  margin: 4rem 0 0;
`;

const S4Image = styled.img`
  width: 10rem;
  height: auto;
  margin: 1rem 0;
`;

const S4Description2 = styled(Description)`
  margin-bottom: 9rem;
`;

export const Slide4 = () => {
  return(
    <SlideWrapper>
      <S4Description1>{parse('Untreated hearing loss contributes to <strong>feelings<br />of isolation,</strong> which can shorten life expectancy.')}</S4Description1>
      <S4Image src="/images/impacts/rain-cloud-graphic.png" alt="rain cloud graphic" />
      <S4Description2>{parse('<strong>Treating hearing loss with hearing aids<br />will help you stay engaged.</strong>')}</S4Description2>
      <Disclaimer>{parse('<strong>Source:</strong> Johns Hopkins Medecine')}</Disclaimer>
    </SlideWrapper>
  )
}