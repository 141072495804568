import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  width: 100%;
  perspective: 18.75rem;
`;

export const Flipper = styled.div`
  width: 100%;
  transform-style: preserve-3d;
  perspective-origin: 50% 50%;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;

  ${({ flipped }) => flipped && `
    transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
    transform: rotateY(180deg) translateZ(1px);
    transform-style: preserve-3d;
    will-change: transform;
  `}
`;

export const Side = styled.div`
  font-size: .8rem;
  width: 100%;
  z-index: 0;

  ${({ activeSide }) => activeSide && `
    z-index: 1;
  `}
`;

export const Side1 = styled(Side)`
  position: relative;
`;

export const Side2 = styled(Side)`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg) translateZ(1px);
`;

export const Circle = styled.div`
  position: relative;
  font-size: .8rem;
  width: 100%;
  border-radius: 50%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  ${({ gray }) => gray && `
    background-color: ${c.grayDark};
  `}

  ${({ blue }) => blue && `
    background-color: ${c.bluePrimary};
  `}
`;

export const Content = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;