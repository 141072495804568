import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Style
import {
  Background,
  Copy,
  Image,
  Title
} from './UnderstandingSound.styles';

const UnderstandingSound = () => {

  return(
    <Page>
      <Progress visible goTo={'/how-we-hear'} navDirection={'prev'} text={'Back'} />
      <Background />
      <Title>Understanding sound</Title>
      <Copy>For sounds to be heard and understood correctly, they need to get to the brain uninterrupted. If they don't, certain sounds can be missed.</Copy>
      <Image src="/images/understanding-sound/toothbrush-misinterperetation.svg" alt="toothbrush misinterperetation" />
      <Progress visible goTo={'/hearing-evaluation'} navDirection={'next'} text={'Next'} />
    </Page>
  );
};

export default UnderstandingSound;
