import styled, { css,keyframes } from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div`
  position: relative;
  width: 36rem;
  height: 36rem;
  border-radius: 50%;
  border: 0.0625rem dashed ${c.grayLight1};
  margin-top: 3rem;
`;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(30, 155, 233, 0.3);
  }
  40% {
    box-shadow: 0 0 0 1.875rem rgba(208, 214, 216, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(28, 171, 228, 0);
  }
`;

export const Choice = styled.button`
  position: absolute;
  width: 9.625rem;
  height: 9.625rem;
  border-radius: 50%;
  text-align: center;
  font-family: 'DIN Next';
  font-size: 0.96rem;
  font-weight: bold;
  background-color: #ffffff;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:nth-of-type(1) {
    top: -4.6rem;
    left: 50%;
    transform: translate(-50%);
  }

  &:nth-of-type(2) {
    top: 7rem;
    right: -3.5rem;
  }

  &:nth-of-type(3) {
    bottom: 0;
    right: 1.3rem;
  }

  &:nth-of-type(4) {
    bottom: 0;
    left: 1.3rem;
  }

  &:nth-of-type(5) {
    top: 7rem;
    left: -3.5rem;
  }

  ${({ active }) => active && css`
    background-color: ${c.bluePrimary};
    color: #fff;
    animation: ${pulse} 1.5s infinite ease-in-out;
  `}

  svg {
    margin-bottom: .2rem;
  }
`;
