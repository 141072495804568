import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.button`
  position: relative;
  background-color: ${c.grayMed};
  width: 2.5625rem;
  height: 1.5625rem;
  border-radius: 1.5625rem;
  overflow: hidden;
  border: none;
  outline: none;
  padding: 0;

  &:after {
    position: absolute;
    left: .125rem;
    top: .125rem;
    content: '';
    width: 1.3125rem;
    height: 1.3125rem;
    background-color: #fff;
    border-radius: 1.5625rem;
    transition: left .1s ease;
    box-shadow: 0 .125rem .1875rem -.0625rem rgba(0,0,0,.3);
  }

  &:hover {
    cursor: pointer;
  }

  ${({ toggled }) => toggled && `
    background-color: ${c.bluePrimary};

    &:after {
      left: 1.125rem;
    }
  `}
`;