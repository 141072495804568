import React from 'react';
import styled from 'styled-components';
// Functions
import parse from 'html-react-parser';
// Style
import { Description,Disclaimer,SlideWrapper } from '../../Slide.styles';

const S1Description = styled(Description)`
  margin: 2rem 0 1rem;
`;

const S1Image = styled.img`
  width: 26rem;
  height: auto;
  margin-bottom: 5.5rem;
`;

export const Slide1 = () => {

  return(
    <SlideWrapper>
      <S1Description>{parse('<strong>Treating your hearing loss can help improve sociability.<br />Communication may help you live longer.</strong>')}</S1Description>
      <S1Image src="/images/impacts/longevity-graph.svg" alt="graph of predictors of how long you will live" />
      <Disclaimer>{parse('<strong>Source:</strong> Pinker, Susan. (2017). The Secret to Living Longer May Be Your Social Life.')}</Disclaimer>
    </SlideWrapper>
  )
}

const S2Image = styled.img`
  width: 10rem;
  height: auto;
  margin: 5.5rem 0 1rem;
`;

const S2Description = styled(Description)`
  margin-bottom: 7.5rem;
`;

export const Slide2 = () => {
  return(
    <SlideWrapper>
      <S2Image src="/images/impacts/social-interaction-1-graphic.svg" alt="couple love graphic" />
      <S2Description>{parse('Hearing aid users reported <strong>significant<br />improvements in their relationships</strong><br /> at home, sense of independence,<br />and social and sex life.')}</S2Description>
      <Disclaimer>{parse('<strong>Source:</strong> American Academy of Audiology')}</Disclaimer>
    </SlideWrapper>
  )
}
