import React, { useEffect,useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// Components
import { CheckCircle } from '../../components/CheckCircle';
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Style
import {
  CheckList,
  CheckListItem,
  Column,
  Columns,
  Image,
  ItemText,
  ListIntro,
  Subtitle,
  Title
} from './HearingAidTransition.styles';

const HearingAidTransition = () => {
  const [ imageIndex,setImageIndex ] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex(imageIndex === 3 ? 0 : imageIndex+1);
    }, 3000);
    return () => clearInterval(intervalId);
  });

  return(
    <Page>
      <Progress visible goTo={'/technology-levels'} navDirection={'prev'} text={'Back'} />
      <Title>Every ear is unique</Title>
      <Subtitle>Let's determine what hearing aid style is right for you.</Subtitle>
      <Columns>
        <Column left>
          <TransitionGroup className="transition-group">
            <CSSTransition
              classNames="image"
              key={imageIndex}
              timeout={1000}
              unmountOnExit
            >
              <Image src={`/images/hearing-aid-transition/${imageIndex}.jpg`} alt="" />
            </CSSTransition>
          </TransitionGroup>
        </Column>
        <Column right>
          <ListIntro>A variety of important and unique factors include:</ListIntro>
          <CheckList>
            <CheckListItem>
              <CheckCircle /><ItemText>The shape of your ear canal</ItemText>
            </CheckListItem>
            <CheckListItem>
              <CheckCircle /><ItemText>Your finger dexterity</ItemText>
            </CheckListItem>
            <CheckListItem>
              <CheckCircle /><ItemText>Desired features</ItemText>
            </CheckListItem>
            <CheckListItem>
              <CheckCircle /><ItemText>Cosmetic concerns</ItemText>
            </CheckListItem>
            <CheckListItem>
              <CheckCircle /><ItemText>Changing needs</ItemText>
            </CheckListItem>
          </CheckList>
        </Column>
      </Columns>
      <Progress visible goTo={'/hearing-aid-styles'} navDirection={'next'} text={'Next'} />
    </Page>
  );
};

export default HearingAidTransition;
