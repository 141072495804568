import React from 'react';
// Style
import { Container,Option,Options,Slider } from './ButtonSlideTriple.styles';

const ButtonSlideTriple = ({
  active,
  choose,
  items = ['item1','item2','item3']
}) => {

  return(
    <Container>
      <Slider position={active} />
      <Options>
        <Option
          active={active===0}
          onClick={() => choose(0)}
        >{items[0]}</Option>
        <Option
          active={active===1}
          onClick={() => choose(1)}
        >{items[1]}</Option>
        <Option
          active={active===2}
          onClick={() => choose(2)}
        >{items[2]}</Option>
      </Options>
    </Container>
  );
};

export default ButtonSlideTriple;
