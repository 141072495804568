import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  font-size: 3.82rem;
  color: ${c.bluePrimary};
  text-align: center;
  margin-top: 3rem;
`;

export const PanelToggleWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 23rem;
  justify-self: end;
  padding-bottom: 3rem;
`;

export const PanelsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .panel-enter {
    opacity: 0;
  }

  .panel-enter-active {
    opacity: 1;
    transition: opacity 250ms;
  }

  .panel-exit {
    opacity: 1;
  }

  .panel-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
`;
