import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '40',
  height = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M20.04723,13.5448a.82305.82305,0,0,1-.82649-.82649V5.64723a.82649.82649,0,1,1,1.653,0v7.07108c.09183.45916-.36733.82649-.82649.82649"
      fill={fill} />
    <path
      d="M12.60882,20.79955H5.53773a.82649.82649,0,0,1,0-1.653h7.07109a.82649.82649,0,0,1,0,1.653"
      fill={fill} />
    <path
      d="M19.86357,35.30905a.82306.82306,0,0,1-.82649-.8265V27.41147a.82649.82649,0,0,1,1.653,0v7.07109a.82305.82305,0,0,1-.82649.82649"
      fill={fill} />
    <path
      d="M34.37307,20.98321H27.302a.82649.82649,0,0,1,0-1.653h7.07109a.82649.82649,0,1,1,0,1.653"
      fill={fill} />
    <path
      d="M14.81279,15.65694a.992.992,0,0,1-.64282-.2755L9.211,10.4225a.96328.96328,0,0,1,0-1.19382.783.783,0,0,1,1.19382,0l4.959,4.959a.783.783,0,0,1,0,1.19382.5553.5553,0,0,1-.551.27549"
      fill={fill} />
    <path
      d="M9.762,31.08476a.992.992,0,0,1-.64283-.27549.783.783,0,0,1,0-1.19382l4.95894-4.95894A.84415.84415,0,0,1,15.272,25.85032l-4.95894,4.959a.79374.79374,0,0,1-.551.27549"
      fill={fill} />
    <path 
      d="M30.057,31.17659a.992.992,0,0,1-.64283-.27549L24.547,25.94216a.84416.84416,0,0,1,1.19382-1.19382l4.95894,4.95894a.783.783,0,0,1,0,1.19382.83368.83368,0,0,1-.64282.27549"
      fill={fill} />
    <path
      d="M25.28167,15.74877a.992.992,0,0,1-.64283-.27549.783.783,0,0,1,0-1.19382l4.959-4.95894a.84415.84415,0,0,1,1.19381,1.19381l-4.95894,4.959a.79372.79372,0,0,1-.551.27549"
      fill={fill} />
  </svg>
);

export default SVG;
