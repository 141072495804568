import React from 'react';
// Components
import { Swiper } from '../../../../../../components/Swiper';
// Slides
import { Slide1,Slide2,Slide3,Slide4 } from './LonelinessDepressionSlides';
// Style
import { Container,SwiperWrapper } from './LonelinessDepression.styles';
import { SubTitle,Title } from '../../Slide.styles';

const LonelinessDepression = () => {

  return(
    <Container>
      <Title>Impacts of hearing loss</Title>
      <SubTitle>Loneliness/Depression</SubTitle>
      <SwiperWrapper>
        <Swiper
          progressDots={true}
          slides={[<Slide1 />,<Slide2 />,<Slide3 />,<Slide4 />]}
          timeout={500}
        />
      </SwiperWrapper>
    </Container>
  );
};

export default LonelinessDepression;
