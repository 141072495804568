import React from 'react';
// Components
import { Swiper } from '../../../../../../components/Swiper';
// Slides
import { Slide1,Slide2 } from './SocialInteractionSlides';
// Style
import { Container,SwiperWrapper } from './SocialInteraction.styles';
import { SubTitle,Title } from '../../Slide.styles';

const SocialInteraction = () => {

  return(
    <Container>
      <Title>Impacts of hearing loss</Title>
      <SubTitle>Social Interaction</SubTitle>
      <SwiperWrapper>
        <Swiper
          progressDots={true}
          slides={[<Slide1 />,<Slide2 />]}
          timeout={500}
        />
      </SwiperWrapper>
    </Container>
  );
};

export default SocialInteraction;
