import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '69.35',
  height = '72.13',
  className = '',
  viewBox = '0 0 69.35 72.13'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M62.477,15.529c-5.564-5.352-13.256-6.09-21.649-2.091-.822.39-1.593.789-2.312,1.156-.78.4-1.537.794-2.2,1.086-.371-4.22-2.086-11.7-9.3-15.484A1.739,1.739,0,0,0,25.4,3.278c5.554,2.909,7.044,8.731,7.42,12.3-.611-.277-1.288-.625-1.983-.982-.724-.371-1.49-.771-2.312-1.161-8.388-3.99-16.052-3.266-21.607,2.039C-.13,22.211-2.071,35.265,2.4,45.847c1.969,4.666,4.774,10.676,8.994,15.789a29.857,29.857,0,0,0,23.3,10.489h.226c9.3-.066,17.721-3.943,23.1-10.648,3.257-4.06,6.006-8.9,8.914-15.672C71.429,35.312,69.516,22.3,62.477,15.529Zm1.255,28.9A62.331,62.331,0,0,1,55.3,59.3c-4.718,5.883-12.161,9.29-20.413,9.347a26.147,26.147,0,0,1-20.822-9.22A59.612,59.612,0,0,1,5.594,44.494c-3.938-9.318-2.34-20.714,3.717-26.5a12.66,12.66,0,0,1,9-3.628,20.4,20.4,0,0,1,8.708,2.218c.789.371,1.523.752,2.213,1.109,2.105,1.086,3.773,1.941,5.39,1.96.009,0,.019,0,.033,0h.033c1.626-.009,3.3-.869,5.418-1.964.691-.352,1.424-.733,2.209-1.109,7.105-3.383,13.238-2.876,17.744,1.457C66.114,23.855,67.693,35.2,63.732,44.433Z"
    />
    <path
      fill={fill}
      d="M94.373,69.906a1.744,1.744,0,0,0-1.626,1.847,18.428,18.428,0,0,1-1.419,8.36A42.8,42.8,0,0,1,85.553,90.3a17.541,17.541,0,0,1-13.839,6.283,1.739,1.739,0,0,0,0,3.477h0a21.01,21.01,0,0,0,16.546-7.584,48.122,48.122,0,0,0,6.255-10.991,21.9,21.9,0,0,0,1.692-9.948A1.737,1.737,0,0,0,94.373,69.906Z"
    />
  </svg>
);

export default SVG;
