import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '36.3',
  height = '37.91',
  className = '',
  viewBox = '0 0 36.3 37.91'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M23.491,10.74H12.578a2.491,2.491,0,0,0-2.488,2.478V35.3a2.493,2.493,0,0,0,2.488,2.488H23.491A2.493,2.493,0,0,0,25.979,35.3V13.228A2.486,2.486,0,0,0,23.491,10.74Zm1.163,3.742V31.443H11.425V14.482ZM12.578,12.065H23.491a1.152,1.152,0,0,1,1.143,1.092h-13.2A1.159,1.159,0,0,1,12.578,12.065Zm10.913,24.4H12.578a1.155,1.155,0,0,1-1.153-1.163V32.768H24.654v2.539A1.17,1.17,0,0,1,23.491,36.469Z"
      transform="translate(0.115 0.122)"
      fill={fill}
    />
    <path
      d="M19.154,33.66H16.788a.657.657,0,1,0,0,1.315h2.367a.657.657,0,1,0,0-1.315Z"
      transform="translate(0.183 0.383)"
      fill={fill}
    />
    <path
      d="M17.994,4.612A3.96,3.96,0,0,0,21.949.657a.668.668,0,0,0-1.335,0,2.63,2.63,0,1,1-5.259,0A.641.641,0,0,0,14.708,0a.665.665,0,0,0-.668.657A3.954,3.954,0,0,0,17.994,4.612Z"
      transform="translate(0.16 0)"
      fill={fill}
    />
    <path
      d="M18.048,9.345A8.693,8.693,0,0,0,26.735.657a.668.668,0,0,0-1.335,0,7.363,7.363,0,1,1-14.726,0A.658.658,0,0,0,10.007,0,.641.641,0,0,0,9.36.657,8.693,8.693,0,0,0,18.048,9.345Z"
      transform="translate(0.106 0)"
      fill={fill}
    />
    <path
      d="M35.331,0a.665.665,0,0,0-.668.657A16.837,16.837,0,0,1,27.23,14.614v1.547A18.136,18.136,0,0,0,35.988.657.65.65,0,0,0,35.331,0Z"
      transform="translate(0.31 0)"
      fill={fill}
    />
    <path
      d="M1.325.657A.658.658,0,0,0,.657,0,.65.65,0,0,0,0,.657a18.149,18.149,0,0,0,8.758,15.5V14.614A16.823,16.823,0,0,1,1.325.657Z"
      transform="translate(0 0)"
      fill={fill}
    />
    <path
      d="M6,.657A.658.658,0,0,0,5.337,0,.65.65,0,0,0,4.68.657,13.362,13.362,0,0,0,9.524,10.963a4.2,4.2,0,0,1,.971-.92A12.016,12.016,0,0,1,6,.657Z"
      transform="translate(0.053 0)"
      fill={fill}
    />
    <path
      d="M31.275.657A.658.658,0,0,0,30.608,0a.65.65,0,0,0-.657.657,12.031,12.031,0,0,1-4.49,9.386,3.813,3.813,0,0,1,.971.92A13.347,13.347,0,0,0,31.275.657Z"
      transform="translate(0.289 0)"
      fill={fill}
    />
  </svg>
);

export default SVG;
