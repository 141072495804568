import React, { useContext } from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
import Icon from '../../components/Icon';
// Context
import configContext from '../../contexts/config/configStore';
// Style
import { BGImage,Body,Header,StyledProgress,Title } from './Intro.styles';

const Intro = () => {
  const { configState } = useContext(configContext);

  return(
    <Page>
      <Progress visible goTo={'/'} navDirection={'prev'} text={'Back'} />
      <Header>
        {(configState.brandName !== "audibel" && configState.brandName !== "nuear") &&
          <Icon name="starkey" width="7rem" height="4.5rem" />
        }
        {configState.brandName === "audibel" &&
          <Icon name="audibel" width="7rem" height="4.5rem" />
        }
        {configState.brandName === "nuear" &&
          <Icon name="nuear" width="7rem" height="4.5rem" />
        }
      </Header>
      <Body>
        <BGImage>
          <img src="/images/intro/intro.jpg" alt="intro" />
        </BGImage>
        <Title>
          <Icon name="care365" width="10.2rem" height="2.8rem" fill="#ffffff" /><br />
          Your Better Hearing Journey
        </Title>
      </Body>
      <StyledProgress
        goTo={'/todays-visit'}
        height="5rem"
        icon="arrowDown"
        navDirection={'next'}
        position={'left'}
        text={''}
        width='2.5rem'
        visible color={'#fff'}
      />
    </Page>
  );
};

export default Intro;
