import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Picker } from '../../components/Picker';
import { Progress } from '../../components/Progress';
// Hooks
import { useStepsComplete } from '../../hooks/useStepsComplete';

const SelfDescriptor = () => {
  const complete = useStepsComplete()[0];

  return(
    <Page>
      <Progress visible goTo={'/todays-visit'} navDirection={'prev'} text={'Back'} />
      <Picker question={0}>
        1. Please select the top 3 options<br />that best <span>describe you</span>.
      </Picker>
      <Progress
        visible={complete}
        goTo={'/favorite-activities'}
        navDirection={'next'}
        text={'Select'}
      />
    </Page>
  );
};

export default SelfDescriptor;
