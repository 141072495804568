import React from 'react';

const SVG = ({
  style = {},
  fill = '#4a4a4a',
  stroke = '#f3f4f4',
  width = '35',
  height = '29.13',
  className = '',
  viewBox = '0 0 35 29.13'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(3.536 3.534)" fill={fill} strokeMiterlimit="10">
      <path
        d="M 9.120539665222168 25.90115737915039 L 8.23744010925293 25.01801681518555 L -0.8839100003242493 15.89621734619141 L -1.767809987068176 15.01227760314941 L -0.8838199973106384 14.12841796875 L 2.408979892730713 10.83606719970703 L 3.292829990386963 9.952337265014648 L 4.176650047302246 10.83608722686768 L 9.122269630432129 15.781325340271 L 25.82427024841309 -0.8848323822021484 L 26.70815086364746 -1.766822338104248 L 27.59107971191406 -0.8838823437690735 L 30.88388061523438 2.408917665481567 L 31.7685604095459 3.293587684631348 L 30.88308906555176 4.177467823028564 L 10.00444030761719 25.01882743835449 L 9.120539665222168 25.90115737915039 Z"
        stroke="none"
      />
      <path
        d="M 26.70719909667969 -1.9073486328125e-06 L 9.121349334716797 17.5481071472168 L 3.292800903320312 11.72000789642334 L 0 15.01235771179199 L 9.121349334716797 24.13415718078613 L 30 3.292797088623047 L 26.70719909667969 -1.9073486328125e-06 M 26.7091007232666 -3.533641815185547 L 28.4749698638916 -1.767772674560547 L 31.76777076721191 1.525028228759766 L 33.537109375 3.294378280639648 L 31.76618957519531 5.06214714050293 L 10.88754081726074 25.90350723266602 L 9.119729995727539 27.66815757751465 L 7.353540420532227 25.90187835693359 L -1.767810821533203 16.78007698059082 L -3.535610198974609 15.01219749450684 L -1.767650604248047 13.24446773529053 L 1.525150299072266 9.952117919921875 L 3.292850494384766 8.184667587280273 L 5.06049919128418 9.952167510986328 L 9.123184204101562 14.0145378112793 L 24.94132995605469 -1.769662857055664 L 26.7091007232666 -3.533641815185547 Z"
        stroke="none"
        fill={stroke}
      />
    </g>
    ;
  </svg>
);

export default SVG;
