import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Picker } from '../../components/Picker';
import { Progress } from '../../components/Progress';
// Hooks
import { useStepsComplete } from '../../hooks/useStepsComplete';

const FavoriteActivities = () => {
  const complete = useStepsComplete()[1];

  return(
    <Page>
      <Progress visible goTo={'/self-descriptor'} navDirection={'prev'} text={'Back'} />
      <Picker question={1}>
        2. Please select the top 3 options<br />that best describe your <span>favorite activites</span>.
      </Picker>
      <Progress
        visible={complete}
        goTo={'/wellness-concerns'}
        navDirection={'next'}
        text={'Select'}
      />
    </Page>
  );
};

export default FavoriteActivities;
