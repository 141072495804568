import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Style
import { Image,Title } from './TodaysVisit.styles';

const TodaysVisit = () => {

  return(
    <Page>
      <Progress visible goTo={'/intro'} navDirection={'prev'} text={'Back'} />
      <Image src="/images/todays-visit/doctor-patient-conversation-sitting.svg" alt="doctor patient conversation while sitting" />
      <Title>Today's visit</Title>
      <Progress visible goTo={'/self-descriptor'} navDirection={'next'} text={'Next'} />
    </Page>
  );
};

export default TodaysVisit;
