import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '30.61',
  height = '32.93',
  className = '',
  viewBox = '0 0 30.61 32.93'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(2.795 6.449)">
      <g>
        <path
          d="M15.262,6.965H3.128a.787.787,0,0,1-.788-.788A.777.777,0,0,1,3.128,5.4H15.274a.782.782,0,1,1-.012,1.565Z"
          transform="translate(-2.34 -5.4)"
          fill={fill}
        />
        <path
          d="M15.262,9.656H3.128a.788.788,0,0,1,0-1.576H15.274a.788.788,0,1,1-.012,1.576Z"
          transform="translate(-2.34 -4.879)"
          fill={fill}
        />
        <path
          d="M12.778,12.275H3.128a.787.787,0,0,1-.788-.788.777.777,0,0,1,.788-.776H12.79a.782.782,0,1,1-.012,1.565Z"
          transform="translate(-2.34 -4.368)"
          fill={fill}
        />
      </g>
      <g transform="translate(16.887 8.802)">
        <g transform="translate(0 0)">
          <path
            d="M19.084,18.443a.787.787,0,0,1-.788-.788,2.471,2.471,0,0,1,1.063-1.971c.394-.346.525-.49.525-.8a.409.409,0,0,0-.191-.358A1.218,1.218,0,0,0,19,14.335c-.012,0-.943.012-.943.609a.788.788,0,1,1-1.576,0,2.282,2.282,0,0,1,2.508-2.174,2.754,2.754,0,0,1,1.6.478,1.943,1.943,0,0,1,.848,1.636,2.509,2.509,0,0,1-1.063,1.983c-.394.346-.525.478-.525.788A.76.76,0,0,1,19.084,18.443Z"
            transform="translate(-16.48 -12.77)"
            fill={fill}
          />
        </g>
        <ellipse
          cx="0.788"
          cy="0.788"
          rx="0.788"
          ry="0.788"
          transform="translate(1.827 6.151)"
          fill={fill}
        />
      </g>
    </g>
    <g transform="translate(0.788 3.201)">
      <g>
        <rect width="17.735" height="1.565" fill={fill} />
        <rect
          width="17.735"
          height="1.565"
          transform="translate(0 22.238)"
          fill={fill}
        />
      </g>
      <path
        d="M9.833,25.313H7.038a.776.776,0,1,1,0-1.553H9.833a.776.776,0,1,1,0,1.553Z"
        transform="translate(0.426 1.416)"
        fill={fill}
      />
    </g>
    <g>
      <path
        d="M17.735,25.474V30a1.371,1.371,0,0,1-1.361,1.373H2.938A1.364,1.364,0,0,1,1.576,30V2.926A1.371,1.371,0,0,1,2.938,1.553H16.374a1.364,1.364,0,0,1,1.361,1.373v9.7a8.729,8.729,0,0,1,1.565-.681V2.926A2.933,2.933,0,0,0,16.362,0H2.938A2.933,2.933,0,0,0,0,2.926V29.989a2.944,2.944,0,0,0,2.938,2.938H16.374a2.944,2.944,0,0,0,2.938-2.938V26.155A8.485,8.485,0,0,1,17.735,25.474Z"
        fill={fill}
      />
    </g>
    <path
      d="M25.736,26.5a.886.886,0,0,1-.3-.06,11.834,11.834,0,0,1-3.284-1.947,9.1,9.1,0,0,1-2.209.263c-4.658,0-8.444-3.392-8.444-7.572S15.286,9.61,19.944,9.61,28.375,13,28.375,17.182a7.106,7.106,0,0,1-2.293,5.183l.406,3.237a.787.787,0,0,1-.3.717A.641.641,0,0,1,25.736,26.5Zm-3.368-3.655a.79.79,0,0,1,.537.215A9.132,9.132,0,0,0,24.781,24.4l-.287-2.233a.79.79,0,0,1,.263-.693,5.647,5.647,0,0,0,2.066-4.288c0-3.308-3.081-6.007-6.867-6.007s-6.867,2.687-6.867,6.007,3.081,6.007,6.867,6.007a7.943,7.943,0,0,0,2.2-.311A.625.625,0,0,1,22.368,22.843Z"
      transform="translate(2.234 1.867)"
      fill={fill}
    />
  </svg>
);

export default SVG;
