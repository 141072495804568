import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '25',
  height = '25',
  className = '',
  viewBox = '0 0 25 25'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M24.5899,15.431l-6.843-5.5a.42365.42365,0,0,0-.689.33v2.545H7.4789a.423.423,0,0,0-.423.423l0,.001v2.088l-5.743-4.614,5.743-4.613V8.176a.423.423,0,0,0,.423.423h9.851a.4235.4235,0,0,0,.001-.847H7.9029V5.207a.42365.42365,0,0,0-.689-.33l-6.843,5.5a.42465.42465,0,0,0,0,.661l6.843,5.5a.426.426,0,0,0,.265.093.418.418,0,0,0,.183-.041.425.425,0,0,0,.241-.382V13.656h9.579a.423.423,0,0,0,.423-.423V11.148l5.743,4.613-5.743,4.614V18.29a.42336.42336,0,0,0-.424-.424H7.6299a.423.423,0,0,0-.423.423l0,.001a.423.423,0,0,0,.422.424h9.428v2.545a.425.425,0,0,0,.241.382.42594.42594,0,0,0,.448-.052l6.844-5.497a.42466.42466,0,0,0,0-.661Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
