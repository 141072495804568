import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '25.61',
  height = '27.57',
  className = '',
  viewBox = '0 0 30.61 32.93'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <g>
          <path
            d="M468.55,276.63H458.39a.66.66,0,0,1-.66-.65.67.67,0,0,1,.66-.66h10.16a.66.66,0,0,1,.66.66A.65.65,0,0,1,468.55,276.63Z"
            transform="translate(-455.39 -269.92)"
          />
          <path
            d="M468.55,279.32H458.39a.66.66,0,1,1,0-1.31h10.16a.66.66,0,1,1,0,1.31Z"
            transform="translate(-455.39 -269.92)"
          />
          <path
            d="M466.47,281.94h-8.08a.66.66,0,0,1-.66-.65.67.67,0,0,1,.66-.66h8.08a.66.66,0,0,1,.66.66A.65.65,0,0,1,466.47,281.94Z"
            transform="translate(-455.39 -269.92)"
          />
        </g>
        <g>
          <path
            d="M474.05,287.45a.67.67,0,0,1-.66-.66,2.06,2.06,0,0,1,.89-1.64c.34-.3.45-.41.45-.67a.34.34,0,0,0-.16-.3A1,1,0,0,0,474,284s-.78,0-.78.51a.67.67,0,0,1-.66.66.66.66,0,0,1-.65-.66,1.91,1.91,0,0,1,2.09-1.82,2.31,2.31,0,0,1,1.34.4,1.67,1.67,0,0,1,.71,1.37,2,2,0,0,1-.9,1.65c-.33.29-.44.41-.44.66A.66.66,0,0,1,474.05,287.45Z"
            transform="translate(-455.39 -269.92)"
          />
          <circle cx="18.67" cy="18.58" r="0.66" />
        </g>
      </g>
      <g>
        <g>
          <rect x="0.66" y="2.68" width="14.85" height="1.31" />
          <rect x="0.66" y="21.3" width="14.85" height="1.31" />
        </g>
        <path
          d="M464.64,295H462.3a.66.66,0,1,1,0-1.31h2.34a.66.66,0,1,1,0,1.31Z"
          transform="translate(-455.39 -269.92)"
        />
      </g>
      <path
        d="M470.24,291.25V295a1.15,1.15,0,0,1-1.14,1.15H457.85A1.15,1.15,0,0,1,456.7,295V272.38a1.15,1.15,0,0,1,1.15-1.15H469.1a1.15,1.15,0,0,1,1.14,1.15v8.12a7.31,7.31,0,0,1,1.31-.57v-7.55a2.46,2.46,0,0,0-2.45-2.46H457.85a2.46,2.46,0,0,0-2.46,2.46V295a2.46,2.46,0,0,0,2.46,2.46H469.1a2.46,2.46,0,0,0,2.45-2.46v-3.22A7.31,7.31,0,0,1,470.24,291.25Z"
        transform="translate(-455.39 -269.92)"
      />
      <path
        d="M478.81,293.67a.7.7,0,0,1-.25-.05,9.71,9.71,0,0,1-2.74-1.63,7.67,7.67,0,0,1-1.85.22c-3.9,0-7.07-2.84-7.07-6.33s3.17-6.34,7.07-6.34,7.06,2.84,7.06,6.34a6,6,0,0,1-1.92,4.34l.35,2.71a.68.68,0,0,1-.25.6A.67.67,0,0,1,478.81,293.67ZM476,290.61a.71.71,0,0,1,.45.18,7.38,7.38,0,0,0,1.57,1.12l-.24-1.87a.65.65,0,0,1,.22-.57,4.7,4.7,0,0,0,1.73-3.59c0-2.78-2.58-5-5.75-5s-5.76,2.25-5.76,5,2.58,5,5.76,5a6.28,6.28,0,0,0,1.83-.26A.63.63,0,0,1,476,290.61Z"
        transform="translate(-455.39 -269.92)"
      />
    </g>
  </svg>
);

export default SVG;
