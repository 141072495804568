export const FEATURES_DATA = [
  {
    name: "Translate",
    emphasis: [false,'ZZZ'],
    circles: [100,0,0],
    icon: "translate",
    description: "Communicate with people who speak other languages via a translation tool in the Thrive app."
  },
  {
    name: "Edge Mode",
    emphasis: [false,'ZZZ'],
    circles: [100,40,0],
    icon: "edgeMode",
    description: "Conducts an AI-based analysis of the environment and makes smart and immediate adjustments."
  },
  {
    name: "Tap Control",
    emphasis: [true,'TC'],
    circles: [100,40,40],
    icon: "tap",
    description: "Enables you to easily stop or start audio streaming by simply tapping your ear twice."
  },
  {
    name: "Multiflex Tinnitus Pro",
    emphasis: [false,'ZZZ'],
    circles: [100,40,40],
    icon: "noIcon",
    description: "Creates a customizable and comforting sound stimulus that the patient and hearing professional can fine-tune to soothe the unique, irritating sounds being heard and take the focus off of it."
  },
  {
    name: "Transcribe",
    emphasis: [true,'TR'],
    circles: [100,100,0],
    icon: "transcribe",
    description: "Conversations are transcribed into text that can be saved or shared."
  },
  {
    name: "Thrive Assistant",
    emphasis: [false,'ZZZ'],
    circles: [100,100,0],
    icon: "thrive",
    description: "Provides quick answers to questions like, “how do I adjust volume?” or “what’s the weather?”"
  },
  {
    name: "Find My Phone",
    emphasis: [false,'ZZZ'],
    circles: [100,100,0],
    icon: "findMyPhone",
    description: "Use your hearing aids and Thrive Assistant to locate your lost or misplaced phone by having it ring."
  },
  {
    name: "Activity",
    emphasis: [true,'BOT'],
    circles: [100,100,100],
    icon: "bodyActivity",
    description: "Tracks your daily steps, measures movement and monitors more vigorous physical activity."
  },
  {
    name: "Engagement",
    emphasis: [true,'BRT'],
    circles: [100,100,100],
    icon: "brainHealth",
    description: "Monitors the brain-health benefits of wearing hearing aids by tracking hearing aid use and interaction."
  },
  {
    name: "Self Check",
    emphasis: [false,'ZZZ'],
    circles: [100,100,100],
    icon: "selfCheck",
    description: "Provides a quick, convenient way for you to analyze your hearing aid system’s performance."
  },
  {
    name: "Reminders",
    emphasis: [false,'ZZZ'],
    circles: [100,100,0],
    icon: "reminders",
    description: "Easily set up reminders for appointments, taking medications, prescription refills or other personal tasks."
  },
  {
    name: "Auto On/Off",
    emphasis: [false,'ZZZ'],
    circles: [100,100,100],
    icon: "autoOnOff",
    description: "Automatically and conveniently save battery power when your hearing aids are not in use."
  },
  {
    name: "Rechargeable",
    emphasis: [false,'ZZZ'],
    circles: [100,100,100],
    icon: "bolt",
    description: "Long-lasting charge delivers all-day hearing."
  },
  {
    name: "TeleHear",
    emphasis: [false,'ZZZ'],
    circles: [100,100,100],
    icon: "telehear",
    description: "Request remote adjustments via video chat from anywhere."
  },
  {
    name: "Fall Detection and Alerts",
    emphasis: [true,'FD'],
    circles: [100,0,0],
    icon: "fallDetection",
    description: "Detects when a hearing aid wearer falls and sends an alert message to selected contacts."
  }
];