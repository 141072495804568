import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Picker } from '../../components/Picker';
import { Progress } from '../../components/Progress';
// Hooks
import { useStepsComplete } from '../../hooks/useStepsComplete';

const WellnessConcerns = () => {
  const complete = useStepsComplete()[2];

  return(
    <Page>
      <Progress visible goTo={'/favorite-activities'} navDirection={'prev'} text={'Back'} />
      <Picker question={2}>
        3. Please select the top 3 options that represent<br />your <span>concerns</span> about your <span>future well-being</span>.
      </Picker>
      <Progress
        visible={complete}
        goTo={'/healthy-living-goals'}
        navDirection={'next'}
        text={'Select'}
      />
    </Page>
  );
};

export default WellnessConcerns;
