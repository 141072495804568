import React from 'react';

const SVG = ({
  style = {},
  fill = '#506162',
  width = '76.32898',
  height = '76.32715',
  className = '',
  viewBox = '0 0 76.32898 76.32715'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M68.974,22.40405H53.921V7.354A6.736,6.736,0,0,0,47.191.625H29.134a6.736,6.736,0,0,0-6.73,6.729V22.40405H7.355a6.737,6.737,0,0,0-6.73,6.729v18.057a6.737,6.737,0,0,0,6.73,6.729H22.404v15.0531a6.737,6.737,0,0,0,6.73,6.73H47.191a6.737,6.737,0,0,0,6.729-6.733V53.91907H68.974a6.737,6.737,0,0,0,6.73-6.729v-18.057A6.736,6.736,0,0,0,68.974,22.40405Zm4.254,24.79016a4.258,4.258,0,0,1-4.254,4.25306H51.444v17.526a4.258,4.258,0,0,1-4.253,4.25305H29.134a4.258,4.258,0,0,1-4.254-4.25305v-17.526H7.355a4.258,4.258,0,0,1-4.254-4.25306v-18.057a4.258,4.258,0,0,1,4.254-4.253h17.525V7.35815a4.258,4.258,0,0,1,4.254-4.253H47.191a4.258,4.258,0,0,1,4.253,4.25305v17.526h17.53a4.258,4.258,0,0,1,4.254,4.253Z"
      fill={fill}
      stroke={fill}
      strokeWidth={1.25}
    />
  </svg>
);

export default SVG;
