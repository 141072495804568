import React, { useEffect } from 'react'
import { useHistory,useLocation } from 'react-router-dom';
import { useMsal,useIsAuthenticated } from "@azure/msal-react";
// Data
import { UNAUTHED_ROUTES_DATA as data } from '../../data/unauthedRoutesData';
// Style
import { Container } from './Page.styles';

const Page = ({ children,className='' }) => {
  const { accounts,inProgress } = useMsal();
  const history = useHistory();
  const { pathname } = useLocation();
  const isAuthed = useIsAuthenticated();

  useEffect(() => {

    // If MSAL is done doing things
    if(inProgress === 'none') {

      // If
        // user is not authenticated
        // and
        // the route is protected
      if(!isAuthed && !data.includes(pathname)) {
        history.push('/login');
      }

      // If an account exists
      if(accounts.length) {
        // If
          // the user is authenticated
          // and
          // the user does not have Care365 access
        if(isAuthed && !accounts[0].idTokenClaims.care365Access) {
          history.push('/noAccess');
        }
      }
    }
  },[accounts,history,inProgress,isAuthed,pathname]);
  
  if(
      (isAuthed && accounts[0].idTokenClaims.care365Access) ||
      data.includes(pathname)
    ) {
    return(
      <Container className={`page ${className}`}>{children}</Container>
    )
  } else {
    return null;
  }
}

export default Page