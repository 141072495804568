import React, { useState } from 'react';
// Functions
import parse from 'html-react-parser';
// Data
import { AUDIOGRAM_DATA as data } from '../../../../../../data/audiogramData';
// Style
import { AudiogramPane,BlurText,BlurToggle,BlurToggleHalf,ClearText,Container,Control,ControlImg,Gram,Panes,TextPane,TextPaneWrapper,TextWrapper } from './AudiogramSimulation.styles';

const AudiogramSimulation = ({ active,bananaToggled,iconsToggled,paneToggled,setActive }) => {
  const [lossToggled, setLossToggled] = useState(false);
  const max = data.length-1;

  const switchLevel = (dir) => {
    if(dir==='next') {
      if(active >= max) {
        return;
      } else {
        setActive(active+1);
      }
    } else if(dir==='prev') {
      if(active <= 0) {
        return;
      } else {
        setActive(active-1);
      }
    } else {
      return;
    }
  }

  return(
    <Container>
      <Control onClick={() => switchLevel('prev')} hide={active <= 0}>
        <ControlImg src="/images/controls/audiogram/prev-control.svg" alt="previous" />
      </Control>
      <Panes>
        <AudiogramPane hidden={paneToggled}>
          <Gram
            src={`/images/audiogram/${data[active].slug}/base.jpg`}
            alt="audiogram base"
            layer="base"
            show
          />
          <Gram
            src={`/images/audiogram/${data[active].slug}/banana.jpg`}
            alt="audiogram with speech spectrum"
            layer="banana"
            show={bananaToggled}
          />
          <Gram
            src={`/images/audiogram/${data[active].slug}/icons.jpg`}
            alt="audiogram with common sounds"
            layer="icons"
            show={iconsToggled}
          />
          <Gram
            src={`/images/audiogram/${data[active].slug}/full.jpg`}
            alt="audiogram with common sounds and speech spectrum"
            layer="full"
            show={bananaToggled && iconsToggled}
          />
        </AudiogramPane>
        <TextPane hidden={!paneToggled}>
          <TextPaneWrapper>
            <TextWrapper>
              <BlurText hidden={!lossToggled}>{parse(data[active].exampleText)}</BlurText>
              <ClearText>{parse(data[active].exampleText)}</ClearText>
            </TextWrapper>
            <BlurToggle onClick={() => setLossToggled(!lossToggled)}>
              <BlurToggleHalf active={!lossToggled}>Normal Hearing</BlurToggleHalf>
              <BlurToggleHalf active={lossToggled}>Sounds you may be missing</BlurToggleHalf>
            </BlurToggle>
          </TextPaneWrapper>
        </TextPane>
      </Panes>
      <Control onClick={() => switchLevel('next')} hide={active >= max}>
        <ControlImg src="/images/controls/audiogram/next-control.svg" alt="next" />
      </Control>
    </Container>
  );
};

export default AudiogramSimulation;
