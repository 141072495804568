import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '69.37',
  height = '91.32',
  className = '',
  viewBox = '0 0 69.37 91.32'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-41.691 -24.66)">
      <g transform="translate(42.64 113.613)">
        <path
          d="M11.427.505H-40.954A1.184,1.184,0,0,1-42.137-.678a1.185,1.185,0,0,1,1.183-1.185H11.427A1.185,1.185,0,0,1,12.61-.678,1.184,1.184,0,0,1,11.427.505"
          transform="translate(42.137 1.863)"
          fill={fill}
        />
      </g>
      <g transform="translate(51.867 90.931)">
        <path
          d="M7.49,5.126A1.184,1.184,0,0,1,6.307,3.942V-16.042a.5.5,0,0,0-.492-.492H-24.761a.5.5,0,0,0-.492.492V3.942a1.184,1.184,0,0,1-1.183,1.183A1.184,1.184,0,0,1-27.62,3.942V-16.042A2.861,2.861,0,0,1-24.761-18.9H5.815a2.862,2.862,0,0,1,2.859,2.859V3.942A1.184,1.184,0,0,1,7.49,5.126"
          transform="translate(27.62 18.901)"
          fill={fill}
        />
      </g>
      <g transform="translate(59.948 58.119)">
        <path
          d="M2.185,5.185c-3.2-.158-6.247-2.925-7.617-6.721A2.221,2.221,0,0,1-6.6-1.965a2.3,2.3,0,0,1-.923-1.529l-.51-3.413A2.339,2.339,0,0,1-6.969-9.229a7.266,7.266,0,0,1-.057-.9v-1.257a6.684,6.684,0,0,1,6.38-6.774,2.127,2.127,0,0,1,.853-.666c1.187-.533,3.188-.343,5.358.51a13.44,13.44,0,0,1,1.555.731,6.733,6.733,0,0,1,3.923,6.2v1.257a7.01,7.01,0,0,1-.057.9,2.331,2.331,0,0,1,1.064,2.32l-.51,3.416a2.3,2.3,0,0,1-.924,1.531,2.238,2.238,0,0,1-.923.4C8.329,2.242,5.282,5.028,2.185,5.185M-4.594-4a1.187,1.187,0,0,1,1.142.872C-2.559.147-.135,2.7,2.185,2.821,4.373,2.7,6.793.161,7.695-3.093a1.189,1.189,0,0,1,1.328-.853l.3.048.388-3.361-.294,0A1.19,1.19,0,0,1,8.6-7.805a1.185,1.185,0,0,1-.125-.972,4.6,4.6,0,0,0,.2-1.347v-1.257a4.416,4.416,0,0,0-2.6-4.079A12.023,12.023,0,0,0,4.7-16.11a6.915,6.915,0,0,0-3.371-.6,1.165,1.165,0,0,1-.287.544,1.181,1.181,0,0,1-.867.379H-.47a4.307,4.307,0,0,0-4.189,4.408v1.257a4.6,4.6,0,0,0,.2,1.347,1.185,1.185,0,0,1-.125.972,1.191,1.191,0,0,1-.817.544l-.37.06.59,3.357.4-.137A1.189,1.189,0,0,1-4.594-4M1.351-17.062v0Z"
          transform="translate(8.058 19.12)"
          fill={fill}
        />
      </g>
      <g transform="translate(41.691 81.911)">
        <path
          d="M2.028,7.154a1.173,1.173,0,0,1-.769-.285L-5.556,1.043c-1.175-1-2.569-3.264-1.6-5.383l7.2-15.638A6.312,6.312,0,0,1,4.81-23.3c3.4-.716,7.658-1.912,8.959-2.851a1.189,1.189,0,0,1,.507-.208,1.236,1.236,0,0,1,1.414.975,1.142,1.142,0,0,1-.76,1.306c-2.3,1.477-7.919,2.736-9.633,3.1a4.028,4.028,0,0,0-3.093,2L-5-3.349c-.379.821.327,2.034.981,2.592L2.8,5.07a1.184,1.184,0,0,1-.769,2.083"
          transform="translate(7.479 26.379)"
          fill={fill}
        />
      </g>
      <g transform="translate(75.156 81.894)">
        <path
          d="M2.918,7.157a1.184,1.184,0,0,1-.769-2.083L8.963-.752c.653-.559,1.359-1.773.98-2.594l-7.2-15.638a4.029,4.029,0,0,0-3.092-2c-1.748-.366-7.565-1.669-9.774-3.187a1.208,1.208,0,0,1-.62-1.214,1.139,1.139,0,0,1,1.3-.993l.118.018a1.2,1.2,0,0,1,.507.208c1.306.942,5.559,2.138,8.961,2.851a6.307,6.307,0,0,1,4.756,3.325L12.1-4.337c.974,2.118-.419,4.379-1.594,5.383L3.687,6.873a1.173,1.173,0,0,1-.769.285"
          transform="translate(10.758 26.393)"
          fill={fill}
        />
      </g>
      <g transform="translate(66.014 68.07)">
        <path
          d="M.252.686A1.184,1.184,0,0,1-.931-.5v-.847A1.184,1.184,0,0,1,.252-2.529,1.184,1.184,0,0,1,1.436-1.344V-.5A1.184,1.184,0,0,1,.252.686"
          transform="translate(0.931 2.529)"
          fill={fill}
        />
      </g>
      <g transform="translate(71.489 68.07)">
        <path
          d="M.252.686A1.184,1.184,0,0,1-.931-.5v-.847A1.184,1.184,0,0,1,.252-2.529,1.184,1.184,0,0,1,1.436-1.344V-.5A1.184,1.184,0,0,1,.252.686"
          transform="translate(0.931 2.529)"
          fill={fill}
        />
      </g>
      <g transform="translate(67.266 74.123)">
        <path
          d="M.557.619A3.235,3.235,0,0,1-1.69-.242a1.184,1.184,0,0,1-.037-1.673A1.18,1.18,0,0,1-.053-1.953a1.193,1.193,0,0,0,1.42-.074,1.184,1.184,0,0,1,1.663.2,1.183,1.183,0,0,1-.2,1.663A3.694,3.694,0,0,1,.557.619"
          transform="translate(2.055 2.282)"
          fill={fill}
        />
      </g>
      <g transform="translate(61.015 62.451)">
        <path
          d="M3.545,1.488A1.185,1.185,0,0,1,2.382.515,6.476,6.476,0,0,0-.353-2.938c-2.15,2-4.78,1.7-6.74,1.475-2.42-.275-3.215-.278-3.642,1.556a1.193,1.193,0,0,1-1.421.885A1.184,1.184,0,0,1-13.04-.445c.928-3.97,3.983-3.623,6.215-3.37,2.048.234,3.816.433,5.236-1.252a1.176,1.176,0,0,1,1.15-.4c2.793.6,4.867,3.984,5.151,5.556a1.185,1.185,0,0,1-.956,1.377,1.326,1.326,0,0,1-.211.018"
          transform="translate(13.072 5.489)"
          fill={fill}
        />
      </g>
      <g transform="translate(62.427 24.66)">
        <path
          d="M5.692,7.2a.993.993,0,0,1-.473-.119A21.438,21.438,0,0,1-.313,3.373a17.079,17.079,0,0,1-4.506.6c-8.916,0-16.169-6.848-16.169-15.264S-13.736-26.552-4.82-26.552s16.168,6.847,16.168,15.263A14.7,14.7,0,0,1,6.719-.6l-.028,6.8a1,1,0,0,1-1,.994M-.033,1.253a1,1,0,0,1,.7.29A15.456,15.456,0,0,0,4.7,4.523l.023-5.559A1,1,0,0,1,5.05-1.77a12.765,12.765,0,0,0,4.3-9.519c0-7.314-6.357-13.266-14.171-13.266S-18.992-18.6-18.992-11.289-12.635,1.979-4.82,1.979A15.036,15.036,0,0,0-.331,1.3a.988.988,0,0,1,.3-.046"
          transform="translate(20.989 26.552)"
          fill={fill}
        />
      </g>
      <g transform="translate(74.962 32.047)">
        <path
          d="M.787,2.37a1,1,0,0,1-1-1,4.959,4.959,0,0,1,2.249-4.1C2.871-3.412,3.3-3.809,3.3-4.626A1.886,1.886,0,0,0,2.083-6.686,2.982,2.982,0,0,0-1.027-5.076a1,1,0,0,1-1.355.394,1,1,0,0,1-.394-1.356A4.988,4.988,0,0,1,2.58-8.619,3.777,3.777,0,0,1,5.3-4.626,4.265,4.265,0,0,1,3.307-1.184C2.424-.455,1.786.071,1.786,1.373a1,1,0,0,1-1,1"
          transform="translate(2.9 8.741)"
          fill={fill}
        />
      </g>
      <g transform="translate(77.623 45.183)">
        <path
          d="M.213.527a1,1,0,0,1-1-1c0-.2.011-.4.029-.581a1.008,1.008,0,0,1,1.1-.89,1,1,0,0,1,.891,1.1,3.471,3.471,0,0,0-.02.374,1,1,0,0,1-1,1"
          transform="translate(0.785 1.945)"
          fill={fill}
        />
      </g>
      <g transform="translate(97.858 47)">
        <path
          d="M.213,2.361A.837.837,0,0,1,.1,2.354a1,1,0,0,1-.882-1.1L.226-7.818a1,1,0,0,1,1.1-.882A1,1,0,0,1,2.21-7.6L1.2,1.472a1,1,0,0,1-.99.889"
          transform="translate(0.787 8.705)"
          fill={fill}
        />
      </g>
      <g transform="translate(97.55 60.082)">
        <path
          d="M.213.527a.542.542,0,0,1-.064,0A1,1,0,0,1-.785-.533a5.332,5.332,0,0,1,.067-.583A1,1,0,0,1,.438-1.929,1,1,0,0,1,1.251-.773,3.145,3.145,0,0,0,1.21-.41a1,1,0,0,1-1,.937"
          transform="translate(0.787 1.944)"
          fill={fill}
        />
      </g>
      <g transform="translate(78.726 53.165)">
        <path
          d="M1.207,4.158a1,1,0,0,1-1-1L.18-3.64A14.7,14.7,0,0,1-4.451-14.332a1,1,0,0,1,1-1,1,1,0,0,1,1,1,12.767,12.767,0,0,0,4.3,9.519,1,1,0,0,1,.327.735L2.2,3.155a1,1,0,0,1-.994,1Z"
          transform="translate(4.451 15.331)"
          fill={fill}
        />
      </g>
      <g transform="translate(83.386 38.901)">
        <path
          d="M.213,7.2A1,1,0,0,1-.261,5.322c2.986-1.606,3.852-2.147,5.5-3.779a1.009,1.009,0,0,1,1-.245,15.028,15.028,0,0,0,4.487.68c7.815,0,14.172-5.952,14.172-13.266S18.539-24.555,10.723-24.555a1,1,0,0,1-1-1,1,1,0,0,1,1-1c8.916,0,16.169,6.847,16.169,15.263S19.639,3.974,10.723,3.974a17.085,17.085,0,0,1-4.506-.6A21.457,21.457,0,0,1,.685,7.081.988.988,0,0,1,.213,7.2"
          transform="translate(0.786 26.552)"
          fill={fill}
        />
      </g>
      <g transform="translate(93.498 60.082)">
        <path
          d="M.213.527a.542.542,0,0,1-.064,0A1,1,0,0,1-.785-.533a5.332,5.332,0,0,1,.067-.583A1,1,0,0,1,.438-1.929,1,1,0,0,1,1.251-.773a3.443,3.443,0,0,0-.042.364,1,1,0,0,1-1,.937"
          transform="translate(0.787 1.944)"
          fill={fill}
        />
      </g>
      <g transform="translate(89.445 60.082)">
        <path
          d="M.213.527a.542.542,0,0,1-.064,0A1,1,0,0,1-.785-.533a5.332,5.332,0,0,1,.067-.583A1,1,0,0,1,.438-1.929,1,1,0,0,1,1.251-.773,3.145,3.145,0,0,0,1.21-.41a1,1,0,0,1-1,.937"
          transform="translate(0.787 1.944)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
