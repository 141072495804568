export const HEARING_LOSS_LEVELS_DATA = [
  {
    text: 'Normal',
    src: '/images/hearing-loss-levels/normal-hearing.svg',
    alt: 'normal hearing'
  },
  {
    text: 'Mild',
    src: '/images/hearing-loss-levels/mild-hearing-loss.svg',
    alt: 'mild hearing loss'
  },
  {
    text: 'Mild-High Frequency',
    src: '/images/hearing-loss-levels/mild-high-hearing-loss.svg',
    alt: 'mild-high frequency hearing loss'
  },
  {
    text: 'Steeply Sloping',
    src: '/images/hearing-loss-levels/steeply-sloping-hearing-loss.svg',
    alt: 'steeply sloping hearing loss'
  },
  {
    text: 'Mild-Moderate',
    src: '/images/hearing-loss-levels/mild-moderate-hearing-loss.svg',
    alt: 'mild-moderate hearing loss'
  },
  {
    text: 'Sloping Severe',
    src: '/images/hearing-loss-levels/sloping-severe-hearing-loss.svg',
    alt: 'sloping severe hearing loss'
  },
  {
    text: 'Moderate',
    src: '/images/hearing-loss-levels/moderate-hearing-loss.svg',
    alt: 'moderate hearing loss'
  },
  {
    text: 'Moderately Severe',
    src: '/images/hearing-loss-levels/moderately-severe-hearing-loss.svg',
    alt: 'moderately severe hearing loss'
  },
  {
    text: 'Profound',
    src: '/images/hearing-loss-levels/profound-hearing-loss.svg',
    alt: 'profound hearing loss'
  }
];
