import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// Components
import Icon from '../../../../../../components/Icon';
// Functions
import parse from 'html-react-parser';
// Style
import {
  Container,
  Content,
  Description,
  Title
} from './FeatureCircle.styles';

const FeaturesCircle = ({ data }) => {

  return(
    <Container>
      <TransitionGroup className="transition-group">
        <CSSTransition
          classNames="content"
          key={data.name}
          timeout={250}
          unmountOnExit
        >
        <Content>
          {data.icon !== 'noIcon' &&
            <Icon
              fill={'#000'}
              height={'2.5rem'}
              name={data.icon}
              width={'2.5rem'}
            />
          }
          <Title>{data.name}</Title>
          <Description>{parse(data.description)}</Description>
        </Content>
      </CSSTransition>
    </TransitionGroup>
    </Container>
  );
};

export default FeaturesCircle;
