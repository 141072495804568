import styled from 'styled-components';
import { colors as c } from '../../../../../../styles/colors';

export const Container = styled.div`
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 11.3rem;
  margin-bottom: 1rem;

  ${({ model }) => model==='cic' && `
    padding: .8rem;
  `}
  
  img {
    width: 100%;
    height: auto;
  }
`;

export const Rotate360 = styled.p`
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: .3rem;
  text-align: center;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  margin-bottom: .3rem;
`;

export const Slider = styled.input`
  width: 15rem;
  -webkit-appearance: none;
  display: block;
  margin: 0 auto;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: "";
    display: inline-block;
    height: 8px;
    width: 8px;
    border-right: 2px solid ${c.grayish};
    border-bottom: 2px solid ${c.grayish};
  }

  &:before {
    transform: rotate(135deg) translate(-7px, -6px);
  }

  &:after {
    transform: rotate(-45deg) translate(-6px, -7px);
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background-color: ${c.grayish};
    border-radius: 0;
    border: 0;
  }

  &::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    margin-top: -11px;
    border: 0;
    border-radius: 24px;
    background-color: ${c.bluePrimary};
    cursor: pointer;
    -webkit-appearance: none;
  }

  &:focus::-webkit-slider-runnable-track {
    background: #d4d5d6;
  }

  &::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: ${c.grayish};
    border-radius: 0;
    border: 0;
  }

  &::-moz-range-thumb {
    height: 24px;
    width: 24px;
    border: 0;
    border-radius: 12px;
    background-color: ${c.bluePrimary};
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: #babbbc;
    border: 0;
  }

  &::-ms-fill-upper {
    background: ${c.grayish};
    border: 0;
  }

  &::-ms-thumb {
    height: 24px;
    width: 24px;
    border: 0;
    border-radius: 24px;
    background-color: ${c.bluePrimary};
    cursor: pointer;
  }

  &:focus::-ms-fill-lower {
    background-color: ${c.grayish};
  }

  &:focus::-ms-fill-upper {
    background-color: #d4d5d6;
  }
`;

export const Swatches = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 13.125rem;
  margin: 0 auto;
`;

export const Color = styled.label`
  display: block;
  cursor: pointer;
  margin: 0;
`;

export const ColorName = styled.span`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin: .0625rem;
  border: .3125rem solid #fff;
  border-radius: 50%;
  background-color: ${c.grayLight1};
  text-indent: -9999px;
  transition: border-color .2s ease;
  background-image: url('/images/hearing-aid-styles/colors/${({ color }) => color}.jpg');

  ${({ active }) => active && `
    border-color: #ddd;
  `}
`;

export const ColorInput = styled.input`
  display: none;
  margin: 0;
  padding: 0;
`;