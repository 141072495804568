import React from 'react';
// Data
import { HEARING_STYLES_DATA as data } from '../../../../data/hearingStylesData';
// Style
import { Container,Name,Description } from './BottomBar.styles';

const BottomBar = ({ activeStyle }) => {

  return(
    <Container>
      <Name>{data.styles[activeStyle].name}</Name>
      <Description>{data.styles[activeStyle].description}</Description>
    </Container>
  );
};

export default BottomBar;
