import styled from 'styled-components';
// Components
import { Page } from '../../components/Page';
// Style
import { colors as c } from '../../styles/colors';

export const StyledPage = styled(Page)`
  background-color: ${c.blueLight};
`;

export const Title = styled.h1`
  font-size: 3.82rem;
  color: ${c.bluePrimary};
  text-align: center;
  margin-top: 3rem;
`;

export const CloseModal = styled.button`
  position: absolute;
  bottom: .3rem;
  right: 1rem;
  width: 5rem;
  height: 5rem;
  font-family: Arial;
  font-size: 3rem;
  font-weight: bold;
  background-color: transparent;
  padding: 1.7rem;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  display: none;

  ${({ active }) => active && `
    display: flex;
  `}

  img {
    width: 100%;
    height: auto;
  }
`;

