import React, { useEffect,useState } from 'react';
// Data
import { HEARING_STYLES_DATA as data } from '../../../../data/hearingStylesData';
// Style
import { Container,Image } from './ModelZoom.styles';

const ModelZoom = ({
  activeModel,
  activeStyle,
  zoomed
}) => {
  const [canRotate, setCanRotate] = useState(false);
  const [rotateIndex, setRotateIndex] = useState(0);

  useEffect(() => {
    if(data.styles[activeStyle].models[activeModel].length > 1) {
      setCanRotate(true);
    } else {
      setCanRotate(false);
      setRotateIndex(0);
    }
  },[activeStyle,activeModel]);

  const toggleRotate = () => {
    if (canRotate) {
      setRotateIndex(rotateIndex === 1 ? 0 : 1);
    }
  }

  return(
    <Container>
      <Image
        src={data.styles[activeStyle].models[activeModel][rotateIndex]}
        alt="hearing aid model"
        onClick={toggleRotate}
        zoomed={zoomed}
      />
    </Container>
  );
};

export default ModelZoom;
