import styled, { css,keyframes } from 'styled-components';
import { colors as c } from '../../../../styles/colors';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(167, 168, 171, 1);
  }
  40% {
    box-shadow: 0 0 0 .9375rem rgba(208, 214, 216, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(28, 171, 228, 0);
  }
`;

export const Container = styled.div`
  position: relative;
  width: 95%;
`;

export const AnatomyWrapper = styled.div`
  width: 100%;
  height: 31.5rem;
`;

export const EarSections = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const EarSection = styled.h2`
  position: relative;
  margin: 0;
  padding: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'DIN Next';
  font-size: .7rem;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: .02rem;
  color: ${c.grayDark};
  text-transform: uppercase;

  &:not(:last-of-type) {

    :after {
      content: '';
      position: absolute;
      width: 0;
      height: 2rem;
      top: 50%;
      right: -1px;
      transform: translateY(-50%);
      border-right: 2px dotted #CCD0CF;
    }
  }

  ${({ center }) => center && `
    flex: 2.3;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 24.13rem;
`;

export const Image = styled.img`
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
`;

export const ModalPositioner = styled.div`
  position: absolute;
  opacity: 1;
  transition: opacity .2s linear;

  ${({ invisible }) => invisible && `
    opacity: 0;
  `}

  ${({ balance }) => balance && `
    bottom: 13.2rem;
    left: 42.6rem;
  `}

  ${({ tinnitus }) => tinnitus && `
    bottom: 8rem;
    left: 26.3rem;
  `}
`;

export const ModalButton = styled.button`
  z-index: 3;
  position: relative;
  width: 2.3125rem;
  height: 2.3125rem;
  border-radius: 50%;
  background-color: ${c.grayDark};
  border: 0;
  outline: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ active }) => !active && css`
    animation: ${pulse} 1.5s infinite ease-in-out;
  `}

  ${({ active }) => active && `
    background-color: ${c.bluePrimary};

    svg {
      transform: rotate(135deg);
    }
  `}

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 1rem;
    height: 1rem;
    transition: transform .2s linear;
  }
`;

export const Modal = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background-color: rgba(89,91,96,0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: all .2s linear;

  ${({ active }) => active && `
    bottom: -1.875rem;
    left: -1.875rem;
    width: 20.5rem;
    height: 20.5rem;
  `}
`;

export const ModalTitle = styled.h2`
  opacity: 0;
  text-align: center;
  font-size: 3.125rem;
  color: #fff;
  margin-bottom: .75rem;

  ${({ active }) => active && `
    opacity: 1;
    transition: opacity .2s .2s linear;
  `}
`;

export const ModalText = styled.p`
  opacity: 0;
  text-align: center;
  font-family: 'DIN Next';
  font-size: 1rem;
  color: #fff;

  ${({ active }) => active && `
    opacity: 1;
    transition: opacity .2s .2s linear;
  `}
`;

export const LabelPositioner = styled.div`
  position: absolute;
  z-index: 1;

  ${({ slug }) => slug === 'eardrum' && `
    bottom: 6rem;
    left: 6rem;
  `}

  ${({ slug }) => slug === 'auditoryNerve' && `
    bottom: 8rem;
    left: 16rem;
  `}
`;

export const Label = styled.img`
  position: absolute;
  z-index: 1;
  height: auto;

  ${({ slug }) => slug === "eardrum" && `
    width: 5.375rem;
    bottom: 3.6rem;
    left: 8.3rem;
  `}

  ${({ slug }) => slug === "auditory-nerve" && `
    width: 3.375rem;
    bottom: 3.2rem;
    left: 23.9rem;
  `}

  ${({ slug }) => slug === "cochlea" && `
    width: 3.375rem;
    bottom: 2.05rem;
    left: 37.85rem;
  `}

  ${({ slug }) => slug === "semi-circular-canals" && `
    width: 5.875rem;
    bottom: 15.75rem;
    left: 38.6rem;
  `}
`;
