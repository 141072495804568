import styled from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const SlideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 1.45rem;
  text-align: center;
  color: ${c.grayLight1};
  margin-bottom: 1rem;
`;

export const SubTitle = styled.h3`
  font-size: 3.375rem;
  text-align: center;
  color: ${c.bluePrimary};
  margin-bottom: 0;
`;

export const Description = styled.p`
  font-size: 1.45rem;
  text-align: center;
`;

export const Disclaimer = styled.p`
  font-size: .77rem;
  text-align: center;

  a {
    color: inherit;
    text-decoration: none;
  }
`;