import styled from 'styled-components';
import { Progress } from '../../components/Progress';

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  padding-left: 3rem;
`;

export const Body = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
`;

export const BGImage = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
`;

export const Title = styled.h1`
  position: relative;
  z-index: 2;
  font-size: 6.1875rem;
  line-height: 1;
  font-weight: 700;
  color: #fff;
  padding: 1.5rem 0 0 3.3rem;
  width: 39%;

  svg {
    vertical-align: middle;
  }
`;

export const StyledProgress = styled(Progress)`
  margin-left: 2.3rem;
  padding-bottom: 0;
`;
