import React from 'react';
// Style
import {
  Container,
  Image,
  Title
} from './ThriveAppsSlide.styles';

const ThriveAppsSlide = () => {

  return(
    <Container>
      <Title>Two powerful apps</Title>
      <Image src="/images/appointment-summary/thrive-thrivecare-screens-angled.png" alt="thrive and thrivecare app screens" />
    </Container>
  );
};

export default ThriveAppsSlide;
