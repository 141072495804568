import React, { useContext } from 'react';
// Components
import { CheckBox } from '../../../../components/CheckBox';
// Child Components
import { PlusMinus } from './children/PlusMinus';
// Context
import assessmentContext from '../../../../contexts/assessment/assessmentStore';
// Style
import {
  Container,
  Description,
  Item,
  Name,
  NameText
} from './Accordion.styles';

const Accordion = ({
  active,
  items,
  setActive
}) => {
  const { assessmentDispatch,assessmentState } = useContext(assessmentContext);

  function toggleAccordion(id) {
    setActive(id === active ? '' : id)
  }

  const choose = (i,e) => {
    e.stopPropagation();

    let newArray = assessmentState.accessories.slice();
    newArray[i] = !newArray[i];
    
    assessmentDispatch({
      type: 'SET_ACCESSORIES',
      payload: newArray
    });
  }

  return(
    <Container>
      {items.map((item,index) => {
        return(
        <Item key={item.id} onClick={() => toggleAccordion(item.id)}>
          <Name top={index === 0}>
            <CheckBox checked={assessmentState.accessories[index]} onClick={(e) => choose(index,e)} />
            <NameText>{item.name}</NameText>
            <PlusMinus toggled={active === item.id} />
          </Name>
          <Description active={active === item.id}>{item.text}</Description>
        </Item>
        )
      })}
    </Container>
  );
};

export default Accordion;
