import React, { useEffect,useState } from 'react';
// Components
import { FillDot } from '../../../../components/FillDot';
// Child Components
import { FeatureCircle } from './children/FeatureCircle';
// Data
import { FEATURES_DATA as data } from '../../../../data/featuresData';
// Style
import {
  Container,
  Disclaimer,
  FeatureName,
  FeatureRow,
  FeaturesTable,
  FeaturesTableWrapper,
  FeaturesWrapper,
  FillDotWrapper,
  Th
} from './AiFeaturesPanel.styles';

const AiFeaturesPanel = ({ lifestyleData }) => {
  const [active,setActive] = useState(0);
  const [emphasized,setEmphasized] = useState({
    BOT: false,
    BRT: false,
    FD: false,
    TC: false,
    TR: false
  });

  useEffect(() => {
    filterFeaturesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lifestyleData]);

  const filterFeaturesData = () => {

    lifestyleData.map(item =>
      {
        return item.answers.map((answer) => {
        switch (answer.features.ai) {
          case 'BOT':
            return setEmphasized(state => ({ ...state, BOT: true }));
          case 'BRT':
            return setEmphasized(state => ({ ...state, BRT: true }));
          case 'FD':
            return setEmphasized(state => ({ ...state, FD: true }));
          case 'TC':
            return setEmphasized(state => ({ ...state, TC: true }));
          case 'TR':
            return setEmphasized(state => ({ ...state, TR: true }));
          default:
            return null;
        }
      })}
    );
  };

  return(
    <Container>
      <FeaturesWrapper>
        <FeaturesTableWrapper>
          <FeaturesTable>
            <thead>
              <tr>
                <td />
                <Th best>
                  <span>Best</span>
                </Th>
                <Th better>
                  <span>Better</span>
                </Th>
                <Th good>
                  <span>Good</span>
                </Th>
              </tr>
            </thead>
            <tbody>
              {data.map((feature,pIndex) => {
                return(
                  <FeatureRow
                    active={active === pIndex}
                    key={feature.name}
                    onClick={() => setActive(pIndex)}
                  >
                    <FeatureName
                      emphasized={feature.emphasis[0] && emphasized[feature.emphasis[1]]}
                    >
                      {feature.name}{(feature.emphasis[0] && emphasized[feature.emphasis[1]]) && " *"}
                    </FeatureName>
                    {feature.circles.map((percent,index) => {
                      return(
                        <FillDotWrapper key={index}>
                          <FillDot percent={percent}/>
                        </FillDotWrapper>
                      )
                    })}
                  </FeatureRow>
                )
              })}
            </tbody>
          </FeaturesTable>
        </FeaturesTableWrapper>
        <Disclaimer>* Corresponds with lifestyle assessment</Disclaimer>
      </FeaturesWrapper>
      <FeatureCircle data={data[active]} />
    </Container>
  );
};

export default AiFeaturesPanel;
