import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
`;

export const Slide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  &.slide-enter {
    opacity: 0;
  }

  &.slide-enter-active {
    opacity: 1;
    transition: opacity ${props => props.timeout+'ms'};
  }

  &.slide-exit {
    opacity: 1;
  }

  &.slide-exit-active {
    opacity: 0;
    transition: opacity ${props => props.timeout+'ms'};
  }

  img {
    user-select: none;
    pointer-events: none;
  }
`;

export const ProgressDots = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
`;

export const ProgressDot = styled.button`
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 .3rem;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }

  ::after {
    display: inline-block;
    content: '';
    width: .6rem;
    height: .6rem;
    border-radius: 50%;
    background-color: ${c.grayMed};
  }

  ${({ active }) => active && `

    ::after {
      background-color: ${c.bluePrimary};
    }
  `}
`;
