import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Picker } from '../../components/Picker';
import { Progress } from '../../components/Progress';
// Hooks
import { useStepsComplete } from '../../hooks/useStepsComplete';

const HealthyLivingGoals = () => {
  const complete = useStepsComplete()[3];

  return(
    <Page>
      <Progress visible goTo={'/wellness-concerns'} navDirection={'prev'} text={'Back'} />
      <Picker question={3}>
        4. Please select the top 3 options that<br />describe your <span>overall wellness goals</span>.
      </Picker>
      <Progress
        visible={complete}
        goTo={'/hearing-challenges'}
        navDirection={'next'}
        text={'Select'}
      />
    </Page>
  );
};

export default HealthyLivingGoals;
