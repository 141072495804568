import React, { useContext } from 'react';
// Components
import { FlipCoin } from '../FlipCoin';
// Child Components
import { Side } from './children/Side';
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
// Functions
import { stepIsComplete } from '../../functions/helpers';
// Style
import { Name,Option,Options,Title } from './Picker.styles';
import { colors as c } from '../../styles/colors';

const Picker = ({ children,question }) => {
  const { assessmentDispatch,assessmentState } = useContext(assessmentContext);
  const qData = assessmentState.assessment[question];

  const choose = (option) => {
    let array = qData.selectedTiles.slice();

    if(array[option.id] === 1) {
      array[option.id] = 0;
      assessmentDispatch({type:'SET_ASSESSMENT_ACTIVE_TILE',payload: {id: question, data: array}});
    } else if(stepIsComplete(qData.selectedTiles)) {
      return null
    } else {
      array[option.id] = 1;
      assessmentDispatch({
        type: 'SET_ASSESSMENT_ACTIVE_TILE',
        payload: {id: question, data: array}
      });
    }
  }

  if(!qData) {
    return null;
  } else {
    return(
      <>
        <Title>{children}</Title>
        <Options>
          {qData.options.map((option) => {
            let rowsOf = (qData.options.length === 8) ? 'four' : 'three'
            return (
              <Option
                key={option.guid}
                onClick={() => choose(option)}
                rowsOf={rowsOf}
              >
                <FlipCoin
                  chosen={qData.selectedTiles[option.id] === 1}
                  side1={<Side icon={option.icon} fill={c.grayLight1} />}
                  side2={<Side icon={option.icon} fill="#BDDEF5" />}
                />
                <Name>{option.text}</Name>
              </Option>
            )
          })}
        </Options>
      </>
    )
  }
};

export default Picker;
