import styled from 'styled-components';
import { colors as c } from '../../../../../../styles/colors';

export const Container = styled.div`
  position: absolute;
  right: -36rem;
  top: 2.375rem;
  background: ${c.grayLight2};
  width: 57.5rem;
  height: 43.1875rem;
  border-bottom-left-radius: 38.375rem;
  border-top-left-radius: 38.375rem;
`;

export const Content = styled.div`
  position: absolute;
  left: 3rem;
  top: 50%;
  transform: translateY(-50%);
  width: 16.3rem;

  &.content-enter {
    opacity: 0;
  }

  &.content-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.content-exit {
    opacity: 1;
  }

  &.content-exit-active {
    opacity: 0;
  }
`;

export const Title = styled.h2`
  color: ${c.bluePrimary};
  font-size: 1.68rem;
  margin-top: .84rem;
  margin-bottom: .625rem;
`;

export const Description = styled.p`
  font-size: 1.125rem;
`;
