import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '61.705',
  height = '80.246',
  className = '',
  viewBox = '0 0 61.705 80.246'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <g transform="translate(-19 -5)">
    <g transform="translate(19 13)">
      <path
        d="M35.029,47.4H-10.042c-5.287,0-8.317-3.03-8.317-8.314V-16.53c0-5.284,3.03-8.314,8.317-8.314h6.255a1.575,1.575,0,0,1,1.576,1.573,1.576,1.576,0,0,1-1.576,1.576h-6.255c-3.524,0-5.165,1.64-5.165,5.165V39.088c0,3.522,1.64,5.162,5.165,5.162H35.029c3.522,0,5.165-1.64,5.165-5.162V-16.53c0-3.524-1.643-5.165-5.165-5.165H28.774A1.573,1.573,0,0,1,27.2-23.271a1.572,1.572,0,0,1,1.573-1.573h6.255c5.287,0,8.317,3.03,8.317,8.314V39.088c0,5.284-3.03,8.314-8.317,8.314" transform="translate(18.359 24.844)"
        fill={fill} />
    </g>
    <g
      transform="translate(33 5)"
      fill="none"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3" >
      <path
        d="M7,0H27a7,7,0,0,1,7,7v9a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V7A7,7,0,0,1,7,0Z"
        stroke="none" />
      <path
        d="M7,1.5H27A5.5,5.5,0,0,1,32.5,7v6A1.5,1.5,0,0,1,31,14.5H3A1.5,1.5,0,0,1,1.5,13V7A5.5,5.5,0,0,1,7,1.5Z"
        fill={fill} />
    </g>
    <path
      d="M63.317,26.059a4.921,4.921,0,0,0-3.3,8.587L51.479,46.066a4.816,4.816,0,0,0-5.4.3L40.96,41.88a4.936,4.936,0,1,0-8.272.063l-5.4,5.551a4.927,4.927,0,1,0,1.384,1.347l5.366-5.523a4.917,4.917,0,0,0,5.581-.043L44.782,47.8a4.883,4.883,0,0,0-.712,2.516,4.938,4.938,0,1,0,9.877,0,4.888,4.888,0,0,0-1.018-2.971l8.757-11.71a4.874,4.874,0,0,0,1.631.3,4.938,4.938,0,1,0,0-9.877ZM24.6,54.636a3.009,3.009,0,1,1,3.009-3.009A3.013,3.013,0,0,1,24.6,54.636Zm12.2-12.4a3.009,3.009,0,1,1,3.009-3.009A3.013,3.013,0,0,1,36.807,42.237Zm12.2,11.089a3.009,3.009,0,1,1,3.009-3.008A3.012,3.012,0,0,1,49.009,53.326Zm14.308-19.32A3.009,3.009,0,1,1,66.326,31,3.013,3.013,0,0,1,63.317,34.007Z"
      transform="translate(5.836 8.441)"
      fill={fill}
      stroke={fill}
      strokeWidth="1.1" />
  </g>
  </svg>
);

export default SVG;
