import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  position: relative;
  display: inline-block;
  width: .75rem;
  height: .75rem;
  border-radius: 50%;
  background-color: ${c.bluePrimary};
  overflow: hidden;
  border: 1px solid #e2e2e2;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${props => 100 - props.percent+'%'};
    background-color: #fff;
  }
`;