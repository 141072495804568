import React from 'react';
import { useHistory } from 'react-router-dom';
// Components
import { Page } from '../../components/Page';
// Styles
import {
  Button,
  Message
} from './NoAccess.styles'

const NoAccess = () => {
  const history = useHistory();

  function handleLogout() {
    history.push('/logout');
  }

  return (
    <Page>
      <Message>Please contact your Sales Representative to learn more about Care365.</Message>
      <Button onClick={handleLogout}>Sign out</Button>
    </Page>
  );
};

export default NoAccess;
