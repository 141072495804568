import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '89.27',
  height = '86.31',
  className = '',
  viewBox = '0 0 89.27 86.31'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-243 -247.005)">
      <path
        d="M3.251,6.5a17.539,17.539,0,0,1-17.519-17.52,17.525,17.525,0,0,1,16.49-17.49,1.632,1.632,0,0,1,1.717,1.529,1.625,1.625,0,0,1-1.53,1.715A14.275,14.275,0,0,0-11.018-11.023,14.286,14.286,0,0,0,3.251,3.248,14.2,14.2,0,0,0,16.892-6.825,1.621,1.621,0,0,1,18.925-7.9,1.625,1.625,0,0,1,20-5.87,17.429,17.429,0,0,1,3.251,6.5"
        transform="translate(257.268 326.82)"
        fill={fill}
      />
      <path
        d="M3.251,6.456A17.541,17.541,0,0,1-14.27-11.064,17.5,17.5,0,0,1-8.085-24.422a1.628,1.628,0,0,1,2.29.185,1.626,1.626,0,0,1-.187,2.292,14.26,14.26,0,0,0-5.037,10.88A14.287,14.287,0,0,0,3.251,3.206,14.286,14.286,0,0,0,17.52-11.064,14.244,14.244,0,0,0,5.77-25.112,1.626,1.626,0,0,1,4.456-27a1.63,1.63,0,0,1,1.886-1.315A17.49,17.49,0,0,1,20.77-11.064,17.539,17.539,0,0,1,3.251,6.456"
        transform="translate(311.497 326.861)"
        fill={fill}
      />
      <path
        d="M3.547,3.9H-12.066A3.47,3.47,0,0,1-15.057,2.21a3.461,3.461,0,0,1-.106-3.428l7.429-14.975a1.626,1.626,0,0,1,2.178-.734,1.628,1.628,0,0,1,.734,2.179L-12.267.258a.248.248,0,0,0-.009.27.228.228,0,0,0,.21.117H3.547a1.626,1.626,0,1,1,0,3.251"
        transform="translate(274.737 312.592)"
        fill={fill}
      />
      <path
        d="M1.153,2.685A1.625,1.625,0,0,1,.066-.148l9.3-8.385h-12.8a1.626,1.626,0,0,1-1.624-1.626,1.626,1.626,0,0,1,1.624-1.626H13.147v4.219L2.241,2.268a1.617,1.617,0,0,1-1.088.417"
        transform="translate(296.681 300.712)"
        fill={fill}
      />
      <path
        d="M1.69,3.379a9.115,9.115,0,0,1-9.1-9.1,9.116,9.116,0,0,1,9.1-9.106A9.117,9.117,0,0,1,10.8-5.725a9.116,9.116,0,0,1-9.107,9.1m0-14.959A5.862,5.862,0,0,0-4.165-5.725,5.862,5.862,0,0,0,1.69.129,5.861,5.861,0,0,0,7.545-5.725,5.861,5.861,0,0,0,1.69-11.58"
        transform="translate(292.384 261.836)"
        fill={fill}
      />
      <path
        d="M5.174,10.809c-3.3,0-5.464-3.049-6.2-4.848L-6.485-10.414l-10.97-6.855a11.229,11.229,0,0,1-5.037-7.289,11.24,11.24,0,0,1,3.44-10.466l8.565-9.474A11.047,11.047,0,0,1,4.035-44.92L20.221-31.431c1.6,1.392,3.147,4.376.945,6.982-1.837,2.168-4.92,1.266-6.494.376l-15.74-7.871-4.307,4.3v.366c2.052,1.481,7.645,5.594,9,7.3C5.17-18.025,7.964-3.382,9.131,3.037c.327.786,1.251,3.4.222,5.455A4.113,4.113,0,0,1,6.39,10.675a5.686,5.686,0,0,1-1.217.134M2.02,4.831C2.027,4.831,3.393,8.01,5.7,7.5c.583-.128.7-.357.749-.454a4.358,4.358,0,0,0-.36-2.863L5.965,3.8C4.215-5.852,1.9-16.643,1.049-17.992.107-19.176-4.97-23-7.944-25.12l-.681-.485v-3.382L-1.711-35.9l17.96,8.983c.668.376,2.052.821,2.434.37.846-1-.228-2.1-.578-2.418L1.952-42.423a7.759,7.759,0,0,0-10.138.217l-8.565,9.474A8.069,8.069,0,0,0-19.3-25.2a7.969,7.969,0,0,0,3.572,5.17l11.959,7.474L2.02,4.833Z"
        transform="translate(285.283 310.28)"
        fill={fill}
      />
      <path
        d="M2.186,6.5A1.625,1.625,0,0,1,.633,5.355L-6.921-19.431v-5.856H-7.968a1.625,1.625,0,0,1-1.626-1.624,1.626,1.626,0,0,1,1.626-1.626h4.3v8.861L3.739,4.4A1.626,1.626,0,0,1,2.186,6.5"
        transform="translate(313.498 311.855)"
        fill={fill}
      />
    </g>
  </svg>
);

export default SVG;
