import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 1rem;
  color: ${c.bluePrimary};
`;

export const Subtitle = styled.h2`
  font-size: 1.875rem;
  margin-bottom: 1.5rem;
`;

export const Columns = styled.div`
  display: flex;
  width: 51.25rem;
`;

export const Column = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ left }) => left && `
    flex-basis: 49%;

    .transition-group {
      position: relative;
      width: 23.12rem;
      height: 23.43rem;
    }
  `}

  ${({ right }) => right && `
    flex: 1;
    padding: 2rem 1rem 0 2rem;
  `}
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  &.image-enter {
    opacity: 0;
  }

  &.image-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.image-exit {
    opacity: 1;
  }

  &.image-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
`;

export const ListIntro = styled.p`
  font-size: 1.68rem;
`;

export const CheckList = styled.ul`
  margin-bottom: 2.4rem;
`;

export const CheckListItem = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 1.1rem;
`;

export const ItemText = styled.span`
  display: inline-block;
  margin-left: 1.6rem;
  font-size: 1.68rem;
`;
