import React from 'react';
// Style
import { Container } from './DotToggle.styles';

const DotToggle = ({ toggled }) => {

  return(
    <Container toggled={toggled} />
  );
};

export default DotToggle;
