import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '80.21',
  height = '66.18',
  className = '',
  viewBox = '0 0 80.21 66.18'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M100.564,0H55.877a6.81,6.81,0,0,0-6.8,6.8V32.445a6.805,6.805,0,0,0,6.8,6.793H83.6L98.428,49.607a1.832,1.832,0,0,0,1.077.349,1.871,1.871,0,0,0,.867-.215l1-.648,0-9.9a6.247,6.247,0,0,0,1.122-.232,6.837,6.837,0,0,0,4.862-6.516V6.8A6.8,6.8,0,0,0,100.564,0Zm3.495,32.445a3.492,3.492,0,0,1-3.495,3.486H98.079l-.009,9.39-13.425-9.39H55.877a3.491,3.491,0,0,1-3.49-3.486V6.8a3.5,3.5,0,0,1,3.49-3.495h44.686A3.5,3.5,0,0,1,104.059,6.8Z"
      transform="translate(-27.146)"
      fill={fill}
    />
    <path
      d="M54.979,68.161a3.5,3.5,0,0,1-3.495,3.49H22.721L9.3,81.041l-.009-9.39H6.8a3.451,3.451,0,0,1-1-.147,3.506,3.506,0,0,1-2.494-3.343V42.517a3.5,3.5,0,0,1,3.49-3.49H16.8V35.72H6.8a6.806,6.806,0,0,0-6.8,6.8V68.161a6.841,6.841,0,0,0,4.862,6.516,7.012,7.012,0,0,0,1.126.237l0,8.9a1.839,1.839,0,0,0,.961,1.631l1.122.492L23.767,74.967H51.488a6.807,6.807,0,0,0,6.8-6.8V63.151H54.983v5.01Z"
      transform="translate(0 -19.756)"
      fill={fill}
    />
  </svg>
);

export default SVG;
