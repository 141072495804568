import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: auto;
  padding: 2.5rem 1rem 0;
`;

export const Sections = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 4rem;
`;

export const CircleSection = styled.div`
  position: absolute;
  opacity: 0;
  bottom: 4.3rem;
  left: 10.5rem;
  width: 16.5rem;
  height: 16.5rem;
  border-radius: 50%;
  background-color: rgba(30,155,233,.71);

  ${({ active }) => active && `
    opacity: 1;
    transition: opacity .7s .1s ease;
  `}
`;
