import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '68.8',
  height = '78.75',
  className = '',
  viewBox = '0 0 68.8 78.75'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(0 0)">
      <g transform="translate(21.36 37.489)">
        <path
          d="M.359,1.275a1.7,1.7,0,0,1-1.7-1.7V-3.057a1.7,1.7,0,0,1,1.7-1.7,1.7,1.7,0,0,1,1.7,1.7V-.426a1.7,1.7,0,0,1-1.7,1.7"
          transform="translate(1.341 4.757)"
          fill={fill}
        />
      </g>
      <g transform="translate(42.467 37.489)">
        <path
          d="M.359,1.275a1.7,1.7,0,0,1-1.7-1.7V-3.057a1.7,1.7,0,0,1,1.7-1.7,1.7,1.7,0,0,1,1.7,1.7V-.426a1.7,1.7,0,0,1-1.7,1.7"
          transform="translate(1.341 4.757)"
          fill={fill}
        />
      </g>
      <g transform="translate(23.181 55.495)">
        <path
          d="M.359,0a1.7,1.7,0,0,1,1.7,1.7c0,3.3,3.5,5.987,7.8,5.987S17.653,5,17.653,1.7a1.7,1.7,0,1,1,3.4,0c0,5.176-5.022,9.387-11.195,9.387S-1.341,6.876-1.341,1.7A1.7,1.7,0,0,1,.359,0"
          transform="translate(1.341)"
          fill={fill}
        />
      </g>
      <g transform="translate(0.906 29.223)">
        <path
          d="M7.175,10.467c-8.161,0-21.955-8.629-24.8-14.258-1.049-2.085-1.684-7.59-2.346-14.036-.039-.389-.077-.762-.115-1.116a5.136,5.136,0,0,1-4.112-1.936c-3.119-3.747-3.008-12.6-1.766-15.973A2.829,2.829,0,0,1-23.9-38.939a9.769,9.769,0,0,1,5.514.689,1.7,1.7,0,0,1,.615,2.324,1.7,1.7,0,0,1-2.324.615,7.146,7.146,0,0,0-2.7-.337c-.711,1.874-1.139,9.01.942,12.227.713,1.1,1.4,1.154,2,1.047l.274-.047c.061-.039.12-.076.186-.11a1.708,1.708,0,0,1,1.585,0,1.7,1.7,0,0,1,.9,1.3c.1.808.2,1.864.325,3.055.4,3.9,1.146,11.158,2,12.852C-12.266-.726.481,7.068,7.175,7.068S26.615-.726,28.934-5.321c.852-1.689,1.6-8.927,2-12.815.123-1.207.232-2.276.33-3.094a1.7,1.7,0,0,1,.9-1.3,1.7,1.7,0,0,1,1.582,0,1.84,1.84,0,0,1,.178.108l.283.049c.591.1,1.279.055,2-1.047,2.078-3.214,1.653-10.353.943-12.228a7.148,7.148,0,0,0-2.7.341,1.721,1.721,0,0,1-2.31-.657,1.677,1.677,0,0,1,.606-2.285,9.812,9.812,0,0,1,5.518-.689,2.829,2.829,0,0,1,2.054,2.086c1.244,3.37,1.352,12.226-1.766,15.974a5.048,5.048,0,0,1-4.116,1.936c-.037.366-.076.753-.117,1.154-.662,6.429-1.295,11.919-2.342,14C29.13,1.84,15.335,10.467,7.175,10.467M34.412-21.919a1.792,1.792,0,0,1,.335.967,1.627,1.627,0,0,0-.335-.967"
          transform="translate(26.773 39.061)"
          fill={fill}
        />
      </g>
      <g transform="translate(6.997 16.453)">
        <path
          d="M11.33,4.5a1.646,1.646,0,0,1-.273-.022c-1.312-.21-1.5-1.227-1.88-3.25-.706-3.791-2.314-12.4-7.509-14.381C-6.021-4.845-16.025-6.18-24.117-7.263c-5.2-.695-9.691-1.3-12.159.749-1.551,1.284-2.405,3.69-2.607,7.354a1.778,1.778,0,0,1-1.79,1.6A1.7,1.7,0,0,1-42.278.653c.259-4.662,1.511-7.864,3.836-9.786,3.612-2.992,9.033-2.271,14.774-1.5,7.929,1.057,16.9,2.256,23.478-5.55a1.689,1.689,0,0,1,1.656-.567C9.6-15.01,11.647-4.069,12.518.6c.107.571.184,1.021.255,1.3a1.675,1.675,0,0,1,.232,1.163A1.7,1.7,0,0,1,11.33,4.5M11.6,1.121s0,0,0,0,0,0,0,0m-.006,0h0"
          transform="translate(42.281 16.788)"
          fill={fill}
        />
      </g>
      <g>
        <path
          d="M12.809,7.976a1.694,1.694,0,0,1-1.246-.544C9.137,4.819,14.542-5.594,14.6-5.691a7.813,7.813,0,0,0-1-10.136c-2.012-1.728-4.486-1.557-6.793.464a1.7,1.7,0,0,1-2.1.113A1.7,1.7,0,0,1,4.1-17.263a3.089,3.089,0,0,0-.175-3c-2.364-3.7-12.124-5.556-19.763-6.019-9.755-.59-19.357,2.026-24.487,6.67-6,5.437-4.839,9.2.03,15.59.817,1.073,1.721,3.639,3.114,8.854.077.292.136.506.166.616a1.7,1.7,0,0,1-.333,1.724,1.7,1.7,0,0,1-2.4.122c-.383-.347-.441-.559-.715-1.584C-42.02-.116-42.72-1.6-43-1.964c-4.52-5.928-8.214-12.369.392-20.17,5.773-5.227,16.377-8.194,26.972-7.542,5.5.333,18.7,1.737,22.427,7.582a6.307,6.307,0,0,1,.9,2.251,7.834,7.834,0,0,1,8.119,1.435A11.225,11.225,0,0,1,17.467-3.863c-1.135,1.783-3.271,7.813-3.18,9.3a1.7,1.7,0,0,1-1.478,2.541m1.246-2.857s0,0,0,0,0,0,0,0"
          transform="translate(47.798 29.764)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
