export const QUESTION_SUPPLEMENT_DATA = [
  {
    features: {
      ai: ['BOT', 'BRT', 'BOT', 'BOT', 'BOT', 'BOT'],
      techLevel: ['110','110','110','111','111','111']
    },
    icons: ['active', 'sociable', 'comfortable', 'inactive', 'reserved', 'technology']
  },
  {
    features: {
      ai: ['BOT', 'BOT', 'BRT', 'BRT', 'BRT', 'TC', 'BRT', 'BOT'],
      techLevel: ['100','110','111','110','100','111','111','110']
    },
    icons: ['exercise', 'golf', 'cards', 'family', 'restaurants', 'tv', 'reserved', 'gardening']
  },
  {
    features: {
      ai: ['FD', 'BRT', 'BRT', 'BOT', 'FD', 'BRT', 'FD', 'BRT'],
      techLevel: ['111','100','110','110','111','111','100','100']
    },
    icons: ['independence', 'brain', 'unhappy', 'cane', 'fall', 'happy', 'burden', 'gauge']
  },
  {
    features: {
      ai: ['BRT', 'BOT', 'BOT', 'BOT', 'BRT', 'BRT', 'BRT', 'BRT'],
      techLevel: ['100','110','111','111','110','100','111','100']
    },
    icons: ['relationships', 'bolt', 'clipboardGraph', 'bowling', 'bulb', 'chat', 'winning', 'brain']
  },
  {
    features: {
      ai: ['TC', 'TR', 'TR', 'BOT', 'BOT', 'TR'],
      techLevel: ['111','111','111','111','111']
    },
    icons: ['tv', 'repeat', 'women', 'restaurants', 'telephone', 'questionMark']
  }
];
