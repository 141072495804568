import styled from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 2.5rem;
  color: ${c.bluePrimary};
  padding-top: 4rem;
  text-align: center;
`;

export const Image = styled.img`
  width: 22rem;
  height: auto;
  margin-bottom: 1.5rem;
`;

export const CheckList = styled.ul`
  margin-bottom: 2.4rem;
`;

export const CheckListItem = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 1.1rem;
`;

export const ItemText = styled.span`
  display: inline-block;
  margin-left: 1.6rem;
  font-size: 1.68rem;
`;
