import React, { useState } from 'react';
// Components
import { HearingAid360 } from './children/HearingAid360';
// Data
// import { HEARING_STYLES_DATA as data } from '../../../../data/hearingStylesData';
// Style
import {
  Container
} from './RightColumn.styles';

const RightColumn = ({
  activeStyle,
  hide,
  rechargeableChecked
}) => {
  const [rangeVal,setRangeVal] = useState(10);

  return(
    <Container hide={hide}>
      <HearingAid360
        activeStyleIndex={activeStyle}
        rangeVal={rangeVal}
        rechargeableChecked={rechargeableChecked}
        setRangeVal={setRangeVal}
      />
    </Container>
  );
};

export default RightColumn;
