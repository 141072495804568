import React from 'react';

const SVG = ({
  style = {},
  width = '474.61',
  height = '250.25',
  className = '',
  viewBox = '0 0 474.61 250.25'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <path
          d="M189.62,84.3A9.19,9.19,0,0,0,182,80.85c-3.57,0-7.13,1.6-7.13,5.6,0,8.74,20.67,3.75,20.67,19.26,0,9.35-7.39,13.9-15.94,13.9-5.41,0-10.7-1.66-14.27-5.91l5.85-5.66a10.25,10.25,0,0,0,8.61,4.55c3.57,0,7.62-2,7.62-6,0-9.54-20.66-4.06-20.66-19.63,0-9,8-13.16,16-13.16a17.87,17.87,0,0,1,12.49,4.49Z"
          fill="#003262"
        />
        <path
          d="M197.42,95.25V89h6.09V80.42h7.38V89H219v6.28h-8.12v12.92c0,3,.86,4.79,4.12,4.79a7.66,7.66,0,0,0,4-.92v6.22a15.73,15.73,0,0,1-5.84,1c-7.82,0-9.66-3.51-9.66-10.46V95.25Z"
          fill="#003262"
        />
        <path
          d="M243.16,114.44H243c-2.09,3.32-5.54,4.8-9.47,4.8-5.48,0-10.77-3-10.77-8.86,0-9.6,11.2-10.28,18.58-10.28h1.85v-.79c0-3.63-2.83-5.54-6.77-5.54a11.61,11.61,0,0,0-7.88,3.08L224.64,93c3.26-3.32,7.87-4.74,12.55-4.74,12.61,0,12.61,9.11,12.61,13.29v17h-6.64Zm-.43-9.16h-1.54c-4.06,0-11.08.31-11.08,4.55,0,2.7,2.77,3.87,5.17,3.87,5.05,0,7.45-2.64,7.45-6.77Z"
          fill="#003262"
        />
        <path
          d="M256.3,89h7.38v4.68h.13a9.4,9.4,0,0,1,11.37-5V95.8a15.1,15.1,0,0,0-3.56-.55,7.43,7.43,0,0,0-7.94,7.44V118.5H256.3Z"
          fill="#003262"
        />
        <polygon
          points="279.44 72 286.82 72 286.82 101.22 298.21 88.97 307.68 88.97 295.01 102.38 308.48 118.5 298.7 118.5 286.95 103.43 286.82 103.43 286.82 118.5 279.44 118.5 279.44 72"
          fill="#003262"
        />
        <path
          d="M314.86,106.32a7.66,7.66,0,0,0,7.94,7,9.71,9.71,0,0,0,7.94-4l5.28,4a15.35,15.35,0,0,1-12.48,5.91c-8.92,0-16.06-6.21-16.06-15.5s7.14-15.51,16.06-15.51c8.24,0,13.84,5.79,13.84,16.06v2ZM330,100.78c-.06-4.3-2.9-7-7.51-7-4.36,0-7.07,2.77-7.63,7Z"
          fill="#003262"
        />
        <path
          d="M338.51,89h8.12l8.25,20.49H355L362.38,89h7.69l-14,34.77c-2.15,5.53-4.73,8.42-11.25,8.42a21.61,21.61,0,0,1-5.66-.74l.92-6.64a10.79,10.79,0,0,0,3.75.74c3.64,0,4.62-.19,5.85-3.27l1.41-3.5Z"
          fill="#003262"
        />
        <path
          d="M255.81,157.4s-63.9-35-136.55-5.13l35.55-60.68L119.27,60H170l25.87-43L205,60h165.8v-.9L211.59,51.93,198.88,0,164.77,52.27H100.9l42.69,41.2s-42.51,76.32-42.69,76.36c73.87-44.5,154.91-11.57,154.91-11.57Z"
          fill="#fdb913"
        />
        <polygon
          points="3.11 215.6 3.11 230.32 23.38 230.32 23.38 215.6 26.5 215.6 26.5 249.57 23.38 249.57 23.38 233.09 3.11 233.09 3.11 249.57 0 249.57 0 215.6 3.11 215.6"
          fill="#00aae7"
          />
        <path
          d="M40.82,224.37c5.72,0,10,3.86,10,9.35a21,21,0,0,1-.23,2.49H32.44c-.45,5.49,2.32,11.32,10.31,11.32a13.58,13.58,0,0,0,7.3-2.55l1,1.87a14.7,14.7,0,0,1-9.79,3.34c-6.8,0-11.84-5.55-11.84-13.08,0-7.87,5.72-12.74,11.38-12.74m.17,2.55a8.23,8.23,0,0,0-8.32,7H47.9c.51-3.91-2.43-7-6.91-7"
          fill="#00aae7"
        />
        <path
          d="M74.85,247.82a2.75,2.75,0,0,0,1.47-.4v1.81A3.45,3.45,0,0,1,74,250c-2.2,0-3.68-1.19-3.68-4.3v-.4c-4.07,3.91-7.81,4.87-10.53,4.87A5.56,5.56,0,0,1,54,244.42c0-5.78,7.7-8.83,16.36-9.68v-1.93c0-4-1.7-5.66-5.83-5.66A20.82,20.82,0,0,0,55.66,230v-2.89c2.09-1.13,6.51-2.72,9.28-2.72,4.87,0,8.38,1.93,8.38,8.44v13c0,1.41.51,2,1.53,2m-4.53-10.54c-8.26.68-13.53,3.63-13.53,7,0,2.66,2,3.34,4.3,3.34,1.48,0,5.61-1.36,9.23-4.76Z"
          fill="#00aae7"
        />
        <path
          d="M79.55,225.34l2.5-.51.33,5a12.19,12.19,0,0,1,9.4-5.38v3.51c-4.47-.68-9.23,2.72-9.23,7v14.61h-3Z"
          fill="#00aae7"
        />
        <path
          d="M104.3,215.32l3-.57v14.1a12,12,0,0,1,9.79-4.42c6.4,0,11.55,5.32,11.55,12.91s-5.15,12.91-11.55,12.91a11.92,11.92,0,0,1-10-4.76l-.57,4.25h-2.2Zm12.23,11.77a10.78,10.78,0,0,0-9.23,5.32v9.86a10.78,10.78,0,0,0,9.23,5.32c4.75,0,9.11-3.51,9.11-10.25s-4.36-10.25-9.11-10.25"
          fill="#00aae7"
        />
        <path
          d="M144.5,224.37c5.72,0,10,3.86,10,9.35a19.05,19.05,0,0,1-.23,2.49H136.12c-.45,5.49,2.32,11.32,10.3,11.32a13.61,13.61,0,0,0,7.31-2.55l1,1.87a14.7,14.7,0,0,1-9.79,3.34c-6.79,0-11.83-5.55-11.83-13.08,0-7.87,5.72-12.74,11.38-12.74m.17,2.55a8.24,8.24,0,0,0-8.33,7h15.23c.51-3.91-2.43-7-6.9-7"
          fill="#00aae7"
        />
        <path
          d="M161.71,242.15V227.66H158v-2.55h3.74v-6.06l3-.62v6.68h6.17v2.55h-6.17v15.17c0,2.83.68,4.7,2.83,4.7a6.72,6.72,0,0,0,3.85-1.13l.57,1.7a6.65,6.65,0,0,1-5.1,2c-4,0-5.15-2.55-5.15-8"
          fill="#00aae7"
        />
        <path
          d="M177.79,242.15V227.66h-3.74v-2.55h3.74v-6.06l3-.62v6.68H187v2.55h-6.17v15.17c0,2.83.68,4.7,2.83,4.7a6.72,6.72,0,0,0,3.85-1.13l.57,1.7a6.65,6.65,0,0,1-5.1,2c-4,0-5.15-2.55-5.15-8"
          fill="#00aae7"
        />
        <path
          d="M202.7,224.37c5.72,0,10,3.86,10,9.35a21,21,0,0,1-.23,2.49H194.32c-.45,5.49,2.33,11.32,10.31,11.32a13.58,13.58,0,0,0,7.3-2.55l1,1.87a14.7,14.7,0,0,1-9.79,3.34c-6.8,0-11.84-5.55-11.84-13.08,0-7.87,5.72-12.74,11.38-12.74m.17,2.55a8.23,8.23,0,0,0-8.32,7h15.23c.51-3.91-2.43-7-6.91-7"
          fill="#00aae7"
        />
        <path
          d="M218.73,225.34l2.49-.51.34,5a12.16,12.16,0,0,1,9.4-5.38v3.51c-4.47-.68-9.23,2.72-9.23,7v14.61h-3Z"
          fill="#00aae7"
        />
        <path
          d="M234.64,247.76a2.15,2.15,0,1,1-2.15-2.15,2.15,2.15,0,0,1,2.15,2.15"
          fill="#00aae7"
        />
        <polygon
          points="254.97 215.6 254.97 246.8 271.1 246.8 271.1 249.57 251.85 249.57 251.85 215.6 254.97 215.6"
          fill="#00aae7"
        />
        <path
          d="M276.71,215.54a2,2,0,1,1,0,4.08,2,2,0,1,1,0-4.08m1.53,9.23v24.8h-3V225.34Z"
          fill="#00aae7"
        />
        <polygon
          points="285.6 225.11 294.03 245.44 302.41 225.11 305.36 225.11 295.05 249.57 292.73 249.57 282.43 225.11 285.6 225.11"
          fill="#00aae7"
        />
        <path
          d="M319.57,224.37c5.72,0,10,3.86,10,9.35a19.05,19.05,0,0,1-.23,2.49H311.19c-.45,5.49,2.32,11.32,10.3,11.32A13.58,13.58,0,0,0,328.8,245l1,1.87a14.72,14.72,0,0,1-9.8,3.34c-6.79,0-11.83-5.55-11.83-13.08,0-7.87,5.72-12.74,11.38-12.74m.17,2.55a8.22,8.22,0,0,0-8.32,7h15.23c.51-3.91-2.44-7-6.91-7"
          fill="#00aae7"
        />
        <path
          d="M345,215.32l3-.57v14.1a12,12,0,0,1,9.8-4.42c6.4,0,11.55,5.32,11.55,12.91s-5.15,12.91-11.55,12.91a11.91,11.91,0,0,1-10-4.76l-.57,4.25H345Zm12.23,11.77a10.78,10.78,0,0,0-9.23,5.32v9.86a10.78,10.78,0,0,0,9.23,5.32c4.76,0,9.12-3.51,9.12-10.25s-4.36-10.25-9.12-10.25"
          fill="#00aae7"
        />
        <path
          d="M385.19,224.37c5.72,0,10,3.86,10,9.35a21,21,0,0,1-.23,2.49H376.81c-.45,5.49,2.32,11.32,10.31,11.32a13.58,13.58,0,0,0,7.3-2.55l1,1.87a14.7,14.7,0,0,1-9.79,3.34c-6.8,0-11.84-5.55-11.84-13.08,0-7.87,5.72-12.74,11.38-12.74m.17,2.55a8.23,8.23,0,0,0-8.32,7h15.23c.51-3.91-2.43-7-6.91-7"
          fill="#00aae7"
        />
        <path
          d="M402.41,242.15V227.66h-3.74v-2.55h3.74v-6.06l3-.62v6.68h6.17v2.55h-6.17v15.17c0,2.83.68,4.7,2.83,4.7a6.74,6.74,0,0,0,3.85-1.13l.56,1.7a6.64,6.64,0,0,1-5.09,2c-4,0-5.15-2.55-5.15-8"
          fill="#00aae7"
        />
        <path
          d="M418.49,242.15V227.66h-3.74v-2.55h3.74v-6.06l3-.62v6.68h6.17v2.55h-6.17v15.17c0,2.83.68,4.7,2.83,4.7a6.74,6.74,0,0,0,3.85-1.13l.56,1.7a6.62,6.62,0,0,1-5.09,2c-4,0-5.15-2.55-5.15-8"
          fill="#00aae7"
        />
        <path
          d="M443.4,224.37c5.72,0,10,3.86,10,9.35a20.84,20.84,0,0,1-.22,2.49H435c-.45,5.49,2.32,11.32,10.3,11.32a13.58,13.58,0,0,0,7.31-2.55l1,1.87a14.72,14.72,0,0,1-9.8,3.34c-6.79,0-11.83-5.55-11.83-13.08,0-7.87,5.72-12.74,11.38-12.74m.17,2.55a8.22,8.22,0,0,0-8.32,7h15.23c.51-3.91-2.44-7-6.91-7"
          fill="#00aae7"
        />
        <path
          d="M459.42,225.34l2.5-.51.33,5a12.19,12.19,0,0,1,9.4-5.38v3.51c-4.47-.68-9.23,2.72-9.23,7v14.61h-3Z"
          fill="#00aae7"
        />
        <path
          d="M474.61,247.76a2.16,2.16,0,1,1-2.16-2.15,2.16,2.16,0,0,1,2.16,2.15"
          fill="#00aae7"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
