import React, { useContext,useState } from 'react';
// Components
import { ButtonSlideTriple } from '../../components/ButtonSlideTriple';
import { EarAnatomy } from '../../components/EarAnatomy';
import Icon from '../../components/Icon';
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { Modal } from './children/Modal';
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
// Style
import {
  ButtonWrapper,
  EarAnatomyWrapper,
  ModalOpenButton,
  Title
} from './TypesOfHearingLoss.styles';

const TypesOfHearingLoss = () => {
  const [modalOpen,setModalOpen] = useState(false);
  const { assessmentDispatch,assessmentState } = useContext(assessmentContext);

  const types = [
    "Sensorineural",
    "Conductive",
    "Mixed"
  ]

  const choose = (num) => {
    assessmentDispatch({
      type: 'SET_HEARING_LOSS_TYPE',
      payload: {
        active: num,
        type: types[num]
      }
    });
  }

  return(
    <Page>
      <Progress visible goTo={'/audiogram'} navDirection={'prev'} text={'Back'} />
      <Title>About your hearing</Title>
      <EarAnatomyWrapper>
        <EarAnatomy activeSection={assessmentState.hearingLossType.active} />
        {(assessmentState.hearingLossType.active === 0 && !modalOpen) &&
          <ModalOpenButton onClick={() => setModalOpen(true)}>
            <Icon name={'plus'} fill={'#fff'} />
          </ModalOpenButton>
        }
      </EarAnatomyWrapper>
      <ButtonWrapper>
        <ButtonSlideTriple
          active={assessmentState.hearingLossType.active}
          choose={choose}
          items={['Sensorineural','Conductive','Mixed']}
        />
      </ButtonWrapper>
      {modalOpen && <Modal setModalOpen={setModalOpen} />}
      <Progress visible goTo={'/impacts-of-hearing-loss'} navDirection={'next'} text={'Select'} />
    </Page>
  );
};

export default TypesOfHearingLoss;
