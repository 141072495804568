import React from 'react';
// Child Components
import { Section } from './children/Section';
// Style
import {
  CircleSection,
  Container,
  Image,
  Sections
} from './EarAnatomy.styles';

const EarAnatomy = ({ activeSection=-1 }) => {

  return(
    <Container>
      <Image src="/images/types-of-hearing-loss/ear-anatomy.jpg" alt="ear anatomy" />
      <Sections>
        <Section active={activeSection===1} section={"outerEar"} />
        <Section active={activeSection===1} section={"middleEar"} />
        <Section active={activeSection===0} section={"innerEar"} />
        <Section section={"auditoryNerveConnection"} />
        <CircleSection active={activeSection===2} />
      </Sections>
    </Container>
  );
};

export default EarAnatomy;
