import styled from 'styled-components';
import { colors as c } from '../../../../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 1.5rem;
  color: ${c.bluePrimary};
  padding-top: 4rem;
  text-align: center;
`;

export const Columns = styled.div`
  display: flex;
  padding: 0 6rem;
`;

export const Column = styled.div`
  flex: 4;

  ${({ features }) => features && `
    flex: 9;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  `}
`;

export const Feature = styled.div`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  padding: .8rem;
`;

export const FeatureName = styled.h2`
  font-size: 1.125rem;
  margin-left: 1.2rem;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const AppIcons = styled.div`
  display: flex;
  justify-content: center;
`;

export const AppIcon = styled.img`
  width: 7.6875rem;
  height: auto;
  margin: 0 .3rem .3rem;
`;

export const Disclaimer = styled.p`
  font-size: 1rem;
  text-align: center;
`;
