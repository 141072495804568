import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.button`
  position: relative;
  border: none;
  outline: none;
  background-color: ${c.grayLight2};
  padding: .5rem;
  display: flex;
  width: 100%;
  height: 2.625rem;
  border-radius: 2.625rem;
`;

export const Slider = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: ${c.bluePrimary};
  border-radius: 2.625rem;
  transition: left .25s cubic-bezier(.25,1,.35,1);
  box-shadow: 0 .1875rem .4375rem .0625rem rgba(0,0,0,.15);

  ${({ slid }) => slid && `
    left: 50%;
  `}
`;

export const Options = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Option = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: 'DIN Next';
  font-weight: 700;
  transition: color .25s cubic-bezier(.25,1,.35,1);

  ${({ active }) => active && `
    color: #fff;
  `}
`;