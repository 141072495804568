import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '31.22',
  height = '31.19',
  className = '',
  viewBox = '0 0 31.22 31.19'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M.789,31.187a.887.887,0,0,1-.3-.06A.78.78,0,0,1,0,30.4V3.742A3.739,3.739,0,0,1,3.742,0h23.7a3.763,3.763,0,0,1,3.777,3.742V21.5a3.747,3.747,0,0,1-3.742,3.742H7.053L1.339,30.96A.81.81,0,0,1,.789,31.187ZM3.742,1.566A2.207,2.207,0,0,0,1.566,3.742V28.51L6.18,23.9a.788.788,0,0,1,.55-.227H27.482a2.176,2.176,0,0,0,2.176-2.176V3.742a2.2,2.2,0,0,0-2.211-2.176Z"
      fill={fill}
    />
    <path
      d="M11.526,16.729a1.666,1.666,0,0,1,.49-1.279A1.977,1.977,0,0,1,13.438,15a1.948,1.948,0,0,1,1.4.454,1.677,1.677,0,0,1,.478,1.279,1.7,1.7,0,0,1-.49,1.279,1.941,1.941,0,0,1-1.387.454,2.083,2.083,0,0,1-1.411-.454,1.648,1.648,0,0,1-.5-1.279m3.419-3.168H11.956L11.49,4.99h3.921Z"
      transform="translate(2.245 0.975)"
      fill={fill}
    />
  </svg>
);

export default SVG;
