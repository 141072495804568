import React, { useContext,useEffect,useState } from 'react';
import { CSSTransition,SwitchTransition } from 'react-transition-group';
// Components
import { ButtonSlideDouble } from '../../components/ButtonSlideDouble';
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { AiFeaturesPanel } from './children/AiFeaturesPanel';
import { LifestyleNeedsPanel } from './children/LifestyleNeedsPanel';
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
// Functions
import { filterLifestyleData } from '../../functions/data';
// Style
import {
  PanelsWrapper,
  PanelToggleWrapper,
  Title
} from './TechnologyLevels.styles';

const TechnologyLevels = () => {
  const [paneToggled,setPaneToggled] = useState(false);
  const [lifestyleData,setLifestyleData] = useState([]);
  const { assessmentState } = useContext(assessmentContext);

  useEffect(() => {
    setLifestyleData(filterLifestyleData(assessmentState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return(
    <Page>
      <Progress visible goTo={'/impacts-of-hearing-loss'} navDirection={'prev'} text={'Back'} />
      <Title>Your best fit</Title>
      <PanelToggleWrapper>
        <ButtonSlideDouble
          items={['Lifestyle Needs','AI Features']}
          onClick={() => setPaneToggled(paneToggled ? false : true)}
          slid={paneToggled}
        />
      </PanelToggleWrapper>
      <PanelsWrapper>
        <SwitchTransition>
          <CSSTransition
            key={paneToggled}
            classNames='panel'
            timeout={250}
          >
            <>
              {!paneToggled && (lifestyleData.length !== 0) && <LifestyleNeedsPanel data={lifestyleData} />}
              {paneToggled && <AiFeaturesPanel lifestyleData={lifestyleData} />}
            </>
          </CSSTransition>
        </SwitchTransition>
      </PanelsWrapper>
      <Progress visible goTo={'/hearing-aid-transition'} navDirection={'next'} text={'Next'} />
    </Page>
  );
};

export default TechnologyLevels;
