import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SwiperWrapper = styled.div`
  width: 100%;
  flex: 1;

  .swiperProgressDots {
    bottom: 5rem;
  }
`;
