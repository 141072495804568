import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { StruggleScale } from './children/StruggleScale';
// Style
import { Detail,Title } from './YourStory.styles';

const YourStory = () => {

  return(
    <Page>
      <Progress visible goTo={'/lifestyle-assessment-results'} navDirection={'prev'} text={'Back'} />
      <Title>Can you think of a time when you would have liked to hear better?</Title>
      <Detail>Please rate on a scale of 1 - 5 how much that impacted your life.</Detail>
      <StruggleScale />
      <Progress visible goTo={'/how-we-hear'} navDirection={'next'} text={'Next'} />
    </Page>
  );
};

export default YourStory;
