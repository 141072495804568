import React from 'react';
// Components
import Icon from '../../../../../../components/Icon';

const Side = ({ fill,icon }) => {
  return <Icon
    name={icon}
    fill={fill}
    width={'45%'}
    height={'45%'}
  />
}

export default Side;
