import React from 'react';
// Components
import Icon from '../../../../components/Icon';
// Functions
import parse from 'html-react-parser';
// Style
import { Choice,Circle,Container } from './ImpactCircle.styles';
import { colors as c } from '../../../../styles/colors';

const ImpactCircle = ({
  highlighted,
  setModalName,
  setModalOpen
}) => {

  const choose = (name) => {
    setModalName(name);
    setModalOpen(true);
  }

  return(
    <Container>
      <Circle>
        <Choice
          active={highlighted[0]}
          onClick={() => choose('brain-health')}
        >
          <Icon
            name={'brain'}
            width={'3rem'}
            height={'4rem'}
            fill={highlighted[0] ? '#bddef5' : c.bluePrimary}
          />
          {parse('Brain Health/<br />Cognition')}
        </Choice>
        <Choice
          active={highlighted[1]}
          onClick={() => choose('physical-health')}
        >
          <Icon
            name={'active'}
            width={'3rem'}
            height={'4rem'}
            fill={highlighted[1] ? '#bddef5' : c.bluePrimary}
          />
          {parse('Physical<br />Health')}
        </Choice>
        <Choice
          active={highlighted[2]}
          onClick={() => choose('social-interaction')}
        >
          <Icon
            name={'sociable'}
            width={'3rem'}
            height={'4rem'}
            fill={highlighted[2] ? '#bddef5' : c.bluePrimary}
          />
          {parse('Social/<br />Interacion')}
        </Choice>
        <Choice
          active={highlighted[3]}
          onClick={() => choose('loneliness-depression')}
        >
          <Icon
            name={'depression'}
            width={'2rem'}
            height={'4rem'}
            fill={highlighted[3] ? '#bddef5' : c.bluePrimary}
          />
          {parse('Loneliness/<br />Depression')}
        </Choice>
        <Choice
          active={highlighted[4]}
          onClick={() => choose('balance-safety')}
        >
          <Icon
            name={'fall'}
            width={'2.4rem'}
            height={'4rem'}
            fill={highlighted[4] ? '#bddef5' : c.bluePrimary}
          />
          {parse('Balance/<br />Safety')}
        </Choice>
      </Circle>
    </Container>
  );
};

export default ImpactCircle;
