import React from 'react';
// Components
import Icon from '../../../../../../components/Icon';
// Data
import { THRIVE_APP_DATA as data } from '../../../../../../data/thriveAppData';
// Style
import {
  AppIcon,
  AppIcons,
  Column,
  Columns,
  Container,
  Disclaimer,
  Feature,
  FeatureName,
  Image,
  Title
} from './ThriveFeaturesSlide.styles';

const ThriveFeaturesSlide = () => {

  return(
    <Container>
      <Title>Thrive Hearing Control</Title>
      <Columns>
        <Column features>
          {data.map((feature) => {
            return(
              <Feature key={feature.icon}>
                <Icon
                  height={'2.1rem'}
                  name={feature.icon}
                  width={'2.1rem'}
                /><FeatureName>{feature.name}</FeatureName>
              </Feature>
            );
          })}
        </Column>
        <Column>
          <Image src="/images/appointment-summary/thrive-app-iphone-screen.png" alt="thrive app iphone screen" />
        </Column>
      </Columns>
      <AppIcons>
        <AppIcon src="/images/appointment-summary/apple-app-store-icon.png" alt="apple app store icon" />
        <AppIcon src="/images/appointment-summary/google-play-store-icon.png" alt="google play store icon" />
      </AppIcons>
      <Disclaimer>* App features vary by hearing aid technology.</Disclaimer>
    </Container>
  );
};

export default ThriveFeaturesSlide;
