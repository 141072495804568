import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Item = styled.div`

`;

export const Name = styled.button`
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
  padding: .75rem 0 .75rem .75rem;
  display: flex;
  align-items: center;
  border-top: .0625rem solid #ddd;
  width: 100%;
  color: #7c7f7f;

  ${({ top }) => top && `
    border-top: none;
  `}
  
  &:hover {
    cursor: pointer;
  }
`;

export const NameText = styled.h2`
  font-family: 'Mr Eaves XL Sans';
  font-size: 1.5625rem;
  font-weight: 700;
  padding-left: 1rem;
`;

export const Description = styled.div`
  height: 0;
  transform: scaleY(0);
  overflow: hidden;
  padding: 0;
  font-size: 1.3125rem;

  ${({ active }) => active && `
    height: auto;
    transform: scaleY(1);
    transition: transform .2s cubic-bezier(.25,1,.35,1);
    padding: 0 3.05rem 1.25rem;
  `}
`;
