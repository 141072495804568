import React, { useContext,useEffect,useState } from 'react';
import { useHistory } from 'react-router-dom';
// Components
import { ButtonSubmit } from '../../components/Button';
import Icon from '../../components/Icon';
import { Page } from '../../components/Page';
// Auth
import { useMsal } from "@azure/msal-react";
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
import configContext from '../../contexts/config/configStore';
// Functions
import { assembleFakeAssessmentData,assemblePatientData } from '../../functions/data';
import { addAssessment,addPatientProfile,addProviderRecord,getIpAddress } from '../../functions/api';
// Style
import {
  FinePrint,
  Form,
  FormGroup,
  IconWrapper,
  Input,
  Label
 } from './Start.styles';

const Start = () => {
  const { assessmentDispatch,assessmentState } = useContext(assessmentContext);
  const { configState } = useContext(configContext);
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const { accounts } = useMsal();

  useEffect(() => {
    setDisabled(
      (assessmentState.patient.firstName !== '' && assessmentState.patient.lastInitial !== '') ? false : true);
  }, [assessmentState.patient]);

  const handleInputChange = function(e) {
    const target = e.currentTarget;

    if (target.id === 'fname') {
      assessmentDispatch({
        type: 'SET_PATIENT_FIRST_NAME',
        payload: target.value
      });
    }

    if (target.id === 'linitial') {
      assessmentDispatch({
        type: 'SET_PATIENT_LAST_INITIAL',
        payload: target.value
      });
    }
  };

  const handleSubmit = function(event) {
    event.preventDefault();
    
    addPatientProfile(assemblePatientData(assessmentState))
    .then(function(res1) {
      assessmentDispatch({
        type: 'SET_PROFILE_ID',
        payload: res1
      });
      addAssessment(assembleFakeAssessmentData(res1,assessmentState.provider.id)).then(function(res2) {
        assessmentDispatch({
          type: 'SET_ASSESSMENT_ID',
          payload: res2
        });
      });
    });

    history.push('/intro');
  };

  const setProviderData = (email) => {

    const providerBody = {
      FirstName: '',
      LastName: '',
      EmailAddress: email,
      AccountNum: '',
      CreatedIP: '',
      ModifiedIP: ''
    };
    
    addProviderRecord(providerBody)
      .then(id => {
        assessmentDispatch({
          type: 'SET_PROVIDER_ID',
          payload: id
        });
      })
      .then(function() {
        getIpAddress()
          .then(result => {
            assessmentDispatch({
              type: 'SET_PROVIDER_IP',
              payload: result.ip
            });
          })
          .catch(() => {
            assessmentDispatch({
              type: 'SET_PROVIDER_IP',
              payload: '0.0.0.0'
            });
          });
      })
      .catch(function() {
        console.log('bad data');
      });
  };

  useEffect(() => {
    if(accounts.length) {
      setProviderData(accounts[0].idTokenClaims.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[accounts]);

  return(
    <Page>
      <IconWrapper name={configState.brandName}>
        {(configState.brandName !== "audibel" && configState.brandName !== "nuear") &&
          <Icon name="starkey-hblb" width="13rem" height="7.5rem" />
        }
        {configState.brandName === "audibel" &&
          <Icon name="audibel" width="7rem" height="4.5rem" />
        }
        {configState.brandName === "nuear" &&
          <Icon name="nuear" width="7rem" height="4.5rem" />
        }
      </IconWrapper>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="fname">Patient First Name</Label>
          <Input
            type="text"
            name="fname"
            id="fname"
            value={assessmentState.patient.firstName}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="linitial">Patient Last Initial</Label>
          <Input
            type="text"
            name="linitial"
            id="linitial"
            value={assessmentState.patient.lastInitial}
            onChange={handleInputChange}
            maxLength="1"
          />
        </FormGroup>
        <FormGroup centered>
          <ButtonSubmit
            disabled={disabled}
            onClick={handleSubmit}
            text={'Submit'}
          />
        </FormGroup>
      </Form>
      <FinePrint>
        <p>Apple is a registered trademark of Apple Inc.</p>
        <p>Google Play is a trademark of Google, LLC.</p>
        <p>The Bluetooth® word mark is a registered trademark owned by Bluetooth SIG, Inc. and any use of such marks by Starkey is under license.</p>
        <p>Hearing Care Anywhere, Care365, Thrive, ThriveCare, Hear Better. Live Better. and the Starkey logo are registered trademarks of Starkey Laboratories, Inc. IntelliVoice is a trademark of Starkey Laboratories, Inc.</p>
      </FinePrint>
    </Page>
  );
};

export default Start;
