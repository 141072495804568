import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '72',
  height = '71.24',
  className = '',
  viewBox = '0 0 72 71.24'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M77.986,16.352A8.176,8.176,0,1,0,69.81,8.176,8.185,8.185,0,0,0,77.986,16.352Zm0-13.4a5.226,5.226,0,1,1-5.23,5.226A5.234,5.234,0,0,1,77.986,2.95Z"
      transform="translate(-41.982)"
      fill={fill}
    />
    <path
      d="M70.533,77.5h-21.1v-8.83A1.477,1.477,0,0,0,47.959,67.2H44.4a4.806,4.806,0,0,0,.446-2.117l-.395-23.858,9.228-7.2C56.617,32.271,57,29.353,55.86,27.38a4.711,4.711,0,0,0-6.569-1.539,40.638,40.638,0,0,0-6.111,4.245c-2.416,1.953-3.632,2.894-7.247,2.922-3.787-.02-5-1.044-7.2-2.9a35.406,35.406,0,0,0-5.983-4.261,4.723,4.723,0,0,0-6.617,1.618c-1.112,1.969-.722,4.819,2.145,6.514l8.73,7.227L26.664,65.11a4.086,4.086,0,0,0,.57,2.093H24.061a1.477,1.477,0,0,0-1.475,1.475v4.4H1.475A1.477,1.477,0,0,0,0,74.55V84.874a1.475,1.475,0,1,0,2.95,0v-8.85H22.578v8.85a1.475,1.475,0,1,0,2.95,0V70.153H46.48V84.874a1.475,1.475,0,1,0,2.95,0V80.462H69.055v4.413a1.475,1.475,0,1,0,2.95,0V78.987A1.472,1.472,0,0,0,70.533,77.5ZM20.059,31.63a1.833,1.833,0,0,0-.211-.147C18.4,30.661,18.3,29.617,18.7,28.915a1.774,1.774,0,0,1,2.6-.494,31.7,31.7,0,0,1,5.521,3.938c2.36,2,4.225,3.576,9.089,3.6,4.76.032,6.637-1.567,9.121-3.576a37.184,37.184,0,0,1,5.7-3.962,1.77,1.77,0,0,1,2.575.442c.407.7.319,1.762-1.292,2.739l-9.958,7.753a1.471,1.471,0,0,0-.57,1.188l.407,24.583a1.411,1.411,0,0,1-.682,1.367,3.384,3.384,0,0,1-3.093-.128,1.473,1.473,0,0,1-.877-1.256,1.385,1.385,0,0,0-.032-.291V52.035a1.473,1.473,0,1,0-2.946,0V65.106c0,1.475-1.276,1.7-2.037,1.7-1.264,0-2.619-.682-2.619-1.678l.355-24.591a1.488,1.488,0,0,0-.534-1.156Z"
      transform="translate(0 -15.106)"
      fill={fill}
    />
  </svg>
);

export default SVG;
