import React from 'react'
// Components
import Icon from '../../components/Icon';
// Style
import { Container } from './CheckBox.styles'

const CheckBox = ({
  checked,
  onClick
}) => {

  return(
    <Container checked={checked} onClick={onClick}>
      <Icon
        name={'checkmark'}
        fill={'#fff'}
        stroke={'transparent'}
        width={'80%'}
        height={'80%'}
      />
    </Container>
  );
}

export default CheckBox