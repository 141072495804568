import styled from 'styled-components';

export const Container = styled.div`
  margin: .5rem;
  display: flex;
  flex-direction: column;

  ${({ section }) => section==="outerEar" && `
    flex-basis: 27%;
  `}

  ${({ section }) => section==="middleEar" && `
    flex-basis: 14%;
  `}

  ${({ section }) => section==="innerEar" && `
    flex-basis: 25%;
  `}

  ${({ section }) => section==="auditoryNerveConnection" && `
    flex: 1;
  `}
`;

export const Title = styled.h2`
  font-size: .9rem;
  font-weight: 700;
  text-align: center;
  padding: 0 0 .45rem;
  border-bottom: .1875rem #CCC solid;
`;

export const SlideUpWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
`;

export const SlideUp = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(30,155,233,.71);

  ${({ active }) => active && `
    height: 100%;
    transition: height .7s .1s ease;
  `}
`;
