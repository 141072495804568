// Check for preview environment which is on the same
// Azure app registration as prod; return true if preview
const isPreview = () => {
  return window.location.href.indexOf('lifestyle-assessment-preview') !== -1;
}

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_B2C_AUTHORITY,
    knownAuthorities: [
      'https://devstarkeyb2c.b2clogin.com/',
      'https://teststarkeyb2c.b2clogin.com/',
      'https://stagestarkeyb2c.b2clogin.com/',
      'https://starkeyb2c.b2clogin.com/'
    ],
    redirectUri: isPreview() ? 'https://lifestyle-assessment-preview.azurewebsites.net/' : process.env.REACT_APP_B2C_REDIRECT_URI,
    navigateToLoginRequestUrl: false
  },
  system: {
      allowRedirectInIframe: true
  }
};

export const loginRequest = {};
