import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  > div {
    opacity: 0;
  }

  ${({ isOpen }) => isOpen && `
    width: 55rem;
    height: 55rem;
    transition: width .5s ease-in-out, height .5s ease-in-out;

    > div {
      opacity: 1;
      transition: opacity .5s .5s linear;
    }
  `}
`;

export const Content = styled.div`
  width: 45rem;
  height: 40rem;
`;
