import styled from 'styled-components';
import { colors as c } from '../../../../../../styles/colors';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  background: #eee;
`;

export const ControlWrapper = styled.div`
  height: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  position: relative;
  margin-top: -50px;
  z-index: 2;
  text-align: center;
`;

export const Control = styled.input`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  
  width: 350px;
  position: relative;
  -webkit-appearance: none;
  background: transparent;
  margin: 15px;

  &::before, &::after {
    content: "";
    position: relative;
    z-index: -1;
    display: inline-block;
    height: 9px;
    width: 9px;
    border-right: 2px solid ${c.grayLight1};
    border-bottom: 2px solid ${c.grayLight1};
  }

  &::before {
    -webkit-transform: rotate(135deg) translate(-7px, -8px);
    transform: rotate(135deg) translate(-7px, -8px);
  }
  &::after {
    -webkit-transform: rotate(45deg) translate(-8px, -7px);
    transform: rotate(-45deg) translate(-8px, -7px);
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: #b7b7b7;
    }

    &::-ms-fill-lower {
      background: ${c.grayLight1};
    }

    &::-ms-fill-upper {
      background: #b7b7b7;
    }
  }

  &::-webkit-slider-runnable-track {
    cursor: default;
    height: 2px;
    transition: all .2s ease;
    width: 100%;
    box-shadow: none;
    background: ${c.grayLight1};
    border: 0px solid #cfd8dc;
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    background: ${c.bluePrimary};
    border: 0px solid #eceff1;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: default;
    height: 24px;
    width: 24px;
    -webkit-appearance: none;
    margin-top: -10px;
  }

  &::-moz-range-track {
    box-shadow: none;
    cursor: default;
    height: 2px;
    transition: all .2s ease;
    width: 100%;
    background: ${c.grayLight1};
    border: 0px solid #cfd8dc;
    border-radius: 5px;
    height: 2px / 2;
  }

  &::-moz-range-thumb {
    box-shadow: none;
    background: ${c.bluePrimary};
    border: 0px solid #eceff1;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: default;
    height: 24px;
    width: 24px;
  }

  &::-ms-track {
    cursor: default;
    height: 2px;
    transition: all .2s ease;
    width: 100%;
    background: transparent;
    border-color: transparent;
    border-width: (24px / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    box-shadow: none;
    background: 9d9d9d;
    border: 0px solid #cfd8dc;
    border-radius: (5px * 2);
  }

  &::-ms-fill-upper {
    box-shadow: none;
    background: ${c.grayLight1};
    border: 0px solid #cfd8dc;
    border-radius: (5px * 2);
  }

  &::-ms-thumb {
    box-shadow: none;
    background: ${c.bluePrimary};
    border: 0px solid #eceff1;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: default;
    height: 24px;
    width: 24px;
    margin-top: 2px / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }

`;

export const ModalCloseButton = styled.button`
  z-index: 2;
  position: absolute;
  top: -25px;
  right: -25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${c.grayDark};
  border: 0;
  outline: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
  }
`;

export const RangeLabel = styled.span`
  font-size: 19px;
  font-weight: 700;
`;
