import styled, { css,keyframes } from 'styled-components';

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-.9rem);
  }
  100% {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  z-index: 5;
  position: absolute;
  display: none;
  padding: 1.25rem;
  cursor: pointer;
  text-align: center;
  user-select: none;

  ${({ visible }) => visible && `
    display: flex;
  `}

  ${({ position }) => (position==='right') && `
    right: 0;
  `}

  ${({ position }) => (position==='left') && `
    left: 0;
  `}

  ${({ navDirection }) => (navDirection==='next') && css`
    bottom: 0;
    animation-name: ${bounce};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-origin: bottom;
    flex-direction: column;
  `}

  ${({ navDirection }) => (navDirection==='prev') && `
    top: 0;
  `}
`;

export const Text = styled.span`
  font-size: 1.45rem;
  font-weight: 700;
  color: ${({ color }) => color};
`;

export const DownArrow = styled.div`
  
`;

export const LeftArrow = styled.div`
  display: inline-block;
  padding-right: .75rem;

  svg {
    transform: rotate(90deg);
  }
`;