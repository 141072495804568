import React from 'react';

const SVG = ({
  style = {},
  fill = '#4a4a4a',
  width = '32.323',
  height = '40.737',
  className = '',
  viewBox = '0 0 32.323 40.737'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path data-name="Path 28"
    d="M6.722.729V31.241l9.611-9.611a2.42,2.42,0,0,1,3.422,3.422L6.066,38.743a2.416,2.416,0,0,1-1.764.707,2.415,2.415,0,0,1-1.763-.707L-11.152,25.052a2.42,2.42,0,0,1,0-3.422,2.422,2.422,0,0,1,3.423,0l9.609,9.611V.729A2.247,2.247,0,0,1,4.3-1.287,2.247,2.247,0,0,1,6.722.729"
    transform="translate(11.86 1.287)" fill={fill} />
  </svg>
);

export default SVG;
