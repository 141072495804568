import { useContext,useEffect } from 'react';
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
// Functions
import { getAllCardsPerCategory } from '../../functions/api';
import { sortDataFromDB } from '../../functions/data';

const Data = () => {
  const { assessmentDispatch } = useContext(assessmentContext);

  useEffect(() => {
    getAllCardsPerCategory().then(res => {
      let sortedData = sortDataFromDB(res.CardList);

      return assessmentDispatch({
        type: 'MERGE_DB_DATA',
        payload: sortedData
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return null;
};

export default Data;
