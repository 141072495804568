import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.button`
  display: inline-block;
  background-color: ${c.bluePrimary};
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  padding: 1rem 3rem;
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;