import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  opacity: 0;
  left: -30rem; 
  top: -6.125rem;
  transition: opacity .25s linear;
  padding-top: 6.5rem;

  ${({ show }) => show && `
    opacity: 1;
    left: -6.125rem;
    transition: opacity .25s .5s linear;
  `}
`;

export const NavBackground = styled.div`
  height: 12.3rem;
  width: 12.4rem;
  top: 0;
  left: 0;
  border-radius: 50%;
  position: absolute;
  transition: all ease-in-out 0.3s;
  z-index: 150;
  background-color: rgba(89, 91, 96, 0.86);
  &.expanded {
    height: 89.3rem;
    width: 83.94rem;
    top: -13rem;
    border-radius: 50%;
    left: -51rem;
  }
`;

export const NavigationWrap = styled.div`
    height: 100vh;
    background-color: transparent;
    left: -500px;
    position: relative;
    transition: left 0.3s ease-in-out;
    z-index: 175;
    &.expanded {
      left: 0;
    }
    .navigation-link {
      display: block;
      position: relative;
      color: #fff;
      font-family: "DIN Next";
      font-size: 1.15rem;
      line-height: 1.25rem;
      font-weight: 100;
      text-decoration: none;
      &.isActive::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -1.25rem;
        top: .3rem;
        margin-right: 10px;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        background-color: #ffffff;
        border: 1px solid #fff;
      }
    }
`;

export const NavigationSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2.4rem;
    padding-left: 11rem;
`;

export const HamburgerMenuButton = styled.button`
    top: 0px;
    left: 0px;
    height: 60px;
    width: 60px;
    padding: 15px;
    display: block;
    border-width: 0;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    margin: 0;
    overflow: visible;
    position: absolute;
    z-index: 200;
    &:focus {
      outline: 0;
    }
    &::after {
      content: '';
      display: table;
      clear: both;
    }
`;

export const HamburgerBox = styled.span`
    width: 26px;
    height: 14px;
    display: inline-block;
    position: absolute;
    left: 7.7rem;
    top: 7.7rem;
`;

export const HamburgerInner = styled.span`
    display: block;
    top: 50%;
    margin-top: -2px;
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &,
    &::before,
    &::after {
      width: 1.6rem;
      height: 0.1rem;
      background-color: #fff;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
  
    &::before,
    &::after {
      content: '';
      display: block;
    }
  
    &::before {
      top: -0.5rem;
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }
  
    &::after {
      bottom: -0.5rem;
      transition: bottom 0.1s 0.25s ease-in,
        transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &.expanded {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  
      &,
      &::before,
      &::after {
        background-color: #fff;
      }
  
      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }
  
      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
          transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }

`;

export const NavigationUl = styled.ul`
  &> * + * {
    margin-top: .5rem;
  }
`;

export const NavigationHeading = styled.h1`
  font-size: 1.43rem;
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin-bottom: .6rem;
`;

const BaseButtonStyles = css`
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: .3125rem .5rem;
  color: #fff;
  text-decoration: none;
`

export const ResetLink = styled.a`
  ${BaseButtonStyles}
`;

export const SignOutLink = styled.a`
  ${BaseButtonStyles}
  margin-top: 8px;
`;
