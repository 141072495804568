export const ACCESSORIES_DATA = [
  {
    "id": "remote-mic-plus",
    "name": "Remote Microphone +",
    "text": "Enjoy one-on-one conversations in noisy environments and easily stream from a variety of audio sources.",
    "images": [
      "/images/accessories/remote-microphone-plus-1.png",
      "/images/accessories/remote-microphone-plus-2.png"
    ]
  },
  {
    "id": "mini-remote-mic",
    "name": "Mini Remote Microphone",
    "text": "Enjoy one-on-one conversations in noisy environments with this small microphone that clips onto the shirt of your conversation partner.",
    "images": [
      "/images/accessories/mini-remote-mic.png"
    ]
  },
  {
    "id": "remote",
    "name": "Remote",
    "text": "Our remote includes updated features so you can control memory and volume, mute your hearing aids and turn other special features on and off.",
    "images": [
      "/images/accessories/remote-1.png",
      "/images/accessories/remote-2.png"
    ]
  },
  {
    "id": "table-mic",
    "name": "Table Microphone",
    "text": "Designed for group settings like meetings, family gatherings or noisy restaurant environments. Utilizing eight built-in microphones, it will determine the primary speaker's voice and stream it directly to your hearing aids.",
    "images": [
      "/images/accessories/table-mic-1.png",
      "/images/accessories/table-mic-2.png"
    ]
  }
];