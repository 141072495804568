import styled from 'styled-components';

export const CheckList = styled.ul`
margin-bottom: 2.4rem;
`;

export const CheckListItem = styled.ul`
display: flex;
align-items: center;
margin-top: 1.1rem;
`;

export const ItemText = styled.span`
display: inline-block;
margin-left: 1.6rem;
font-size: 1.68rem;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 2rem;
  color: rgb(30, 155, 233);
`;

export const SubTitle = styled.p`
  font-size: 2.4rem;
`;