import React from 'react';
// Components
import { ButtonSlideDouble } from '../../../../../../components/ButtonSlideDouble';
import { DotToggle } from '../../../../../../components/DotToggle';
// Child Components
import { KebabNav } from './children/KebabNav';
// Data
import { AUDIOGRAM_DATA as data } from '../../../../../../data/audiogramData';
// Style
import { Container,GramToggles,PaneToggleWrapper,SoundsToggleWrapper,SpectrumToggleWrapper } from './Toggles.styles';

const Toggles = ({
  active,
  bananaToggled,
  iconsToggled,
  paneToggled,
  pinned,
  toggleBanana,
  toggleIcons,
  togglePane
}) => {

  return(
    <Container>
      <GramToggles>
        <SpectrumToggleWrapper onClick={toggleBanana}>
          <DotToggle toggled={bananaToggled} />
          <div>Speech Spectrum</div>
        </SpectrumToggleWrapper>
        <SoundsToggleWrapper onClick={toggleIcons}>
          <DotToggle toggled={iconsToggled} />
          <div>Common Sounds</div>
        </SoundsToggleWrapper>
      </GramToggles>
      <KebabNav active={active} items={data} pinned={pinned} />
      <PaneToggleWrapper>
        <ButtonSlideDouble
          items={['Audiogram','Simulation']}
          onClick={() => togglePane()}
          slid={paneToggled}
        />
      </PaneToggleWrapper>
    </Container>
  );
};

export default Toggles;
