import React from 'react'
// Style
import { Container } from './Button.styles'

const ButtonSubmit = ({
  disabled=false,
  text='button'
}) => {

  return(
    <Container
      disabled={disabled}
      role="button"
      type="submit"
    >
      {text}
    </Container>
  );
}

export default ButtonSubmit;