import React, { useState } from 'react';
// Components
import Icon from '../../../../components/Icon';
// Child Components
import { Modal } from './children/Modal';
// Functions
import parse from 'html-react-parser';
// Data
import { HOW_WE_HEAR_DATA as data } from '../../../../data/howWeHearData';
// Style
import {
  AnatomyWrapper,
  Container,
  EarSection, 
  EarSections,
  Image,
  ImageWrapper,
  Label,
  ModalButton,
  ModalPositioner,
} from './EarBrainAnatomy.styles';

const EarBrainAnatomy = () => {
  const [tinnitusModalOpen,setTinnitusModalOpen] = useState(false);
  const [balanceModalOpen,setBalanceModalOpen] = useState(false);

  const toggleModal = (name) => {
    if(name === 'tinnitus') {
      setTinnitusModalOpen(!tinnitusModalOpen);
    }
    if(name === 'balance') {
      setBalanceModalOpen(!balanceModalOpen);
    }
  }

  return(
    <Container>
      <AnatomyWrapper>
        <EarSections>
          {data.earSections.map((earSection,index) => {
            return <EarSection key={index} center={index === 4}>{parse(earSection)}</EarSection>
          })}
        </EarSections>
        <ImageWrapper>
          <Image src="/images/how-we-hear/ear-brain-anatomy.jpg" alt="ear brain anatomy" />
          {data.labels.map((label) => {
            return(
              <Label
                alt={`${label.slug} label`}
                key={label.slug}
                slug={label.slug}
                src={label.src}
              />
            )
          })}
          <ModalPositioner tinnitus invisible={balanceModalOpen}>
            <ModalButton
              active={tinnitusModalOpen}
              onClick={() => toggleModal('tinnitus')}
              tinnitus
            >
              <Icon name={'plus'} fill={'#fff'} />
            </ModalButton>
            <Modal active={tinnitusModalOpen} data={data.modals.tinnitus} />
          </ModalPositioner>
          <ModalPositioner balance invisible={tinnitusModalOpen}>
            <ModalButton
              active={balanceModalOpen}
              onClick={() => toggleModal('balance')}
              balance
            >
              <Icon name={'plus'} fill={'#fff'} />
            </ModalButton>
            <Modal active={balanceModalOpen} data={data.modals.balance} />
          </ModalPositioner>
        </ImageWrapper>
      </AnatomyWrapper>
    </Container>
  );
};

export default EarBrainAnatomy;
