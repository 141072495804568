import { useHistory } from 'react-router-dom';
// Auth
import { useMsal,useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import { useEffect } from 'react';

const Login = () => {
  const { accounts,inProgress,instance } = useMsal();
  const isAuthed = useIsAuthenticated();
  const history = useHistory();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  }

  useEffect(() => {

    // If MSAL is done doing things
    if(inProgress === 'none') {
      handleLogin(instance);
    }
  },[accounts,history,inProgress,instance,isAuthed]);

    return null;
};

export default Login;
