// IE 11 Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// React
import React, { useEffect,useReducer } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// Components
import { Data } from './components/Data';
import { Frame4x3 } from './components/Frame4x3';
// Context
import { AssessmentProvider } from './contexts/assessment/assessmentStore';
import { assessmentReducer,initialAssessmentData } from './contexts/assessment/assessmentReducer';
import { ConfigProvider } from './contexts/config/configStore';
import { configReducer,initialConfigData } from './contexts/config/configReducer';
// Auth
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
// Analytics
import ReactGA from 'react-ga';
// Style
import './index.css';

//Inialize MS Azure AD B2C
const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  const [assessmentState,assessmentDispatch] = useReducer(assessmentReducer,initialAssessmentData);
  const [configState,configDispatch] = useReducer(configReducer,initialConfigData);

  useEffect(() => {
    ReactGA.initialize('UA-23486989-3');
  },[]);

  return (
    <Router>
      <MsalProvider instance={msalInstance}>
        <ConfigProvider value={{ configState,configDispatch }}>
          <AssessmentProvider value={{ assessmentState,assessmentDispatch }}>
            <Data />
            <Frame4x3 />
          </AssessmentProvider>
        </ConfigProvider>
      </MsalProvider>
    </Router>
  );
};

render(<App />, document.getElementById('root'));