import React, { useContext } from 'react';
// Components
import { AssessmentError } from '../../components/AssessmentError';
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { Results } from './children/Results';
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
// Hooks
import { useStepsComplete } from '../../hooks/useStepsComplete';

const LifestyleAssessmentResults = () => {
  const { assessmentState } = useContext(assessmentContext);
  const allStepsComplete = useStepsComplete().every(v => v);

  return(
    <Page>
      <Progress
        active={allStepsComplete}
        goTo={'/hearing-challenges'}
        navDirection={'prev'}
        text={'Back'}
      />
      {allStepsComplete && <Results questions={assessmentState.assessment} />}
      {!allStepsComplete && <AssessmentError />}
      <Progress
        active={allStepsComplete}
        goTo={'/your-story'}
        navDirection={'next'}
        text={'Next'}
      />
    </Page>
  );
};

export default LifestyleAssessmentResults;
