import styled, { keyframes } from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 5.5rem;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 0;
  color: ${c.bluePrimary};
  padding-top: 3rem;
  text-align: center;
`;

export const Patient = styled.h2`
  font-size: 2.6rem;
  text-align: center;
`;

export const SectionTitle = styled.h2`
  font-size: 1.875rem;
  margin: 0 0 .25rem;
  color: ${c.bluePrimary};
`;

export const Columns = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
`;

export const Column = styled.div`
  flex: 1;
  padding: 1.5rem 1.5rem 0;
  display: flex;
  flex-direction: column;
  min-height: 26rem;
`;

export const SubSection = styled.div`
  margin-top: 5rem;
  background-color: ${c.grayLight2};
  padding: 1rem 1.5rem .5rem;
  border-radius: .3rem;
  margin-top: auto;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Item = styled.div`
  font-size: 1.125rem;
  padding: .5rem 0;

  &:not(:last-of-type) {
    border-bottom: 1px dashed #d8d9d6;
  }
`;

export const ItemTitle = styled.h3`
  display: inline-block;
  font-weight: 700;
  margin: 0;
`;

export const EmailButton = styled.button`
  display: inline-block;
  background-color: ${c.bluePrimary};
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  padding: 0 3rem;
  height: 3.375rem;
  border-radius: 3rem;
  border: .125rem solid ${c.bluePrimary};
  outline: none;
  cursor: pointer;

  &:disabled {
    color: #000;
    background-color: #fff;
    cursor: not-allowed;
  }
`;

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const WarningIcon = styled.img`
  width: 2rem;
`;

export const SpinnerIcon = styled.img`
  width: 2rem;
  animation: ${rotating} 1.3s linear infinite;
`;
