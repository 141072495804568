import styled from 'styled-components';

export const Container = styled.button`
  z-index: 4;
  position: absolute;
  bottom: 6.5rem;
  right: 13.8rem;
  width: 3rem;
  height: 3rem;
  padding: .8rem;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;
