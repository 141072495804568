import React, { useContext,useEffect,useState } from 'react';
import { CSSTransition,SwitchTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
// Components
import { ButtonSlideTriple } from '../../components/ButtonSlideTriple';
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { AppsPanel } from './children/AppsPanel';
import { CareExperiencePanel } from './children/CareExperiencePanel';
import { ResultsPanel } from './children/ResultsPanel';
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';
import configContext from '../../contexts/config/configStore';
// Functions
import { assembleAssessmentData } from '../../functions/data';
import {
  addAssessment,
  sendAppointmentSummary
} from '../../functions/api';
// Style
import {
  ButtonWrapper,
  EndButton,
  PanelsWrapper
} from './AppointmentSummary.styles';

const AppointmentSummary = () => {
  const { assessmentDispatch,assessmentState } = useContext(assessmentContext);
  const { configState } = useContext(configContext);
  const location = useLocation();
  const [activePane,setActivePane] = useState(0);
  const [emailButtonState, setEmailButtonState] = useState(1);

  const choose = (num) => {
    setActivePane(num);
  }

  useEffect(() => {
    if(location.state && location.state.active !== activePane) {
      setActivePane(location.state.active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  useEffect(() => {
    addAssessment(assembleAssessmentData(assessmentState, configState.brandName)).then(function(res2) {
      window.ga('set', 'dimension1', assessmentState.provider.id);
      window.ga('set', 'dimension2', res2);
      assessmentDispatch({
        type: 'SET_ASSESSMENT_ID',
        payload: res2
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const sendEmailHandler = () => {
    setEmailButtonState(1);
    sendAppointmentSummary(assessmentState.assessmentID, assessmentState.provider.email)
      .then(res => {
        console.log(res);
        // "Email sent successfully"
        // or
        // "Failure to send email"
        if (res === 'Email sent successfully') {
          setEmailButtonState(2);

          assessmentDispatch({
            type: 'SET_PROVIDER_EMAILSENTONCE',
            payload: true
          });
        } else {
          setEmailButtonState(3);
        }
      })
      .catch(err => {
        console.log(err);
        console.log('email no go');
        setEmailButtonState(3);
      });
  };

  useEffect(() => {
    if (assessmentState.provider.email === '') {
      console.warn(
        'No email address set for Provider. Must visit start page for global Provider data to be set'
      );
      setEmailButtonState(3);
    }

    if (assessmentState.assessmentID.length === 36) {
      setEmailButtonState(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentState.assessmentID]);

  return(
    <Page>
      <Progress visible goTo={'/accessories'} navDirection={'prev'} text={'Back'} />
      <PanelsWrapper>
        <SwitchTransition>
          <CSSTransition
            key={activePane}
            classNames='panel'
            timeout={250}
          >
            <>
              {activePane === 0 &&
                <ResultsPanel
                  emailButtonState={emailButtonState}
                  sendEmailHandler={sendEmailHandler}
                />
              }
              {activePane === 1 && <CareExperiencePanel />}
              {activePane === 2 && <AppsPanel />}
            </>
          </CSSTransition>
        </SwitchTransition>
      </PanelsWrapper>
      <ButtonWrapper>
        <ButtonSlideTriple
          active={activePane}
          choose={choose}
          items={['Results','Care Experience','Apps']}
        />
      </ButtonWrapper>
      <EndButton href="/">End</EndButton>
    </Page>
  );
};

export default AppointmentSummary;
