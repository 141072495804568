import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 2.5rem;

  span {
    color: ${c.bluePrimary};
  }
`;

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
`;

export const Option = styled.div`
  height: 17.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ rowsOf }) => rowsOf === 'three' && `
    padding: 0 4.13rem;
    flex: 0 33.33%;
    flex-basis: auto;
    width: 33.33%;
  `}

  ${({ rowsOf }) => rowsOf === 'four' && `
    padding: 0 2rem;
    flex: 0 25%;
    flex-basis: auto;
    width: 25%;
  `}
`;

export const Name = styled.div`
  font-family: 'DIN Next';
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 1rem;
  text-align: center;
  width: 100%;
`;