import React, { useContext,useEffect,useState } from 'react';
// Components
import Icon from '../../../../components/Icon';
import { CheckBox } from '../../../../components/CheckBox';
// Context
import assessmentContext from '../../../../contexts/assessment/assessmentStore';
// Data
import { HEARING_STYLES_DATA as data } from '../../../../data/hearingStylesData';
// Style
import {
  Container,
  DropDown,
  DropName,
  DropArrow,
  ModelChoices,
  ModelChoice,
  Rechargeable,
  RechText,
  StyleChoiceBox,
  StyleChoices,
  StyleChoice,
  StyleText,
  Title
} from './LeftColumn.styles';
import { colors as c } from '../../../../styles/colors';

const LeftColumn = ({
  activeModel,
  activeStyle,
  hide,
  rechargeableChecked,
  setActiveModel,
  setActiveStyle,
  setRechargeableChecked
}) => {
  const [styleBoxOpen,setStyleBoxOpen] = useState(false);
  const { assessmentDispatch } = useContext(assessmentContext);

  useEffect(() => {
    assessmentDispatch({
      type: 'SET_HEARING_AID_STYLE',
      payload: data.styles[activeStyle].name + (rechargeableChecked ? " Rechargeable" : "")
    });
  },[activeStyle,assessmentDispatch,rechargeableChecked]);

  const chooseStyle = (i) => {
    if(!(rechargeableChecked && !data.styles[i].hasRechargeable)) {
      if(!data.styles[i].hasRechargeable) {
        setRechargeableChecked(false);
      }
      setActiveStyle(i);
      setStyleBoxOpen(!styleBoxOpen);
    }
  }

  const chooseRechargeable = () => {
    data.styles[activeStyle].hasRechargeable && setRechargeableChecked(!rechargeableChecked);
  }

  return(
    <Container hide={hide}>
      <Title>Style</Title>
        <StyleChoices>
          <DropDown onClick={() => setStyleBoxOpen(!styleBoxOpen)}>
            <DropName>{data.styles[activeStyle].shortName}</DropName>
            <DropArrow open={styleBoxOpen}>
              <Icon
                name={'chevronDown'}
                fill={c.bluePrimary}
                width={'40%'}
                height={'40%'}
              />
            </DropArrow>
          </DropDown>
          <StyleChoiceBox open={styleBoxOpen}>
            {data.styles.map((style,index) => {
              return(
                <StyleChoice
                  disabled={rechargeableChecked && !data.styles[index].hasRechargeable}
                  key={index}
                  onClick={() => chooseStyle(index)}
                >
                  <CheckBox checked={activeStyle === index} /><StyleText>{style.shortName}</StyleText>
                </StyleChoice>
              )
            })}
          </StyleChoiceBox>
        </StyleChoices>
        <Rechargeable
          disabled={!data.styles[activeStyle].hasRechargeable}
          onClick={chooseRechargeable}
        >
          <CheckBox checked={rechargeableChecked} />
          <RechText>Rechargeable</RechText>
        </Rechargeable>
      <Title>Model</Title>
      <ModelChoices>
        {data.styles[activeStyle].modelThumbs.map((model,index) => {
          return(
            <ModelChoice key={index} active={activeModel === index} onClick={() => setActiveModel(index)}>
              <img src={model} alt="model thumbnail" />
            </ModelChoice>
          )
        })}
      </ModelChoices>
    </Container>
  );
};

export default LeftColumn;
