import styled from 'styled-components';

export const Choices = styled.div`
  width: 95%;
  display: flex;
  margin-bottom: 2.4rem;
`;

export const Choice = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 3.4rem;
  flex: 1;
`;

export const Question = styled.p`
  font-size: 1.9rem;
  margin-bottom: 2.4rem;
  opacity: 0;
  transition: opacity .5s .25s linear;

  ${({ show }) => show && `
    opacity: 1;
  `}
`;

export const Value = styled.div`
  font-family: 'Mr Eaves XL Sans';
  font-size: 1.7rem;
  margin-top: .8rem;
`;