export const MENUDOT_DATA = {
    "todays-visit": "todays-visit",
    "self-descriptor": "lifestyle-assessment",
    "favorite-activities": "lifestyle-assessment",
    "wellness-concerns": "lifestyle-assessment",
    "healthy-living-goals": "lifestyle-assessment",
    "hearing-challenges": "lifestyle-assessment",
    "lifestyle-assessment-results": "lifestyle-assessment-results",
    "your-story": "lifestyle-assessment-results",
    "how-we-hear": "how-we-hear",
    "understanding-sound": "understanding-sound",
    "hearing-evaluation": "hearing-evaluation",
    "hearing-loss": "hearing-results",
    "audiogram": "audiogram",
    "types-of-hearing-loss": "about-your-hearing",
    "impacts-of-hearing-loss": "impacts-of-hearing-loss",
    "technology-levels": "your-best-fit",
    "hearing-aid-transition": "every-ear-unique",
    "hearing-aid-styles": "hearing-aid-styles",
    "accessories": "accessories",
    "appointment-summary": "appointment-summary"
  }
  