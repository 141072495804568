import React, { useRef,useState } from 'react';
import ReactPlayer from 'react-player/file';
// Components
import Icon from '../../../../../../components/Icon';
// Style
import {
  Container,
  Control,
  ControlWrapper,
  RangeLabel,
  ModalCloseButton,
} from './HairCells.styles';

const HairCells = ({ setModalOpen }) => {
  const vidRef = useRef(null);
  const [playerState,setPlayerState] = useState({
    url: null,
    pip: false,
    playing: true,
    controls: true,
    light: false,
    volume: 0,
    muted: true,
    played: 0.0001,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  });

  const handleSeekChange = (e) => {
    setPlayerState({
      ...playerState,
      played: parseFloat(e.target.value)
    });
    vidRef.current.seekTo(parseFloat(e.target.value));
  };

  return(
    <Container>
      <ReactPlayer
        ref={vidRef}
        url={[{
          src: '/videos/hair-cells/ear-hair-cells.mp4',
          type: 'video/mp4'
        }]}
      />
      <ControlWrapper>
        <RangeLabel>Healthy</RangeLabel>
        <Control
          id="imageRange-head"
          type="range"
          min={0}
          max={0.99}
          step="any"
          value={playerState.played}
          onChange={handleSeekChange}
        />
        <RangeLabel>Damaged</RangeLabel>
      </ControlWrapper>
      <ModalCloseButton onClick={() => setModalOpen(false)}>
        <Icon name={'plus'} fill={'#fff'} />
      </ModalCloseButton>
    </Container>
  );
};

export default HairCells;
