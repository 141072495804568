import React, { useState } from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { BottomBar } from './children/BottomBar';
import { LeftColumn } from './children/LeftColumn';
import { ModelZoom } from './children/ModelZoom';
import { RightColumn } from './children/RightColumn';
import { ZoomButton } from './children/ZoomButton';
// Style
import { Title } from './HearingAidStyles.styles';

const HearingAidStyles = () => {
  const [activeModel, setActiveModel] = useState(0);
  const [activeStyle, setActiveStyle] = useState(0);
  const [zoomed, setZoomed] = useState(false);
  const [rechargeableChecked,setRechargeableChecked] = useState(false);

  return(
    <Page>
      <Progress visible goTo={'/hearing-aid-transition'} navDirection={'prev'} text={'Back'} />
      <Title hide={zoomed}>Hearing aid styles</Title>
      <LeftColumn
        activeModel={activeModel}
        activeStyle={activeStyle}
        hide={zoomed}
        rechargeableChecked={rechargeableChecked}
        setActiveModel={setActiveModel}
        setActiveStyle={setActiveStyle}
        setRechargeableChecked={setRechargeableChecked}
      />
      <ModelZoom
        activeModel={activeModel}
        activeStyle={activeStyle}
        zoomed={zoomed}
      />
      <RightColumn
        activeStyle={activeStyle}
        hide={zoomed}
        rechargeableChecked={rechargeableChecked}
      />
      <ZoomButton
        setZoomed={setZoomed}
        zoomed={zoomed}
      />
      <BottomBar activeStyle={activeStyle} />
      <Progress visible goTo={'/accessories'} navDirection={'next'} text={'Select'} />
    </Page>
  );
};

export default HearingAidStyles;
