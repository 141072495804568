/*eslint-disable no-unused-vars*/
import React, { useContext,useEffect,useState } from 'react';
/*eslint-enable no-unused-vars*/
// Context
import assessmentContext from '../../contexts/assessment/assessmentStore';

const  useStepsComplete = () => {
  const { assessmentState } = useContext(assessmentContext);
  const [stepsComplete,setStepsComplete] = useState([]);

  useEffect(() => {
    let tempArr = [];
    assessmentState.assessment.forEach((item) => {
      tempArr.push(item.selectedTiles.reduce((a, b) => a + b, 0) === 3);
    })
    setStepsComplete(tempArr);
  },[assessmentState])

  return stepsComplete;
}

export default useStepsComplete;
