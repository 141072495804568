import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
`;

export const Line = styled.span`
  position: absolute;
  transition: .3s;
  background: #000;

  &:first-of-type {
    top: 25%;
    bottom: 25%;
    width: 10%;
    left: 45%;

    ${({ toggled }) => toggled && `
      transform: rotate(90deg);
    `}
  }

  &:last-of-type {
    left: 25%;
    right: 25%;
    height: 10%;
    top: 45%;

    ${({ toggled }) => toggled && `
      left: 50%;
      right: 50%;
    `}
  }
`;
