import React, { useEffect, useState } from 'react';
// Data
import { HEARING_STYLES_DATA as data } from '../../../../../../data/hearingStylesData';
// Style
import {
  Container,
  Color,
  ColorInput,
  ColorName,
  ImageWrapper,
  Rotate360,
  SliderWrapper,
  Slider,
  Swatches
} from './HearingAid360.styles';

const HearingAid360 = ({
  activeStyleIndex,
  rangeVal = 0,
  rechargeableChecked,
  setRangeVal
}) => {
  const [activeColor,setActiveColor] = useState(data.styles[activeStyleIndex].colors.standard[0]);
  const stdOrRech = rechargeableChecked ? "rechargeable" : "standard";

  // If the active color does not exist for the newly-selected style,
  // set the active color to the first in the new style's list.
  useEffect(() => {
    if(!data.styles[activeStyleIndex].colors[stdOrRech].includes(activeColor)) {
      setActiveColor(data.styles[activeStyleIndex].colors[stdOrRech][0]);
    }
  },[activeColor,activeStyleIndex,stdOrRech]);

  // Preload hearing aid images
  useEffect(() => {
    function loadImage(style,type,color,num) {
      const img = new Image();
      img.src = "/images/hearing-aid-styles/360s/"+style+"/"+type+"/"+color+"/0_"+num+".png";
    }

    const style = data.styles[activeStyleIndex];

    if(!rechargeableChecked && style.colors.standard.includes(activeColor)) {
      for(let i=0; i<=17; i++) {
        loadImage(style.shortName.toLowerCase(),"standard",activeColor,i);
      }
    }

    if(rechargeableChecked && style.colors.rechargeable.includes(activeColor)) {
      for(let j=0; j<=17; j++) {
        loadImage(style.shortName.toLowerCase(),"rechargeable",activeColor,j);
      }
    }
  },[activeColor,activeStyleIndex,rechargeableChecked]);

  const updateRange = (e) => {
    setRangeVal(e.target.value);
  }

  const updateColor = (color) => {
    setActiveColor(color)
  }

  return(
    <Container>
      <ImageWrapper model={data.styles[activeStyleIndex].shortName.toLowerCase()}>
        <img src={`/images/hearing-aid-styles/360s/${data.styles[activeStyleIndex].shortName.toLowerCase()}/${rechargeableChecked ? "rechargeable" : "standard"}/${activeColor}/0_${rangeVal}.png`} alt="hearing aid" />
      </ImageWrapper>
      <Rotate360>Rotate 360&deg;</Rotate360>
      <SliderWrapper>
        <Slider
          id="range"
          type="range"
          min="0"
          max="17"
          value={rangeVal}
          step="1"
          onChange={updateRange}
        />
      </SliderWrapper>
      <Swatches>
        {data.styles[activeStyleIndex].colors.standard.map((color) => {
          return (
            <Color
              key={color}
              title={color}
            >
              <ColorInput
                type="radio"
                name={color}
                onClick={() => updateColor(color)} value={color}
              />
              <ColorName
                active={color === activeColor}
                color={color}
              >
                {color}
              </ColorName>
            </Color>
          )
        })}
      </Swatches>
    </Container>
  );
};

export default HearingAid360;
