import React from 'react';
// Components
import { CheckCircle } from '../../../../components/CheckCircle';
// Functions
import { calculateBenefitsTotals } from '../../../../functions/data';
// Style
import { Title, SubTitle, CheckList,CheckListItem,ItemText } from './Results.styles';

const Results = ({ questions }) => {
  const benefitsTotals = calculateBenefitsTotals(questions);

   return(
    <>
      <Title>Thank you!</Title>
      <SubTitle>Based on your answers, we know that:</SubTitle>
      <CheckList>
        <CheckListItem>
          <CheckCircle />
          <ItemText>You are {benefitsTotals[0] > 0 ? 'active' : 'quiet'}</ItemText>
        </CheckListItem>
        <CheckListItem>
          <CheckCircle />
          <ItemText>
            {benefitsTotals[1] > 0
            ? 'Enjoy spending time with others'
            : 'Engaging in healthy activities'}
          </ItemText>
        </CheckListItem>
        <CheckListItem>
          <CheckCircle />
          <ItemText>
            Are concerned about staying{' '}
            {benefitsTotals[2] > 0
            ? 'physically active'
            : 'mentally sharp'}
          </ItemText>
        </CheckListItem>
      </CheckList>
    </>
  );
}

export default Results;