import React from 'react';
// Child Components
import { HairCells } from './children/HairCells';
// Style
import { Container,Overlay } from './Modal.styles';

const Modal = ({ setModalOpen }) => {

  return(
    <Container>
      <Overlay onClick={() => setModalOpen(false)} />
      <HairCells setModalOpen={setModalOpen} />
    </Container>
  );
};

export default Modal;
