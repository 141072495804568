import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '66.03',
  height = '74.65',
  className = '',
  viewBox = '0 0 66.03 74.65'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-901.984 -265.677)">
      <path
        d="M.348,1.22A1.621,1.621,0,0,1-1.283-.392V-2.886A1.621,1.621,0,0,1,.348-4.5,1.621,1.621,0,0,1,1.979-2.886V-.392A1.621,1.621,0,0,1,.348,1.22"
        transform="translate(923.768 305.709)"
        fill={fill}
      />
      <path
        d="M.348,1.22A1.621,1.621,0,0,1-1.283-.392V-2.886A1.621,1.621,0,0,1,.348-4.5,1.621,1.621,0,0,1,1.979-2.886V-.392A1.621,1.621,0,0,1,.348,1.22"
        transform="translate(944.023 305.709)"
        fill={fill}
      />
      <path
        d="M4.237,2.242A1.621,1.621,0,0,1,2.606.63c0-3.128-3.357-5.675-7.483-5.675S-12.36-2.5-12.36.63a1.622,1.622,0,0,1-1.631,1.612A1.622,1.622,0,0,1-15.623.63c0-4.906,4.82-8.9,10.745-8.9S5.868-4.275,5.868.63A1.621,1.621,0,0,1,4.237,2.242"
        transform="translate(939.855 326.546)"
        fill={fill}
      />
      <path
        d="M6.95,10.015c-7.832,0-21.07-8.179-23.8-13.515-1.007-1.975-1.617-7.194-2.253-13.3-.038-.369-.074-.722-.111-1.058A4.953,4.953,0,0,1-23.154-19.7c-2.994-3.552-2.887-11.945-1.7-15.14a2.7,2.7,0,0,1,1.975-1.979,9.491,9.491,0,0,1,5.292.653,1.6,1.6,0,0,1,.59,2.2,1.639,1.639,0,0,1-2.23.582A6.951,6.951,0,0,0-21.81-33.7c-.681,1.776-1.092,8.541.905,11.588.684,1.046,1.345,1.094,1.917.994.1-.017.188-.033.263-.044a1.867,1.867,0,0,1,.178-.1,1.657,1.657,0,0,1,1.522,0,1.613,1.613,0,0,1,.862,1.237c.092.767.2,1.767.313,2.9.384,3.7,1.1,10.576,1.919,12.182C-11.707-.594.527,6.793,6.95,6.793S25.608-.594,27.833-4.951c.819-1.6,1.532-8.46,1.917-12.146.118-1.144.222-2.158.317-2.934a1.606,1.606,0,0,1,.864-1.236,1.644,1.644,0,0,1,1.519,0,1.454,1.454,0,0,1,.169.1c.075.014.165.029.272.047a1.742,1.742,0,0,0,1.916-.994c2-3.046,1.588-9.811.905-11.589a6.968,6.968,0,0,0-2.591.323A1.663,1.663,0,0,1,30.9-34a1.579,1.579,0,0,1,.582-2.166,9.536,9.536,0,0,1,5.3-.653,2.7,2.7,0,0,1,1.97,1.978c1.195,3.194,1.3,11.588-1.694,15.141a4.869,4.869,0,0,1-3.948,1.834c-.037.347-.075.713-.113,1.094-.636,6.093-1.243,11.3-2.247,13.267C28.02,1.838,14.782,10.015,6.95,10.015m26.141-30.7a1.7,1.7,0,0,1,.322.917,1.547,1.547,0,0,0-.322-.917"
        transform="translate(928.483 330.307)"
        fill={fill}
      />
      <path
        d="M10.976,4.3a1.664,1.664,0,0,1-.262-.02c-1.258-.2-1.442-1.163-1.805-3.08C8.232-2.391,6.69-10.548,1.7-12.428c-7.381,7.876-16.982,6.61-24.747,5.584-4.989-.66-9.3-1.228-11.668.709-1.49,1.218-2.31,3.5-2.5,6.972a1.7,1.7,0,0,1-1.719,1.519,1.619,1.619,0,0,1-1.539-1.7c.248-4.419,1.45-7.453,3.681-9.275,3.467-2.837,8.668-2.153,14.179-1.422C-15-9.035-6.393-7.9-.081-15.3a1.631,1.631,0,0,1,1.589-.538C9.32-14.188,11.28-3.816,12.117.612c.1.542.177.969.245,1.234a1.58,1.58,0,0,1,.222,1.1A1.624,1.624,0,0,1,10.976,4.3m.263-3.2s0,0,0,0,0,0,0,0m-.006,0h0"
        transform="translate(949.174 297.146)"
        fill={fill}
      />
      <path
        d="M12.408,7.632a1.638,1.638,0,0,1-1.2-.515c-2.328-2.478,2.86-12.348,2.919-12.44a7.34,7.34,0,0,0-.964-9.608c-1.931-1.639-4.307-1.476-6.52.44a1.643,1.643,0,0,1-2.011.107,1.6,1.6,0,0,1-.582-1.907,2.9,2.9,0,0,0-.168-2.844C1.617-22.644-7.75-24.4-15.081-24.84c-9.362-.559-18.577,1.921-23.5,6.32-5.757,5.155-4.644,8.724.029,14.778.784,1.017,1.651,3.449,2.989,8.392.075.276.13.481.16.583a1.6,1.6,0,0,1-.322,1.636,1.651,1.651,0,0,1-2.3.116c-.367-.329-.422-.531-.685-1.5-1.5-5.523-2.167-6.928-2.433-7.274-4.337-5.619-7.883-11.725.376-19.119,5.54-4.954,15.717-7.767,25.886-7.149,5.278.317,17.94,1.647,21.522,7.189A5.94,5.94,0,0,1,7.5-18.735a7.592,7.592,0,0,1,7.791,1.36A10.549,10.549,0,0,1,16.88-3.589c-1.091,1.688-3.14,7.4-3.053,8.812A1.6,1.6,0,0,1,13.519,7.2a1.642,1.642,0,0,1-1.111.43m1.2-2.708s0,0,0,0,0,0,0,0"
        transform="translate(947.74 293.819)"
        fill={fill}
      />
    </g>
  </svg>
);

export default SVG;
