import React from 'react'
import { useHistory } from 'react-router-dom';
// Components
import Icon from '../../components/Icon';
// Style
import {
  Container,
  DownArrow,
  LeftArrow,
  Text
} from './Progress.styles'
import { colors as c } from '../../styles/colors';

const Progress = ({
  active=0,
  className,
  color=c.bluePrimary,
  icon = "chevronDown",
  goTo,
  height = '1.5rem',
  navDirection = 'next',
  position = 'right',
  text = 'Next',
  visible = true,
  width = '1.1rem'
}) => {
  const history = useHistory();

  const go = () => {
    history.push({
      pathname: goTo,
      state: { active: active }
    });
  }

   return(
    <Container className={className} visible={visible} navDirection={navDirection} onClick={go} position={position}>
    {navDirection === 'prev' &&
      <LeftArrow>
        <Icon name={icon} fill={color} width={width} height={height} />
      </LeftArrow>
    }
      <Text color={color}>{text}</Text>
      {navDirection === 'next' &&
        <DownArrow>
          <Icon name={icon} fill={color} width={width} height={height} />
        </DownArrow>
      }
    </Container>
   )
}

export default Progress;