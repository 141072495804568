export const colors = {
  bluePrimary: "#1e9be9",
  blueLight: "#e9f5fd",
  grayDark: "#595b60",
  grayMed: "#d6d9d8",
  grayish: "#c7c8c9",
  grayLight1: "#aaaaaa",
  grayLight2: "#f3f4f4",
  rainbow: [
    "#34c84a",
    "#7dc446",
    "#a5c144",
    "#d5be42",
    "#fdbc40",
    "#f29b3f",
    "#eb863e",
    "#de603c",
    "#d23c3b"
  ]
}