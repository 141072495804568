import styled, { keyframes } from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 2rem;
  color: ${c.bluePrimary};
  margin-bottom: 2.4rem;
`;

export const EarAnatomyWrapper = styled.div`
  width: 47.625rem;
  height: 26rem;
  margin-bottom: 4rem;
`;

export const ButtonWrapper = styled.div`
  width: 47.625rem;
`;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(167, 168, 171, 1);
  }
  40% {
    box-shadow: 0 0 0 .9375rem rgba(208, 214, 216, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(28, 171, 228, 0);
  }
`;

export const ModalOpenButton = styled.button`
  z-index: 2;
  position: absolute;
  bottom: 12.6rem;
  left: 28.9rem;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  background-color: ${c.grayDark};
  border: 0;
  outline: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${pulse} 1.5s infinite ease-in-out;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
