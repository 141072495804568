import React, { useEffect,useState } from 'react';
// Auth
import { useMsal } from "@azure/msal-react";
// Style
import { Message } from './SSOLogout.styles';

const SSOLogout = () => {
  const [done,setDone] = useState(false);
  const { instance } = useMsal();

  // Front-channel logout
  useEffect(() => {
    instance.logoutRedirect({
      onRedirectNavigate: () => {
        setDone(true);
        // Return false to stop navigation after local logout
        return false;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return(
    <Message>
      Single Sign-Out 
      {done ? ' Success' : ' Pending...'}
    </Message>
  );
};

export default SSOLogout;
