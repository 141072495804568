import styled, { keyframes } from 'styled-components';
import { colors as c } from '../../styles/colors';

export const ButtonWrapper = styled.div`
  width: 47.625rem;
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const PanelsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .panel-enter {
    opacity: 0;
  }

  .panel-enter-active {
    opacity: 1;
    transition: opacity 250ms;
  }

  .panel-exit {
    opacity: 1;
  }

  .panel-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
`;

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-.9rem);
  }
  100% {
    transform: translateY(0);
  }
`;

export const EndButton = styled.a`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2rem;
  display: inline-block;
  font-size: 1.45rem;
  font-weight: 700;
  color: ${c.bluePrimary};
  animation: ${bounce} 2s ease-in-out infinite;
  text-decoration: none;
`;
