import React, { useState } from 'react';
// Components
import { Page } from '../../components/Page';
import { Progress } from '../../components/Progress';
// Child Components
import { Accordion } from './children/Accordion';
import { Swipers } from './children/Swipers';
// Data
import { ACCESSORIES_DATA as data } from '../../data/accessoriesData';
// Style
import {
  ContentWrapper,
  LeftColumn,
  RightColumn,
  Title
} from './Accessories.styles';

const Accessories = () => {
  const [active,setActive] = useState('');

  return(
    <Page>
      <Progress visible goTo={'/hearing-aid-styles'} navDirection={'prev'} text={'Back'} />
      <Title>Wireless accessories</Title>
      <ContentWrapper>
        <LeftColumn>
          <Accordion active={active} items={data} setActive={setActive} />
        </LeftColumn>
        <RightColumn>
          <Swipers active={active} items={data} />
        </RightColumn>
      </ContentWrapper>
      <Progress visible active={0} goTo={'/appointment-summary'} navDirection={'next'} text={'Select'} />
    </Page>
  );
};

export default Accessories;
