import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '70.71',
  height = '70.71',
  className = '',
  viewBox = '0 0 70.71 70.71'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(13669.859 -2974.114)">
      <g transform="translate(-13669.357 2974.643)">
        <path
          d="M11.286,14.659C-.648,14.66-15.18,5.576-23.857-3.1c-8.93-8.931-18.289-24.062-17.736-36.181a17.013,17.013,0,0,1,6.2-12.828c2.825-2.352,9.064-1.939,9.767-1.886a1.206,1.206,0,0,1,1.016.728C-21.082-45-16.88-34.885-16.88-33.631c0,.439,0,1.772-5.868,7.64a1.349,1.349,0,0,1-.145.123c-.109.094-.751.756.3,2.339a1.2,1.2,0,0,1,.155.336C-21.473-19.81-6.881-5.168-3.85-4.541a1.206,1.206,0,0,1,.421.177c1.581,1.047,2.247.408,2.363.269.022-.027.075-.09.1-.116,5.867-5.867,7.2-5.867,7.639-5.867,1.255,0,11.369,4.2,19.636,7.728a1.206,1.206,0,0,1,.728,1.016c.055.7.467,6.943-1.886,9.768a17.011,17.011,0,0,1-12.828,6.2c-.342.015-.689.023-1.037.023m-38.619-66.3c-2.083,0-5.157.256-6.516,1.386a14.687,14.687,0,0,0-5.334,11.085c-.556,12.159,9.881,27.214,17.03,34.365C-15.184,2.162-.715,12.249,11.271,12.249c.315,0,.631-.008.943-.023A14.693,14.693,0,0,0,23.3,6.892C24.576,5.355,24.73,1.534,24.67-.426,16.847-3.753,8.257-7.264,6.713-7.643c-.89.392-3.7,2.873-5.92,5.083-.727.908-2.675,2.016-5.382.315C-9.133-3.621-23.2-18.253-24.694-22.334c-1.726-2.727-.617-4.686.295-5.417,2.231-2.237,4.734-5.078,5.094-5.943-.287-1.275-3.852-10-7.227-17.935-.243-.009-.512-.013-.8-.013"
          transform="translate(41.616 54.055)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13669.357 2974.643)">
        <path
          d="M11.286,14.659C-.648,14.66-15.18,5.576-23.857-3.1c-8.93-8.931-18.289-24.062-17.736-36.181a17.013,17.013,0,0,1,6.2-12.828c2.825-2.352,9.064-1.939,9.767-1.886a1.206,1.206,0,0,1,1.016.728C-21.082-45-16.88-34.885-16.88-33.631c0,.439,0,1.772-5.868,7.64a1.349,1.349,0,0,1-.145.123c-.109.094-.751.756.3,2.339a1.2,1.2,0,0,1,.155.336C-21.473-19.81-6.881-5.168-3.85-4.541a1.206,1.206,0,0,1,.421.177c1.581,1.047,2.247.408,2.363.269.022-.027.075-.09.1-.116,5.867-5.867,7.2-5.867,7.639-5.867,1.255,0,11.369,4.2,19.636,7.728a1.206,1.206,0,0,1,.728,1.016c.055.7.467,6.943-1.886,9.768a17.011,17.011,0,0,1-12.828,6.2C11.981,14.651,11.634,14.659,11.286,14.659Zm-38.619-66.3c-2.083,0-5.157.256-6.516,1.386a14.687,14.687,0,0,0-5.334,11.085c-.556,12.159,9.881,27.214,17.03,34.365C-15.184,2.162-.715,12.249,11.271,12.249c.315,0,.631-.008.943-.023A14.693,14.693,0,0,0,23.3,6.892C24.576,5.355,24.73,1.534,24.67-.426,16.847-3.753,8.257-7.264,6.713-7.643c-.89.392-3.7,2.873-5.92,5.083-.727.908-2.675,2.016-5.382.315C-9.133-3.621-23.2-18.253-24.694-22.334c-1.726-2.727-.617-4.686.295-5.417,2.231-2.237,4.734-5.078,5.094-5.943-.287-1.275-3.852-10-7.227-17.935C-26.775-51.637-27.044-51.641-27.333-51.641Z"
          transform="translate(41.616 54.055)"
          fill="none"
          stroke={fill}
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
