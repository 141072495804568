import React from 'react';

const SVG = ({
  style = {},
  fill = '#AAAAAA',
  width = '51.59',
  height = '76.47',
  className = '',
  viewBox = '0 0 51.59 76.47'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(13459.796 -2967.433)">
      <g transform="translate(-13439.628 3034.39)">
        <path
          d="M1.047,2.03A5.268,5.268,0,0,1-2.586.693C-3.177.178-3.861-.683-3.861-2.757A4.334,4.334,0,0,1-2.489-6.1,4.7,4.7,0,0,1,1.047-7.485,4.436,4.436,0,0,1,4.43-6.139,4.432,4.432,0,0,1,5.776-2.757,4.128,4.128,0,0,1,4.489.7,4.925,4.925,0,0,1,1.047,2.03"
          transform="translate(3.861 7.485)"
          fill={fill}
        />
      </g>
      <g transform="translate(-13459.796 2967.433)">
        <path
          d="M5.209,12.78a3.092,3.092,0,0,1-2.336-.868,4.449,4.449,0,0,1-.831-3.019A18.314,18.314,0,0,1,3.269,1.8,18.165,18.165,0,0,1,6.625-3.466c1.618-1.745,3.856-3.876,6.643-6.319,2.6-2.275,4.536-4.044,5.751-5.255A21.661,21.661,0,0,0,22.6-19.785a13.437,13.437,0,0,0,1.7-6.614,14.911,14.911,0,0,0-4.925-11.2A17.182,17.182,0,0,0,7.31-42.018c-5.6,0-9.9,1.572-12.8,4.67-2.521,2.686-4.551,6.4-6.2,11.358-1.175,3.9-2.784,3.9-3.744,3.9a3.391,3.391,0,0,1-2.65-1.094,3.063,3.063,0,0,1-1.119-2.193,17.694,17.694,0,0,1,3.022-9.32c2.042-3.264,4.808-6.905,8.825-9.1A29.61,29.61,0,0,1,7-47.126a31,31,0,0,1,13.537,2.792,23.42,23.42,0,0,1,8.907,8.127,17.145,17.145,0,0,1,2.939,9.689,14.5,14.5,0,0,1-1.566,6.942,23.371,23.371,0,0,1-4.163,5.625c-1.865,1.824-5.264,4.941-10.092,9.261a46.705,46.705,0,0,0-3.633,3.636A17.282,17.282,0,0,0,10.52,2.385,16.84,16.84,0,0,0,9.285,5.492c-.233.834-.582,2.274-1.047,4.339C7.77,12.38,6.93,12.78,5.209,12.78"
          transform="translate(19.207 47.126)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
