import React from 'react';

const SVG = ({
  style = {},
  fill = '#1e9be9',
  width = '27.74',
  height = '38.47',
  className = '',
  viewBox = '0 0 27.74 38.47'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(109.493 -617.103)">
      <path
        d="M-95.1,637.6h0v-.4A.934.934,0,0,0-95.1,637.6Z"
        transform="translate(1.269 1.774)"
        fill={fill}
      />
      <path
        d="M-106.821,644.783a1.248,1.248,0,0,1-1.245-1.246V640.8h10.307l-.433-1.426h-9.874V621.128h14.233v16.2a2.1,2.1,0,0,1,.317-.139,2.057,2.057,0,0,1,1.11-.011v-17.4A2.675,2.675,0,0,0-95.08,617.1h-11.742a2.675,2.675,0,0,0-2.672,2.671v23.763a2.675,2.675,0,0,0,2.672,2.672h10.7l-.432-1.426Zm0-26.255H-95.08a1.241,1.241,0,0,1,1.232,1.174h-14.2A1.241,1.241,0,0,1-106.821,618.528Z"
        transform="translate(0 0)"
        fill={fill}
      />
      <path
        d="M-100.207,640.018h-2.549a.713.713,0,0,0-.713.713.713.713,0,0,0,.713.713h2.549a.713.713,0,0,0,.713-.713A.713.713,0,0,0-100.207,640.018Z"
        transform="translate(0.532 2.023)"
        fill={fill}
      />
      <path
        d="M-82.673,645.337l-2.5-8.224a2.079,2.079,0,0,0-2.59-1.383,2.066,2.066,0,0,0-.959.618,2.082,2.082,0,0,0-2.238-.705,2.081,2.081,0,0,0-.959.618,2.06,2.06,0,0,0-1.127-.715v1.2a.948.948,0,0,1,.408.52l1.321,4.353a.561.561,0,0,0,.7.373.56.56,0,0,0,.373-.7l-1.027-3.387a.957.957,0,0,1,.637-1.193.958.958,0,0,1,1.193.638l1.027,3.387a.561.561,0,0,0,.7.372.561.561,0,0,0,.373-.7l-.735-2.418a.959.959,0,0,1,.638-1.194.959.959,0,0,1,1.193.638l2.5,8.224a5.514,5.514,0,0,1-3.67,6.869,6.709,6.709,0,0,1-5.149-.508l-8.156-4.356a.858.858,0,0,1-.422-.516.867.867,0,0,1,.576-1.081,1.978,1.978,0,0,1,.9-.057l3.87.66a.574.574,0,0,0,.258-.016.544.544,0,0,0,.256-.165.564.564,0,0,0,.116-.532l-.422-1.393c-.044,0-.085.013-.13.013h-1.038l.255.838-2.976-.508a3.088,3.088,0,0,0-1.416.09,1.989,1.989,0,0,0-1.321,2.483,1.971,1.971,0,0,0,.965,1.175l8.155,4.355a7.826,7.826,0,0,0,6,.59A6.634,6.634,0,0,0-82.673,645.337Z"
        transform="translate(0.635 1.629)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
      <path
        d="M-98.711,638.879l1.209,3.982h1.171l-1.209-3.982Z"
        transform="translate(0.952 1.923)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
      <path
        d="M-95.8,638.879l.756,2.489v-2.489Z"
        transform="translate(1.209 1.923)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
      <path
        d="M-99.022,634.193A.957.957,0,0,1-98.385,633a.958.958,0,0,1,1.194.637l1.347,4.438h1.186a.934.934,0,0,1,0-.4v-1.648a2.014,2.014,0,0,0-.53.362l-.934-3.074a2.078,2.078,0,0,0-2.591-1.382,2.077,2.077,0,0,0-1.383,2.589l1.08,3.557h1.171Z"
        transform="translate(0.822 1.301)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
      <path
        d="M-94.787,635.563a2.1,2.1,0,0,0-.317.139v1.648a.944.944,0,0,1,.642-.715.947.947,0,0,1,.785.118v-1.2A2.06,2.06,0,0,0-94.787,635.563Z"
        transform="translate(1.27 1.623)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
      <path
        d="M-96,643.95l-.429-1.413H-97.6l.432,1.426h1.038C-96.085,643.963-96.044,643.952-96,643.95Z"
        transform="translate(1.05 2.246)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
      <path
        d="M-97.938,637.569h-1.171l.433,1.425h1.171Z"
        transform="translate(0.917 1.807)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
      <path
        d="M-95.763,638.995h.755v2.489l.255.839a.561.561,0,0,0,.7.373.56.56,0,0,0,.373-.7L-95,637.643c-.008-.024,0-.049-.008-.074H-96.2Z"
        transform="translate(1.174 1.807)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export default SVG;
