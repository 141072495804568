// Data
import { ACCESSORIES_DATA as accessoriesData } from '../data/accessoriesData';
import { FAKE_DATA as fakeData } from '../data/fakeAssessmentData';
import { choicesData as struggleScaleData } from '../data/struggleScaleData';
import { HEARING_LOSS_LEVELS_DATA as hearingLossChoiceData } from '../data/hearingLossLevelsData';
// Functions
import { commaSeparatedListUtility, searchArrayOfObjects } from './helpers';

/**
 * Create array of booleans for 'Impacts of Hearing Loss'
 * @param {object} questionX Question X
 * @param {object} questionY Question Y
 * @return {array} array The array of calculated Impacts
 */
export const calculateImpactsTotals = (data) => {
  let impactsTotals = [];
  data.forEach((item,index) => {
    let count = 0;
    item.selectedTiles.forEach((tile,tileIndex) => {
      if(tile === 1) {
        count += item.options[tileIndex].benefit;
      }
    });
    
    impactsTotals.push(count);
  });

  return [
    (impactsTotals[1] > 0 || impactsTotals[1] < 0),
    (impactsTotals[1] < 0),
    (impactsTotals[1] > 0),
    (impactsTotals[1] > 0 && impactsTotals[2] < 0),
    (
      impactsTotals[1] < 0 ||
      // 'Being at rist of falling' is selected
      data[2].selectedTiles[4] === 1
    )
  ];
}

/**
 * Create array of calculated 'Impacts of Hearing Loss' based on 2 questions
 * @param {object} questionX Question X
 * @param {object} questionY Question Y
 * @return {array} array The array of calculated Impacts
 */
export const createImpactsArray = (data) => {
  let array = [];
  //     (benefitsTotals[1] > 0 || benefitsTotals[1] < 0),
  //     (benefitsTotals[1] < 0),
  //     (benefitsTotals[1] > 0),
  //     (benefitsTotals[1] > 0 && benefitsTotals[2] < 0),
  //     (
  //       benefitsTotals[1] < 0 ||
  //       // 'Being at rist of falling' is selected
  //       assessmentState.assessment[2].selectedTiles[4]
  //     )

  let benefitsArray = calculateImpactsTotals(data)
  
  if (benefitsArray[0]) array.push('Brain Health / Cognition');

  if (benefitsArray[1]) array.push('Physical Health');

  if (benefitsArray[2]) array.push('Social Interaction');

  if (benefitsArray[3]) array.push('Depression / Isolation');

  if (benefitsArray[4]) array.push('Balance / Safety');

  return array;
};

/**
 * Calculate the Benefits totals for 'Lifestyle Assessment Results'
 * @param {object} data Questions data
 * @return {array} array The array of calculated benefits
 */
export const calculateBenefitsTotals = (data) => {
  let benefitsTotals = [];
  data.forEach((item,index) => {
    let count = 0;
    item.selectedTiles.forEach((tile,tileIndex) => {
      if(tile === 1) {
        count += item.options[tileIndex].benefit;
      }
    });
    benefitsTotals.push(count);
  });

  return benefitsTotals;
}

/**
 * Sort data from DB by SortOrder on Categories, then on Cards
 * @param {object} data The unsorted data from the DB
 * @return {object} sortedData The sorted data
 */
export function sortDataFromDB(data) {
  let sortedData = [];

  // For each Category...
  for (let i = 0; i < data.length; i++) {
    let cat = data[i];
    let cards = cat.Cards;
    let sortedCards = [];

    // For the length of Cards...
    for (let j = 0; j < cards.length; j++) {
      // Find Card SortOrder that matches j
      sortedCards.push(searchArrayOfObjects(cards, j + 1, 'SortOrder'));
    }

    // Replace the sorted list of Cards into the data
    data[i].Cards = sortedCards;
  }

  // For the length of Categories...
  for (let k = 0; k < data.length; k++) {
    // Find Card SortOrder that matches k
    sortedData.push(searchArrayOfObjects(data, k + 1, 'SortOrder'));
  }

  return sortedData;
}

/**
 * Assemble Patient data to send to DB
 * @param {object} data The assessmentState
 * @return {object} assebledData The assembled Patient data
 */
export function assemblePatientData(data) {
  const assembledData = {
    FirstName: data.patient.firstName,
    LastInitial: data.patient.lastInitial,
    BirthYear: 1234,
    EmailAddress: 'none',
    CreatedIP: data.provider.ip,
    ModifiedIP: data.provider.ip,
    ProviderID: data.provider.id
  };
  return assembledData;
}

/**
 * Get assessment ID initially using fake data
 * @param {object} data The assessmentState
 * @return {object} assebledData The assembled Assessment data
 */
export function assembleFakeAssessmentData(profileID,providerID) {
  let assembledFakeData = {
    ProfileID: profileID,
    ProviderID: providerID,
    FrustrationLevel: "zzz",
    HearingLossChoice: "zzz",
    HearingLossType: "zzz",
    HearingAidStyle: "zzz",
    Accessory: "zzz",
    ImpactsOfHearingLoss: "zzz",
    TechnologyLevel: "zzz",
    CardList: fakeData.cardsData,
    Brand: "zzz"
  };

  return assembledFakeData;
}

/**
 * Assemble Assessment data to send to DB
 * @param {object} data The assessmentState
 * @return {object} assebledData The assembled Assessment data
 */
export function assembleAssessmentData(data, brand) {
  /////
  // 1. Create Card choices array
  /////
  let cardsData = [];
  // For each category...
  for (let i = 0; i < data.assessment.length; i++) {
    let category = data.assessment[i];
    let cardsList = { CategoryID: category.guid };
    
    let cardIndex = 1;
    // For each answer...
    for (let j = 0; j < category.selectedTiles.length; j++) {
      if(category.selectedTiles[j] === 1) {
        cardsList['Card' + (cardIndex)] = category.options[j].guid;
        cardIndex++;
      }
    }

    cardsData.push(cardsList);
  }

  /////
  // 2. Create the Accessories list
  /////
  let accessoriesString = '';
  let accessoriesArr = [];

  data.accessories.forEach((accy,index) => {
    if(accy) {
      accessoriesArr.push(accessoriesData[index].name);
    }
  });

  if (accessoriesArr.length) {
    accessoriesString = commaSeparatedListUtility(accessoriesArr);
  } else {
    accessoriesString = 'None';
  }

  /////
  // 3. Create the Impacts list
  /////
  let impactsString = '';

  const impactsArr = createImpactsArray(data.assessment);

  if (!(impactsArr === undefined) && impactsArr.length > 0) {
    impactsString = impactsArr
      .map(item => item)
      .reduce((prev, curr) => prev + ', ' + curr);
  } else {
    impactsString = 'None';
  }

  /////
  // 4. Create the final data object
  /////
  const assembledData = {
    ProfileID: data.patient.id,
    ProviderID: data.provider.id,
    FrustrationLevel: struggleScaleData[data.frustrationLevel].text,
    HearingLossChoice: hearingLossChoiceData[data.hearingLossLevel].text,
    HearingLossType: data.hearingLossType.type,
    HearingAidStyle: data.hearingAidStyle,
    Accessory: accessoriesString,
    ImpactsOfHearingLoss: impactsString,
    TechnologyLevel: data.technologyLevel,
    CardList: cardsData,
    Brand: brand
  };

  /////
  // 3.1. Add AssessmentID to assembledData if assessmentID already exists
  /////
  if (data.assessmentID !== 0) {
    assembledData.AssessmentID = data.assessmentID;
  }

  return assembledData;
}

/**
 * Filter Assessment data for Lifestyle Needs panel
 * @param {object} data The assessmentState
 * @return {object} assebledData The assembled Lifestyle Needs data
 */
export function filterLifestyleData(data) {
  let assembledData = [];
  data.assessment.map((item) => {
    let tempArr = [];
    item.selectedTiles.map((tile,index) => {
      if(tile === 1) tempArr.push(item.options[index])
      return null;
    })
    assembledData.push(
      {
        title: item.question,
        answers: tempArr
      }
    );
    return null;
  });

  return assembledData;
};
