export const HEARING_STYLES_DATA = {
  "styles": [
    {
      "name": "Receiver-in-Canal (RIC)",
      "shortName": "RIC",
      "hasRechargeable": true,
      "description": "Small, discreet behind-the-ear device",
      "clothes": "clothes1",
      "models": [
        [
          "/images/hearing-aid-styles/models/ric/model1-ric-a.jpg",
          "/images/hearing-aid-styles/models/ric/model1-ric-b.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/ric/model2-ric-a.jpg",
          "/images/hearing-aid-styles/models/ric/model2-ric-b.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/ric/model3-ric-a.jpg",
          "/images/hearing-aid-styles/models/ric/model3-ric-b.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/ric/model4-ric-a.jpg",
          "/images/hearing-aid-styles/models/ric/model4-ric-b.jpg"
        ]
      ],
      "modelThumbs": [
        "/images/hearing-aid-styles/models/thumbs/model1-clothes1-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model2-clothes1-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model3-clothes1-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model4-clothes1-thumb.jpg"
      ],
      "styleImage": "/images/hearing-aid-styles/styles/ric.png",
      "colors": {
        "standard": [
          "black",
          "bronze",
          "champagne",
          "espresso",
          "slate",
          "sterling",
          "white"
        ],
        "rechargeable": [
          "black",
          "bronze",
          "champagne",
          "espresso",
          "slate",
          "sterling",
          "white"
        ]
      }
    },
    {
      "name": "Behind-the-Ear (BTE)",
      "shortName": "BTE",
      "hasRechargeable": true,
      "description": "Available in standard, mini or power.",
      "clothes": "clothes1",
      "models": [
        [
          "/images/hearing-aid-styles/models/bte/model1-bte-a.jpg",
          "/images/hearing-aid-styles/models/bte/model1-bte-b.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/bte/model2-bte-a.jpg",
          "/images/hearing-aid-styles/models/bte/model2-bte-b.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/bte/model3-bte-a.jpg",
          "/images/hearing-aid-styles/models/bte/model3-bte-b.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/bte/model4-bte-a.jpg",
          "/images/hearing-aid-styles/models/bte/model4-bte-b.jpg"
        ]
      ],
      "modelThumbs": [
        "/images/hearing-aid-styles/models/thumbs/model1-clothes1-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model2-clothes1-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model3-clothes2-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model4-clothes1-thumb.jpg"
      ],
      "styleImage": "/images/hearing-aid-styles/styles/bte.png",
      "colors": {
        "standard": [
          "black",
          "bronze",
          "champagne",
          "espresso",
          "slate",
          "sterling",
          "white"
        ],
        "rechargeable": [
          "black",
          "bronze",
          "champagne",
          "espresso",
          "slate",
          "sterling",
          "white"
        ]
      }
    },
    {
      "name": "In-the-Canal (ITC)",
      "shortName": "ITC",
      "hasRechargeable": true,
      "description": "Custom hearing aid that fits in ear canal",
      "clothes": "clothes2",
      "models": [
        [
          "/images/hearing-aid-styles/models/itc/model1-itc-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/itc/model2-itc-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/itc/model3-itc-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/itc/model4-itc-a.jpg"
        ]
      ],
      "modelThumbs": [
        "/images/hearing-aid-styles/models/thumbs/model1-clothes2-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model2-clothes2-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model3-clothes3-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model4-clothes2-thumb.jpg"
      ],
      "styleImage": "/images/hearing-aid-styles/styles/itc.png",
      "colors": {
        "standard": [
          "black",
          "chestnut",
          "dark-brown",
          "light-brown",
          "medium-brown",
          "pink"
        ],
        "rechargeable": [
          "black",
          "chestnut",
          "dark-brown",
          "light-brown",
          "medium-brown",
          "pink"
        ]
      }
    },
    {
      "name": "Completely-in-Canal (CIC)",
      "shortName": "CIC",
      "hasRechargeable": false,
      "description": "Second smallest custom device",
      "clothes": "clothes3",
      "models": [
        [
          "/images/hearing-aid-styles/models/cic/model1-cic-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/cic/model2-cic-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/cic/model3-cic-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/cic/model4-cic-a.jpg"
        ]
      ],
      "modelThumbs": [
        "/images/hearing-aid-styles/models/thumbs/model1-clothes3-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model2-clothes3-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model3-clothes4-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model4-clothes3-thumb.jpg"
      ],
      "styleImage": "/images/hearing-aid-styles/styles/cic.png",
      "colors": {
        "standard": [
          "black",
          "chestnut",
          "dark-brown",
          "light-brown",
          "medium-brown",
          "pink"
        ]
      }
    },
    {
      "name": "Invisible-in-the-Canal (IIC)",
      "shortName": "IIC",
      "hasRechargeable": false,
      "description": "Smallest, least visible style",
      "clothes": "clothes3",
      "models": [
        [
          "/images/hearing-aid-styles/models/iic/model1-iic-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/iic/model2-iic-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/iic/model3-iic-a.jpg"
        ],
        [
          "/images/hearing-aid-styles/models/iic/model4-iic-a.jpg"
        ]
      ],
      "modelThumbs": [
        "/images/hearing-aid-styles/models/thumbs/model1-clothes3-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model2-clothes3-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model3-clothes4-thumb.jpg",
        "/images/hearing-aid-styles/models/thumbs/model4-clothes3-thumb.jpg"
      ],
      "styleImage": "/images/hearing-aid-styles/styles/iic.png",
      "colors": {
        "standard": [
          "black",
          "dark-brown",
          "light-brown",
          "medium-brown",
          "pink"
        ]
      }
    }
  ]
}