import React from 'react';
// Functions
import parse from 'html-react-parser';
// Style
import {
  Container,
  Text,
  Title
} from './Modal.styles';

const Modal = ({
  active,
  data
}) => {

  return(
    <Container active={active}>
      <Title active={active}>{data.title}</Title>
      <Text active={active}>
        {parse(data.text)}
      </Text>
    </Container>
  );
};

export default Modal;
