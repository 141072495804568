import React from 'react';
// Style
import { Container } from './ZoomButton.styles';

const ZoomButton = ({ setZoomed,zoomed }) => {

  return(
    <Container onClick={() => setZoomed(!zoomed)}>
      <img
        src={`/images/icons/zoom-${zoomed ? 'out' : 'in'}-icon.svg`}
        alt={`zoom`}
      />
    </Container>
  );
};

export default ZoomButton;
