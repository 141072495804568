import React from 'react';

const SVG = ({
  style = {},
  fill = '#4a4a4a',
  width = '9.252',
  height = '5.441',
  className = '',
  viewBox = '0 0 9.252 5.441'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path
   data-name="Path 30679"
    d="M.228,5.209a.786.786,0,0,1,0-1.108L4.113.228A.783.783,0,0,1,5.193.2L9.02,4.02a.782.782,0,1,1-1.1,1.108L4.628,1.889,1.336,5.213A.783.783,0,0,1,.228,5.209Z"
    transform="translate(9.252 5.441) rotate(180)"
    fill={fill} />
  </svg>
);

export default SVG;
