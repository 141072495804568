import React from 'react';
import styled from 'styled-components';
// Functions
import parse from 'html-react-parser';
// Style
import { Description,Disclaimer,SlideWrapper } from '../../Slide.styles';

const S1Image = styled.img`
  width: 26rem;
  height: auto;
  margin-top: 5.5rem;
  margin-bottom: 1rem;
`;

const S1Description = styled(Description)`
  margin-bottom: 6rem;
`;

export const Slide1 = () => {

  return(
    <SlideWrapper>
      <S1Image src="/images/impacts/30percent-40percent.svg" alt="30 to 40 percent" />
      <S1Description>{parse('Adults with untreated hearing loss<br />experience a <strong>30-40% faster decline<br />in cognitive abilities</strong>')}</S1Description>
      <Disclaimer>{parse('<strong>Source:</strong> Lin, F. R., Yaffe, K., Xia, J., Xue, Q., Harris, T. B., Purchase-Helzner, E., … Simonsick, E. M.<br />(2013). Hearing Loss and Cognitive Decline in Older Adults. JAMA Internal Medicine,173(4), 293-299.<br />doi:10.1001/jamainternmed.2013.1868')}</Disclaimer>
    </SlideWrapper>
  )
}

const S2Description = styled(Description)`
  margin: 3rem 0 1rem;
`;

const S2Image = styled.img`
  width: 26.5625rem;
  height: auto;
  margin-bottom: 5rem;
`;

export const Slide2 = () => {
  return(
    <SlideWrapper>
      <S2Description>{parse('<strong>Adults with untreated hearing loss are<br />more likely to develop dementia.</strong>')}</S2Description>
      <S2Image src="/images/impacts/cognition-graph.svg" alt="cognition graph" />
      <Disclaimer>{parse('<strong>Source:</strong> Jphns Hopkins Medicine')}</Disclaimer>
    </SlideWrapper>
  )
}

const S3Description1 = styled(Description)`
  margin: 3.5rem 0 1rem;
`;

const S3Image = styled.img`
  width: 18rem;
  height: auto;
  margin-bottom: .5rem;
`;

const S3Description2 = styled(Description)`
  margin-bottom: 8rem;
`;

export const Slide3 = () => {
  return(
    <SlideWrapper>
      <S3Description1>{parse('<strong>Wearing hearing aids can help with brain health.</strong>')}</S3Description1>
      <S3Image src="/images/impacts/brain-dumbbell-circle.svg" alt="brain dumbbell circle" />
      <S3Description2>{parse('Participating in coversations exercises<br />the brain, which prevents atrophy and<br />loss of brain matter.')}</S3Description2>
      <Disclaimer>{parse('<strong>Source:</strong> National Institute of Aging')}</Disclaimer>
    </SlideWrapper>
  )
}