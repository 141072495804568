import React from 'react';
// Style
import { Container,Option,Options,Slider } from './ButtonSlideDouble.styles';

const ButtonSlideDouble = ({
  items = ['item1','item2'],
  onClick,
  slid
}) => {

  return(
    <Container onClick={onClick}>
      <Slider slid={slid} />
      <Options>
        <Option active={!slid}>{items[0]}</Option>
        <Option active={slid}>{items[1]}</Option>
      </Options>
    </Container>
  );
};

export default ButtonSlideDouble;
