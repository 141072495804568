import styled from 'styled-components';
import { colors as c } from '../../../../styles/colors';

export const Title = styled.h1`
  height: 13%;
  padding-top: 2.125rem;
  font-size: 2.4rem;
  color: ${c.bluePrimary};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 87%;
  display: flex;
  flex-direction: column;
`;
