import React from 'react';
// Style
import {
  AppIcon,
  AppIcons,
  Container,
  Disclaimer,
  Image,
  Screen,
  Screens,
  SubTitle,
  Title
} from './ThriveCareSlide.styles';

const ThriveCareSlide = () => {

  return(
    <Container>
      <Title>ThriveCare</Title>
      <SubTitle>For the people who care about you</SubTitle>
      <Screens>
        <Screen>
          <Image src="/images/appointment-summary/thrivecare-home-screen.png" alt="thrivecare home screen" />
          <SubTitle>Home screen</SubTitle>
        </Screen>
        <Screen>
          <Image src="/images/appointment-summary/thrivecare-steps-screen.png" alt="thrivecare steps screen" />
          <SubTitle>Steps</SubTitle>
        </Screen>
        <Screen>
          <Image src="/images/appointment-summary/thrivecare-use-screen.png" alt="thrivecare use screen" />
          <SubTitle>Use</SubTitle>
        </Screen>
      </Screens>
      <AppIcons>
        <AppIcon src="/images/appointment-summary/apple-app-store-icon.png" alt="apple app store icon" />
        <AppIcon src="/images/appointment-summary/google-play-store-icon.png" alt="google play store icon" />
      </AppIcons>
      <Disclaimer>* Works with the Thrive Hearing Control app.</Disclaimer>
      <Disclaimer>Exclusive to AI hearing aids.</Disclaimer>
    </Container>
  );
};

export default ThriveCareSlide;
