import React from 'react';
// Components
import { Page } from '../../components/Page';
import { Picker } from '../../components/Picker';
import { Progress } from '../../components/Progress';
// Hooks
import { useStepsComplete } from '../../hooks/useStepsComplete';

const HearingChallenges = () => {
  const complete = useStepsComplete()[4];

  return(
    <Page>
      <Progress visible goTo={'/healthy-living-goals'} navDirection={'prev'} text={'Back'} />
      <Picker question={4}>
        5. Please select the top 3 options that represent<br />where you <span>struggle the most to hear</span>.
      </Picker>
      <Progress
        visible={complete}
        goTo={'/lifestyle-assessment-results'}
        navDirection={'next'}
        text={'Select'}
      />
    </Page>
  );
};

export default HearingChallenges;
