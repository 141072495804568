import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 0;
  color: ${c.bluePrimary};
  flex-basis: 17%;
  padding-top: 4.3rem;
`;

export const ContentWrapper = styled.div`
  width: 85%;
  flex: 1;
  display: flex;
  padding-bottom: 6rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LeftColumn = styled(Column)`
  flex-basis: 47%;
  padding-right: 2rem;
`;

export const RightColumn = styled(Column)`
  flex-basis: 53%;
`;
