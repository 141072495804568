import React from 'react';

const SVG = ({
  style = {},
  fill = '#506162',
  width = '78.27577',
  height = '76.58208',
  className = '',
  viewBox = '0 0 78.27577 76.58208'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M19.48254,30.792l.08765-.00025a15.26659,15.26659,0,1,0-.08765.00025ZM6.80432,15.24584a12.65164,12.65164,0,1,1-.001.3031Q6.80189,15.39732,6.80432,15.24584Z"
      fill={fill}
      stroke={fill}
      strokeWidth={.5}
    />
    <path
      d="M52.35217,34.46666A14.345,14.345,0,1,0,52.47741,5.777l-.02368-.00012a14.29982,14.29982,0,0,0-14.3833,14.21607l-.00049.0664A14.34506,14.34506,0,0,0,52.35217,34.46666Zm.03638-26.13171a11.739,11.739,0,0,1,11.74194,11.736V20.071A11.748,11.748,0,0,1,52.43737,31.81285l-.043.00013a11.739,11.739,0,1,1-.00586-23.478Z"
      fill={fill}
      stroke={fill}
      strokeWidth={.5}
    />
    <path
      d="M55.86651,57.06505a1.181,1.181,0,0,0-1.181,1.181v16.905a1.181,1.181,0,1,0,2.362,0v-16.905A1.181,1.181,0,0,0,55.86651,57.06505Z"
      fill={fill}
      stroke={fill}
      strokeWidth={.5}
    />
    <rect
      x="67.35247"
      y="56.97203"
      width="2.25098"
      height="19.33301"
      rx="1.125"
      fill={fill}
      stroke={fill}
      strokeWidth={.5}
    />
    <path
      d="M77.9096,47.52208a9.79965,9.79965,0,0,0-5.574-9.041,2.54576,2.54576,0,0,1-.87988-.759c-2.80591-3.813-4.87793-4.517-9.334-2.882-7.3,2.677-14.77905,3.887-22.489,2.538-5.3-.927-10.51392-2.29-15.80005-3.274a45.45961,45.45961,0,0,0-8.448-.89C6.34881,33.25.4638,39.047.32269,48.099.18158,57.092.28778,66.09.29681,75.08592v.012a1.233,1.233,0,0,0,1.23291,1.233h.05493a1.293,1.293,0,0,0,1.292-1.294V74.01c0-8.523-.03393-17.046.012-25.568.041-7.324,4.208-11.89,11.4751-12.61694a14.32942,14.32942,0,0,1,3.88891.078c4.654.829,9.30493,1.691,13.9209,2.71,6.093,1.344,12.229,2.613,18.45288,1.6a135.697,135.697,0,0,0,13.219-3.261c2.21509-.594,3.979-.1,5.093,1.71,1.17993,1.917.9729,3.578-.823,5.233A15.55015,15.55015,0,0,1,65.23748,45.944c-10.8999,6.17-22.68408,6.918-34.79,5.594-2.13111-.234-2.551.037-2.55494,2.166-.009,5.522-.00708,15.121-.00488,21.234a1.36809,1.36809,0,0,0,1.36792,1.368h.00317a1.37109,1.37109,0,0,0,1.3711-1.37085V54.50707H47.05975v20.347a1.37305,1.37305,0,0,0,1.251,1.4,1.322,1.322,0,0,0,1.39209-1.24805c.00122-.02393.002-.04785.002-.0719V73.977c0-6.156.02393-12.311-.022-18.46594-.009-1.147.28906-1.63,1.5061-1.835a45.40866,45.40866,0,0,0,14.376-4.9c2.98291-1.631,5.842-3.382,6.8-7.2a8.66808,8.66808,0,0,1,3.033,7.1q.00805,12.78406,0,25.568V74.977A1.334,1.334,0,0,0,76.436,76.297a1.31386,1.31386,0,0,0,1.55664-1.01477,1.29735,1.29735,0,0,0,.02832-.26928c.00562-.77784.00562-1.52991.00366-2.28089C78.00067,64.332,78.07269,55.92308,77.9096,47.52208Z"
      fill={fill}
      stroke={fill}
      strokeWidth={.5}
    />
  </svg>
);

export default SVG;
