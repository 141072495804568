import React from 'react'
// Components
import Icon from '../../components/Icon';
// Style
import { Container } from './CheckCircle.styles'

const CheckCircle = () => {

  return(
    <Container>
      <Icon name={'checkmark'} />
    </Container>
  );
}

export default CheckCircle