import React from 'react';
// Style
import { Container,Bite } from './KebabNav.styles';

const KebabNav = ({ active,items,pinned }) => {

  return(
    <Container>
      {items.map(({slug},index) => {
        return <Bite key={slug} active={active===index} pinned={pinned===index} />
      })}
    </Container>
  );
};

export default KebabNav;
