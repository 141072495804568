// Data
import { QUESTION_SUPPLEMENT_DATA as questionSupplement } from '../../data/questionSupplementData';

export const initialAssessmentData = {
  accessories: [false,false,false,false,false],
  assessment: [],
  assessmentID: 0,
  frustrationLevel: 0,
  hearingAidStyle: "",
  hearingLossLevel: 1,
  hearingLossType: {
    active: 0,
    type: ""
  },
  patient: {
    firstName: "",
    lastInitial: "",
    id: ""
  },
  provider: {
    email: '',
    id: '',
    ip: '',
    emailSentOnce: false
  },
  technologyLevel: "Best"
};

export const assessmentReducer = (state = initialAssessmentData, action) => {

  switch (action.type) {

    case 'SET_ACCESSORIES':
      return {
        ...state,
        accessories: action.payload
      };

    case 'SET_ASSESSMENT_ID':
      return {
        ...state,
        assessmentID: action.payload
      };

    case 'SET_ASSESSMENT_QUESTION_COMPLETED':
      return {
        ...state,
        assessment: [...state.assessment].map((item, index) => {
          if (index !== action.payload.id) return item;
          return {
            ...item,
            completed: action.payload.data
          };
        })
      };

    case 'SET_ASSESSMENT_ACTIVE_TILE':
      return {
        ...state,
        assessment: [...state.assessment].map((item, index) => {
          if (index !== action.payload.id) return item;
          return {
            ...item,
            selectedTiles: action.payload.data
          };
        })
      };

    case 'SET_HEARING_LOSS':
      return {
        ...state,
        hearingLoss: action.payload
      };

    case 'SET_HEARING_LOSS_LEVEL':
      return {
        ...state,
        hearingLossLevel: action.payload
      };

    case 'SET_HEARING_LOSS_TYPE':
      return {
        ...state,
        hearingLossType: action.payload
      };

    case 'SET_FRUSTRATION_LEVEL':
      return {
        ...state,
        frustrationLevel: action.payload
      };

    case 'SET_HEARING_AID_STYLE':
      return {
        ...state,
        hearingAidStyle: action.payload
      };
      
    case 'SET_PATIENT_FIRST_NAME':
      return {
        ...state,
        patient: {
          ...state.patient,
          firstName: action.payload
        }
      };
      
    case 'SET_PATIENT_LAST_INITIAL':
      return {
        ...state,
        patient: {
          ...state.patient,
          lastInitial: action.payload
        }
      };

    case 'SET_PROFILE_ID':
      return {
        ...state,
        patient: {
          ...state.patient,
          id: action.payload
        }
      };
      
    case 'SET_PROVIDER_EMAIL':
      return {
        ...state,
        provider: {
          ...state.provider,
          email: action.payload
        }
      };
      
    case 'SET_PROVIDER_EMAILSENTONCE':
      return {
        ...state,
        provider: {
          ...state.provider,
          emailSentOnce: action.payload
        }
      };
      
    case 'SET_PROVIDER_ID':
      return {
        ...state,
        provider: {
          ...state.provider,
          id: action.payload
        }
      };
      
    case 'SET_PROVIDER_IP':
      return {
        ...state,
        provider: {
          ...state.provider,
          ip: action.payload
        }
      };

    case 'MERGE_DB_DATA':
      return {
        ...state,
        assessment: action.payload.map((item, i) => {
          return {
            guid: item.CategoryID,
            id: i,
            options: item.Cards.map((card, j) => {
              return {
                features: {
                  ai: questionSupplement[i].features.ai[j],
                  techLevel: questionSupplement[i].features.techLevel[j]
                },
                benefit: card.Score,
                guid: card.CardID,
                icon: questionSupplement[i].icons[j],
                id: j,
                text: card.Name
              };
            }),
            question: item.Name,
            selectedTiles: new Array(item.Cards.length + 1)
              .join('0')
              .split('')
              .map(parseFloat)
          };
        })
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
