import React from 'react';
// Style
import {
  Container,
  SlideUp,
  SlideUpWrapper,
  Title
} from './Section.styles';

const Section = ({
  active,
  section
}) => {

  const data = {
    "auditoryNerveConnection": "Auditory Nerve Connection",
    "innerEar": "Inner Ear",
    "middleEar": "Middle Ear",
    "outerEar": "Outer Ear",
  }

  return(
    <Container section={section}>
      <Title>{data[section]}</Title>
      <SlideUpWrapper>
        <SlideUp active={active} />
      </SlideUpWrapper>
    </Container>
  );
};

export default Section;
