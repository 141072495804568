import styled, { css,keyframes } from 'styled-components';
import { colors as c } from '../../../../../../../../styles/colors';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(27, 159, 211, 0.3);
  }
  40% {
    box-shadow: 0 0 0 .7rem rgba(208, 214, 216, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(28, 171, 228, 0);
  }
`;

export const Container = styled.div`
  width: 15rem;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1.5rem;
`;

export const Bite = styled.span`
  width: .5rem;
  height: .5rem;
  border-radius: .5rem;
  background-color: ${c.grayMed};

  ${({ active,pinned }) => (active && !pinned) && `
    background-color: ${c.grayDark};
  `}

  ${({ pinned }) => pinned && css`
    background-color: ${c.bluePrimary};
    animation: ${pulse} 1.5s infinite ease-in-out
  `}
`;
