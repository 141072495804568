import React from 'react';

const SVG = ({
  style = {},
  fill = '#00a3e0',
  width = '40',
  height = '40',
  className = '',
  viewBox = '0 0 40 40'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M20.0031,21.59728a2.108,2.108,0,0,0-1.24774.37432,1.25771,1.25771,0,0,0-.37432,1.123,1.35475,1.35475,0,0,0,.49909,1.123,2.26674,2.26674,0,0,0,2.49548,0,1.25769,1.25769,0,0,0,.37432-1.123,1.74187,1.74187,0,0,0-.37432-1.123A3.25327,3.25327,0,0,0,20.0031,21.59728Z"
      fill={fill} />
    <polygon
      points="18.631 20.35 21.251 20.35 21.75 12.863 18.256 12.863 18.631 20.35"
      fill={fill} />
    <path
      d="M25.86746,5.62625H14.13874A2.63555,2.63555,0,0,0,11.51849,8.2465v23.707a2.63555,2.63555,0,0,0,2.62025,2.62025H25.86746a2.63556,2.63556,0,0,0,2.62025-2.62025h0V8.2465a2.71985,2.71985,0,0,0-2.62025-2.62025ZM14.13873,6.874H25.86746A1.25034,1.25034,0,0,1,27.1152,8.12173h0v.24954H12.891V8.12173A1.25034,1.25034,0,0,1,14.13873,6.874ZM27.1152,9.74379V27.836H12.891V9.74379ZM25.86746,33.07646H14.13874A1.25034,1.25034,0,0,1,12.891,31.82873h0V29.20848H26.99043v2.62025a1.147,1.147,0,0,1-1.123,1.24773Z"
      fill={fill} />
    <path
      d="M21.25083,30.33144H18.75536a.62387.62387,0,0,0,0,1.24774h2.49547a.62387.62387,0,0,0,0-1.24774Z"
      fill={fill} />
    <path
      d="M35.475,11.98971a.70582.70582,0,1,0-.99818.99819,10.01629,10.01629,0,0,1,0,14.09943h0a.70582.70582,0,1,0,.99818.99819h0a11.297,11.297,0,0,0,0-16.09581Z"
      fill={fill} />
    <path
      d="M31.3575,15.10905a.60307.60307,0,0,0,0,.87342h0a5.49768,5.49768,0,0,1,0,7.736h0a.60306.60306,0,0,0,0,.87341h0a.64824.64824,0,0,0,.4991.24955.94588.94588,0,0,0,.49909-.24955,6.74074,6.74074,0,0,0,0-9.60757h0a1.00118,1.00118,0,0,0-.99819.12477Z"
      fill={fill} />
    <path
      d="M5.40458,11.98971a.759.759,0,0,0-.99819,0,11.37237,11.37237,0,0,0,0,15.971h0a.64821.64821,0,0,0,.49909.24955c.37432,0,.74864-.24955.74864-.49909a.95309.95309,0,0,0-.24954-.62387,10.01627,10.01627,0,0,1,0-14.09943h0A.75466.75466,0,0,0,5.40458,11.98971Z"
      fill={fill} />
    <path
      d="M8.64869,15.10905a.60308.60308,0,0,0-.87341,0h0a6.74074,6.74074,0,0,0,0,9.60757h0a.64821.64821,0,0,0,.49909.24955.94588.94588,0,0,0,.49909-.24955.60306.60306,0,0,0,0-.87341h0a5.49769,5.49769,0,0,1,0-7.736h0a1.00113,1.00113,0,0,0-.12477-.99819Z"
      fill={fill} />
  </svg>
);

export default SVG;
