import React from 'react';
import styled from 'styled-components';
// Functions
import parse from 'html-react-parser';
// Style
import { Description,Disclaimer,SlideWrapper } from '../../Slide.styles';

const S1Image = styled.img`
  width: 19.2rem;
  height: auto;
  margin-top: 4.5rem;
  margin-bottom: 1rem;
`;

const S1Description = styled(Description)`
  margin-bottom: 6rem;
`;

export const Slide1 = () => {

  return(
    <SlideWrapper>
      <S1Image src="/images/impacts/falling-down-stairs-graphic.svg" alt="falling down stairs graphic" />
      <S1Description>{parse('People with mild hearing loss [25dB] are <strong>3 times<br />more likely to have a history of falling.</strong>Every<br />additional 10 decibels of hearing loss increases the<br />chances of falling by 1.4 times the original risk.')}</S1Description>
      <Disclaimer>{parse('<strong>Source:</strong> Johns Hopkins Medicine')}</Disclaimer>
    </SlideWrapper>
  )
}

const S2Description = styled(Description)`
  margin: 5rem 0 1rem;
`;

const S2Image = styled.img`
  width: 26rem;
  height: auto;
  margin-bottom: 8.2rem;
`;

export const Slide2 = () => {
  return(
    <SlideWrapper>
      <S2Description>{parse('Falls are the <strong>leading cause of fatal<br />injury</strong> among older adults.')}</S2Description>
      <S2Image src="/images/impacts/ambulance-hospital-graphic.svg" alt="ambulance hospital graphic" />
      <Disclaimer>{parse('<strong>Source:</strong> Faul, M., Xu, L., Wald, M. M., Coronado, V. G. Traumatic brain injury in the United States:<br />Emergency department visits, hospitalizations and deaths 2002-2006. Centers for Disease Control<br />and Prevention, National Center for Injury Prevention and Control; 2010.')}</Disclaimer>
    </SlideWrapper>
  )
}

const S3Description1 = styled(Description)`
  margin: 5rem 0 0;
`;

const S3Image = styled.img`
  width: 17.5rem;
  height: auto;
  margin: .7rem 0;
`;

const S3Description2 = styled(Description)`
  margin: 0 0 6.15rem;
`;

export const Slide3 = () => {
  return(
    <SlideWrapper>
      <S3Description1>{parse('Falling accounts for more than <strong>60% of traumatic<br />brain injuries</strong> in people over 65 years old.')}</S3Description1>
      <S3Image src="/images/impacts/pie-chart-graphic.svg" alt="ambulance hospital graphic" />
      <S3Description2>{parse('<strong>Hearing aids with integrated sensors<br />can detect when you fall and send alerts.</strong>')}</S3Description2>
      <Disclaimer>{parse('<strong>Source:</strong> Faul, M., Xu, L., Wald, M. M., Coronado, V. G. Traumatic brain injury in the United States:<br />Emergency department visits, hospitalizations and deaths 2002-2006. Centers for Disease Control<br />and Prevention, National Center for Injury Prevention and Control; 2010.')}</Disclaimer>
    </SlideWrapper>
  )
}