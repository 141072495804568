export const initialConfigData = {
  brandName: 'starkey',
  loading: false
};

export const configReducer = (state = initialConfigData, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'SET_BRAND_NAME':
      return {
        ...state,
        brandName: action.payload
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
